import { Box } from 'grommet';
import { Event } from '../../../types/EventTypes';
import React, { useEffect, useState } from 'react';
import { TextInput } from '../../Input/TextInput';
import { Select } from '../../Input/Select';
// @ts-ignore
import { monthList } from '../../../service/common/constants';
import { useTranslation } from 'react-i18next';

interface FireFormProps {
  event: Event;
  updateEvent: (herd: any) => void;
  startDate: Date;
  endDate: Date;
}

export const FireForm = ({ event, updateEvent, startDate, endDate }: FireFormProps) => {
  const { t } = useTranslation();
  const [year, setYear] = useState<number | undefined>(
    new Date(startDate).getFullYear() === new Date(endDate).getFullYear() ? new Date(startDate).getFullYear() : undefined,
  );
  const [filteredMonthList, setFilteredMonthList] = useState(monthList());

  function setEventDates() {
    if (!event.data?.yearBurnOccurred || !event.data?.monthBurnOccurred) return;
    const monthNumber = monthList().findIndex((month: any) => month === event.data?.monthBurnOccurred);
    event.data.startDate = new Date(Number(event.data.yearBurnOccurred), monthNumber, 1);
    event.data.endDate = new Date(Number(event.data.yearBurnOccurred), monthNumber + 1, 0);
  }

  useEffect(() => {
    if (!year) {
      setFilteredMonthList([]);
      return;
    }
    if (new Date(startDate).getFullYear() === new Date(endDate).getFullYear()) {
      setFilteredMonthList(monthList().slice(new Date(startDate).getUTCMonth(), new Date(endDate).getUTCMonth() + 1));
      return;
    }
    if (year === new Date(startDate).getFullYear()) {
      setFilteredMonthList(monthList().slice(new Date(startDate).getUTCMonth(), 12));
      return;
    }
    if (year === new Date(endDate).getFullYear()) {
      setFilteredMonthList(monthList().slice(0, new Date(endDate).getUTCMonth() + 1));
      return;
    }
    setFilteredMonthList(monthList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  return (
    <Box direction={'row'} gap={'1rem'} width={'66%'}>
      <TextInput
        label={t('fieldFlow.year')}
        width={'50%'}
        type={'number'}
        value={event.data?.yearBurnOccurred?.toString() ?? ''}
        onChange={(yearBurnOccurred) => {
          event.data = event.data || {};
          event.data.yearBurnOccurred = yearBurnOccurred;

          if (Number(yearBurnOccurred) < new Date(startDate).getFullYear())
            event.data.yearBurnOccurred = new Date(startDate).getFullYear().toString();
          if (Number(yearBurnOccurred) > new Date(endDate).getFullYear()) event.data.yearBurnOccurred = new Date(endDate).getFullYear().toString();

          setEventDates();
          setYear(Number(event.data.yearBurnOccurred));
          updateEvent(event);
        }}
      />
      <Select
        label={t('fieldFlow.month')}
        width={'50%'}
        value={event.data?.monthBurnOccurred ?? ''}
        options={filteredMonthList}
        onChange={(month) => {
          event.data = event.data || {};
          event.data.monthBurnOccurred = month;
          setEventDates();
          updateEvent(event);
        }}
      />
    </Box>
  );
};

export default FireForm;
