import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Keyboard, Text } from 'grommet';
import SearchInput from './SearchInput';
import { Position } from '@turf/turf';

export interface MapSearchResultFeature extends ReturnType<JSON['parse']> {
  place_name: string;
  place_type: string[];
  center: Position;
}

export type SearchProps = {
  initialValue: string | undefined;
  options?: MapSearchResultFeature[] | undefined;
  hint: string;
  inputCallback: (value: string) => void;
  setSearchInput?: ((value: ((prevState: string) => string) | string) => void) | undefined;
  onSelectOptionCallback: (value: number[], placeName: string, context: any[]) => void;
};

export const SearchInputWithOptions = (props: SearchProps): JSX.Element => {
  const [value, setValue] = useState(props.initialValue ?? '');
  const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
  const isMounted = useRef(false);
  const boxRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const [throttleTimeout, setThrottleTimeout] = useState<NodeJS.Timeout | null>();

  useEffect(() => {
    //Prevents run on first render in the case of existing initialValue
    if (isMounted.current) {
      if (value.trim()) {
        setIsShowOptions(true);
        if (throttleTimeout) clearTimeout(throttleTimeout);
        setThrottleTimeout(
          setTimeout(() => {
            setThrottleTimeout(null);
            props.inputCallback(value);
          }, 500),
        );
      }
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onSuggestionSelect = useCallback((event: any) => {
    setValue(event.place_name);
    let center = [0, 0, 0, 0];
    if (event.center) {
      center = [event.center[0], event.center[1], event.center[0], event.center[1]];
    }
    props.onSelectOptionCallback(event.bbox || center, event.place_name, event.context);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBlur = useCallback(() => {
    setTimeout(() => {
      setIsShowOptions(false);
    }, 200);
  }, []);
  return (
    <React.Fragment>
      <Box>
        <Keyboard
          onEnter={() => {
            if (props?.options?.length) {
              setValue(props?.options[0].place_name);
              props.onSelectOptionCallback(props?.options[0].center, props?.options[0].place_name, props?.options[0]['context']);
              setIsShowOptions(false);
            }
          }}
        >
          <SearchInput
            setValue={setValue}
            clearSearchBarCallBack={() => props.onSelectOptionCallback([], '', [])}
            value={value}
            boxRef={boxRef}
            hint={props.hint}
            onBlur={onBlur}
            onChange={(event) => {
              setValue(event.target.value);
              if (props.setSearchInput) {
                props.setSearchInput(event.target.value);
              }
            }}
          />
        </Keyboard>
        {isShowOptions && (
          <Box
            width='100%'
            direction='column'
            margin={'48px 0'}
            style={{
              maxWidth: '280px',
              position: 'absolute',
              zIndex: 1,
              background: '#fff',
            }}
          >
            {value &&
              props?.options?.map((eachItem) => {
                return (
                  <Box
                    key={eachItem['id']}
                    pad={'small'}
                    direction='column'
                    gap='small'
                    border={{
                      color: '#B4BFC6',
                      size: '0.063rem',
                      position: 'bottom',
                    }}
                    onClick={() => onSuggestionSelect(eachItem)}
                  >
                    <Text color='#0D0D0D' size='16px'>
                      {eachItem.place_name}
                    </Text>
                  </Box>
                );
              })}
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};
