// @ts-ignore
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import FieldDrawMode from './fieldDrawMode';
import FieldEditMode from './fieldEditMode';
import FieldInnerBorderMode from './fieldInnerBorderMode';
import PointDeletionMode from './pointDeletionMode';
import RadiusMode from './circleDrawMode';
import SplitPolygonMode from './splitPolygonMode';
import PassingDrawLineString from './passingDrawLineString';
import AutoDirectSelectMode from './autoDirectSelectMode';
import DirectSelectMode from './directSelectMode';

export const MAP_MODES = {
  STATIC: 'static',
  DRAW_FIELD: 'draw_field',
  CIRCULAR_BORDER: 'circle_border',
  OUTER_BORDER: 'outer_border',
  INNER_BORDER: 'inner_border',
  POINT_DELETION: 'point_deletion',
  SIMPLE_SELECT: 'simple_select',
  DIRECT_SELECT: 'fixed_direct_select',
  SPLIT_FIELD: 'split_field',
  PASSING_MODE: 'passing_mode',
  AUTO_DIRECT_SELECT: 'auto_direct_select',
};

export const customModes = {
  [MAP_MODES.STATIC]: StaticMode,
  [MAP_MODES.DRAW_FIELD]: FieldDrawMode,
  [MAP_MODES.CIRCULAR_BORDER]: RadiusMode,
  [MAP_MODES.OUTER_BORDER]: FieldEditMode,
  [MAP_MODES.INNER_BORDER]: FieldInnerBorderMode,
  [MAP_MODES.POINT_DELETION]: PointDeletionMode,
  [MAP_MODES.SPLIT_FIELD]: SplitPolygonMode,
  [MAP_MODES.PASSING_MODE]: PassingDrawLineString,
  [MAP_MODES.AUTO_DIRECT_SELECT]: AutoDirectSelectMode,
  [MAP_MODES.DIRECT_SELECT]: DirectSelectMode,
};
