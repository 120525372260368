import { API } from './api';
import { BASE_URL } from './env';

export const getStates = async (country: string) => {
  return API.get(`${BASE_URL}/base/growers/states?country=${country}`).then((res) => {
    return res.data;
  });
};

export interface IReason {
  sfValue: string;
  value: string;
}

export const leadDeletionReasons = {
  // generalReasons: [
  //   'Already working with competitor',
  //   'Conflict of interest',
  //   'Not interested in the program',
  //   'Unresponsive',
  //   'Not a real Lead',
  //   'Not interested in the program',
  //   'Unresponsive',
  //   'Not a real Lead',
  //   'Other',
  // ],
  brReasons: [
    {
      sfValue: 'Failed Eligibility - Range & Pastureland practices already adopted',
      value: 'Falha de Elegibilidade - Práticas de Pastejo já aplicadas',
    },
    {
      sfValue: 'Failed Eligibility - Interested in Specialty, Vegetable, Orchard or Other crop contract',
      value: 'Falha de Elegibilidade - Interesse em Especialidade, Vegetal, Pomar ou Outros contratos de Lavoura',
    },
    {
      sfValue: 'Failed Eligibility - Receiving conflicting conservation funding',
      value: 'Falha de Elegibilidade - Receiving conflicting conservation funding',
    },
    { sfValue: 'Failed Eligibility - Land ownership issues', value: 'Falha de Elegibilidade - Conflitos de Propriedade' },
    { sfValue: 'Failed Eligibility - Too few acres', value: 'Falha de Elegibilidade - Hectares abaixo do mínimo necessário' },
    { sfValue: 'Failed Eligibility - Carbon below minimum', value: 'Falha de Elegibilidade - Carbono abaixo do mínimo necessário' },
    {
      sfValue: 'Failed Eligibility - Already enrolled in competitive carbon program',
      value: 'Falha de Elegibilidade - Já registrado em um programa competitivo de carbono',
    },
    { sfValue: 'Not interested in the program', value: 'Sem interesse no programa' },
    { sfValue: 'Concerns with contract duration', value: 'Conflitos com relação a duração do contrato' },
    { sfValue: 'Concerns with payment amounts', value: 'Conflitos com relação a quantia a ser paga' },
    { sfValue: 'Unresponsive', value: 'Sem resposta' },
    { sfValue: 'Not a real Lead', value: 'Não é uma Lead real' },
    { sfValue: 'Other', value: 'Outros' },
  ],
  usaReasons: [
    { sfValue: 'Failed Eligibility - Row crop practices already adopted', value: 'Failed Eligibility - Row crop practices already adopted' },
    {
      sfValue: 'Failed Eligibility - Range & Pastureland practices already adopted',
      value: 'Failed Eligibility - Range & Pastureland practices already adopted',
    },
    {
      sfValue: 'Failed Eligibility - Interested in Specialty, Vegetable, Orchard or Other crop contract',
      value: 'Failed Eligibility - Interested in Specialty, Vegetable, Orchard or Other crop contract',
    },
    {
      sfValue: 'Failed Eligibility - Receiving conflicting conservation funding',
      value: 'Failed Eligibility - Receiving conflicting conservation funding',
    },
    { sfValue: 'Failed Eligibility - Land ownership issues', value: 'Failed Eligibility - Land ownership issues' },
    { sfValue: 'Failed Eligibility - Too few acres', value: 'Failed Eligibility - Too few acres' },
    { sfValue: 'Failed Eligibility - Carbon below minimum', value: 'Failed Eligibility - Carbon below minimum' },
    {
      sfValue: 'Failed Eligibility - Already enrolled in competitive carbon program',
      value: 'Failed Eligibility - Already enrolled in competitive carbon program',
    },
    { sfValue: 'Not interested in the program', value: 'Not interested in the program' },
    { sfValue: 'Concerns with contract duration', value: 'Concerns with contract duration' },
    { sfValue: 'Concerns with payment amounts', value: 'Concerns with payment amounts' },
    { sfValue: 'Unresponsive', value: 'Unresponsive' },
    { sfValue: 'Not a real Lead', value: 'Not a real Lead' },
    { sfValue: 'Other', value: 'Other' },
  ],
};
