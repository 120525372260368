import { Box, Text, CheckBox } from 'grommet';
import { useMapContext } from '../../service/map/MapContext';
import { useTranslation } from 'react-i18next';

export const ShowCloserFieldsComponents = (): JSX.Element => {
  const { t } = useTranslation();
  const { toggleShowNearFields, showCloserFields, loading } = useMapContext();

  return (
    <Box
      style={{
        padding: '0px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 11,
        background: '#fff',
        border: 'solid 1px #282828',
        borderRadius: '5px',
      }}
    >
      <Box align='center'>
        <CheckBox
          data-cy={'toggle-close-fields'}
          disabled={loading}
          label={<Text style={{ display: 'flex', alignItems: 'center', fontSize: '12px', fontWeight: 700 }}>{t('fieldFlow.seeCloserFields')}</Text>}
          checked={showCloserFields}
          reverse={true}
          onChange={async (event) => {
            await toggleShowNearFields(event.target.checked);
          }}
          toggle
        />
      </Box>
    </Box>
  );
};
