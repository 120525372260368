export const FieldColorTokens = {
  // linePaintColor: '#00a1ff',
  // lineWidth: 6,
  // fillPaintColor: '#0C5692',
  // fillWidth: 0.75,
  // textColor: '#fff',
  // textHaloColor: '#000',
  // fillOpacity: 0.7,

  fieldUnselected: {
    fillOpacity: 0.55,
    fillColor: '#0D0D0D',
    lineColor: '#00A1FF',
    lineWidth: 2,
    textColor: '#ffffff',
    textHaloColor: '#000',
  },
  fieldSelected: {
    fillOpacity: 0.55,
    fillColor: '#0D0D0D',
    lineColor: '#FFE137',
    lineWidth: 2,
    textColor: '#ffffff',
    textHaloColor: '#000',
  },
  wetland: {
    fillOpacity: 0.3,
    fillColor: '#a0f2f2',
    lineColor: '#19e3e3',
    lineWidth: 2,
    textColor: '#ffffff',
    textHaloColor: '#000',
  },
  publicLand: {
    fillOpacity: 0.3,
    fillColor: '#aef138',
    lineColor: '#19e3e3',
    lineWidth: 2,
    textColor: '#ffffff',
    textHaloColor: '#000',
  },
  nearField: {
    fillOpacity: 0.55,
    fillColor: '#282828',
    lineColor: '#E7A325',
    lineWidth: 2,
    textColor: '#ffffff',
    textHaloColor: '#000',
  },
  fieldUnenrolled: {
    fillOpacity: 0.55,
    fillColor: '#0D0D0D',
    lineColor: '#cccccc',
    lineWidth: 2,
    textColor: '#ffffff',
    textHaloColor: '#000',
  },
  fieldIntersection: {
    fillOpacity: 0.55,
    fillColor: '#0D0D0D',
    lineColor: '#ff0000',
    lineWidth: 2,
    textColor: '#ffffff',
    textHaloColor: '#000',
  },
};
