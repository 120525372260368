import React from 'react';
import { Close, Search } from 'grommet-icons';
import { Box, Button, TextInput } from 'grommet';

type SearchInputProps = {
  onBlur?: () => void;
  onChange: (event: any) => void;
  clearSearchBarCallBack?: () => void;
  boxRef?: React.MutableRefObject<HTMLInputElement>;
  hint: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
};

const SearchInput = ({ boxRef, hint, onChange, clearSearchBarCallBack, onBlur, setValue, value }: SearchInputProps): JSX.Element => {
  return (
    <Box
      round={'5px'}
      background='src/components/Search#fff'
      border={{ color: '#282828', size: '1px', style: 'solid' }}
      style={{ minHeight: '3rem' }}
      direction='row'
      align='center'
      ref={boxRef}
    >
      <TextInput
        data-cy='input-searchbar'
        dropTarget={boxRef ? boxRef.current : undefined}
        style={{ fontWeight: 700 }}
        plain
        focusIndicator={false}
        placeholder={hint}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <Button
        hoverIndicator='none'
        icon={
          value ? (
            <Close color='#282828' size='20px' style={{ verticalAlign: 'middle' }} />
          ) : (
            <Search color='#282828' size='20px' style={{ verticalAlign: 'middle', cursor: 'default' }} />
          )
        }
        onClick={() => {
          setValue(value ? '' : value);
          if (clearSearchBarCallBack) {
            clearSearchBarCallBack();
          }
        }}
      />
    </Box>
  );
};

export default SearchInput;
