import { BASE_URL } from './env';
import { API } from './api';

const getUsersFromAuth = async () => {
  return await API.get(`${BASE_URL}/base/user/auth0`).then((res) => {
    res.data.sort((a: any, b: any) => {
      const nameA = `${a.given_name} ${a.family_name}`;
      const nameB = `${b.given_name} ${b.family_name}`;

      return nameA > nameB ? 1 : -1;
    });
    return res.data;
  });
};

const getContractsFromPartner = async () => {
  return await API.get(`${BASE_URL}/base/salesforce/partner-active-contracts`).then((res) => {
    return res.data;
  });
};

const updateUserMetadata = (user: any) => {
  return API.patch(`${BASE_URL}/base/user/auth0/metadata`, user);
};

export const UserService = {
  getUsersFromAuth,
  updateUserMetadata,
  getContractsFromPartner,
};
