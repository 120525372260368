import { useTranslation } from 'react-i18next';
import { Box, Button, Spinner, Text } from 'grommet';
import { useMapContext } from '../../../service/map/MapContext';

interface FieldStepButtonsProps {
  showLoading: boolean;
  shouldNotifyUpdate: () => boolean;
  setShowSaveAndLeaveModal: (value: ((prevState: boolean) => boolean) | boolean) => void;
  saveAndLeave: () => void;
  saveLeaveLoading: boolean;
}

const FieldStepButtons = ({ showLoading, shouldNotifyUpdate, setShowSaveAndLeaveModal, saveAndLeave, saveLeaveLoading }: FieldStepButtonsProps) => {
  const { t } = useTranslation();
  const { country, fields, setDeleteAllFields, fieldsDrawingInvalid } = useMapContext();

  return (
    <>
      <Button
        data-cy={'save-and-leave-button'}
        as={'button'}
        alignSelf={'center'}
        onClick={() => {
          if (!showLoading) {
            shouldNotifyUpdate() ? setShowSaveAndLeaveModal(true) : saveAndLeave();
          }
        }}
        disabled={fieldsDrawingInvalid || saveLeaveLoading}
        justify={'center'}
        style={{
          alignItems: 'center',
          textAlign: 'center',
          width: '232px',
          padding: '1rem 1.5rem',
          background: '#1B7C3D',
          color: '#FFF',
          ...(fieldsDrawingInvalid ? { pointerEvents: 'none', opacity: 0.7 } : {}),
        }}
      >
        {saveLeaveLoading ? (
          <Box width={'100%'}>
            <Spinner alignSelf={'center'} width={'10px'} height={'10px'} color={'white'} />
          </Box>
        ) : (
          <Text weight={700} textAlign={'center'} size={'1rem'} style={{ lineHeight: 'unset' }}>
            {t('fieldFlow.mainWizard.saveButton')}
          </Text>
        )}
      </Button>
      {country === 'br' && fields.length > 0 && (
        <Button
          data-cy={'delete-all-fields-button'}
          as={'button'}
          alignSelf={'center'}
          justify={'center'}
          onClick={() => setDeleteAllFields(true)}
          style={{
            alignItems: 'center',
            textAlign: 'center',
            padding: '1rem 1.5rem',
            background: '#D03450',
            color: '#FFF',
          }}
        >
          <Text weight={700} textAlign={'center'} size={'1rem'} style={{ lineHeight: 'unset' }}>
            {t('fieldFlow.mainWizard.Fields.deleteAll')}
          </Text>
        </Button>
      )}
    </>
  );
};

export default FieldStepButtons;
