import { Box, Button, Image, Text } from 'grommet';
import React, { useEffect, useRef, useState } from 'react';
import { FormDown, FormUp } from 'grommet-icons';
import LevelTag from '../../../../Wizard/components/LevelTag';
import eventIcon from '../../../../../../../assets/BoundaryTool/infoIcons/events.svg';
import EventItem, { getEventTypeDictionary } from '../../../../Events/EventItem';
import { Feature } from '@turf/helpers';
import { useSearchParams } from 'react-router-dom';
import EventInfo from './components/EventInfo';
import { useTranslation } from 'react-i18next';

interface EventDropdownProps {
  filteredEvents: any[];
  field: Feature;
  paddocks: Feature[];
  addEvent: () => void;
  updateEvent: (event: any) => void;
  removeEvent: (event: any) => void;
  saveEvent: (event: any) => Promise<void>;
}

export const EventDropdown = ({
  filteredEvents,
  field,
  paddocks,
  addEvent,
  updateEvent,
  removeEvent,
  saveEvent,
}: EventDropdownProps): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [highlight, setHighlight] = useState<boolean>(false);
  const [savingEvent, setSavingEvent] = useState<boolean>(false);
  const elementRef = useRef<any>();

  const EventTypeDictionary = getEventTypeDictionary(t);

  useEffect(() => {
    const type = searchParams.get('type');

    if (type && type === 'EVENT') {
      setExpanded(true);
      setHighlight(true);
    } else {
      setHighlight(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (elementRef.current && highlight) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [elementRef, highlight]);

  return (
    <>
      <Box style={{ minHeight: 'unset' }}>
        <Box
          onClick={() => setExpanded(!expanded)}
          border={{ color: highlight ? '#1457A8' : '#808080', size: '1px', style: 'solid' }}
          style={{
            borderRadius: expanded ? '5px 5px 0 0' : '5px',
            padding: '0.5rem 0.75rem',
            cursor: 'pointer',
          }}
        >
          <Box direction={'row'} justify={'between'}>
            <Box direction={'row'} gap={'0.75rem'} align={'center'}>
              <Image height={'36px'} width={'36px'} src={eventIcon} />
              <Text weight={900} margin={'-0.25rem 0 0 0'} size={'1.25rem'}>
                {t('fieldFlow.eventItem.events')} ({filteredEvents.length})
              </Text>
              <LevelTag level={'Field'} />
              <LevelTag level={'Paddock'} />
            </Box>
            {expanded ? (
              <Box justify={'center'}>
                <FormUp />
              </Box>
            ) : (
              <Box justify={'center'}>
                <FormDown />
              </Box>
            )}
          </Box>
        </Box>
        {expanded && (
          <Box
            overflow={'hidden'}
            pad={'1rem'}
            gap={'1rem'}
            style={{ borderTop: 0 }}
            round={'0 0 5px 5px'}
            border={{ color: highlight ? '#1457A8' : '#808080', size: '1px', style: 'solid' }}
          >
            {filteredEvents.map((event) => {
              if (typeof event.id === 'number') {
                return (
                  <Box key={event.id}>
                    <EventItem
                      simple={true}
                      event={event}
                      fields={[field]}
                      paddocks={{ [field.id!]: paddocks }}
                      updateEvent={updateEvent}
                      removeEvent={() => removeEvent(event)}
                    />

                    <Box margin={{ top: '19px' }} alignSelf={'end'} align={'end'} direction={'row'} gap={'16px'}>
                      <Button
                        focusIndicator={false}
                        hoverIndicator={false}
                        style={{
                          width: '80px',
                          minHeight: '28px',
                          borderRadius: '0px',
                          border: '1px solid #282828',
                          fontWeight: '800',
                          fontSize: '10px',
                          lineHeight: '12px',
                          textAlign: 'center',
                          padding: '6px 0',
                          backgroundColor: 'white',
                          justifyContent: 'center',
                        }}
                        onClick={() => {
                          removeEvent(event);
                        }}
                        label={t('fieldFlow.cancel')}
                        plain
                      />

                      <Button
                        focusIndicator={false}
                        hoverIndicator={false}
                        disabled={event.invalid}
                        style={{
                          width: '90px',
                          minHeight: '28px',
                          borderRadius: '0px',
                          fontWeight: '800',
                          fontSize: '10px',
                          lineHeight: '12px',
                          textAlign: 'center',
                          padding: '8px 0',
                          backgroundColor: '#1B7C3D',
                          color: 'white',
                          justifyContent: 'center',
                        }}
                        onClick={async () => {
                          if (savingEvent) return;

                          setSavingEvent(true);
                          await saveEvent(event);
                          setSavingEvent(false);
                        }}
                        label={savingEvent ? t('fieldFlow.saving') : t('fieldFlow.saveChanges')}
                        plain
                      />
                    </Box>
                  </Box>
                );
              }
              return (
                <Box key={event.id} round={'5px'} border={{ color: '#CCC', size: '1px', style: 'solid' }}>
                  <Box
                    direction={'row'}
                    justify={'between'}
                    align={'center'}
                    pad={'0.25rem 0.75rem'}
                    border={{ color: '#CCC', size: '1px', style: 'solid', side: 'bottom' }}
                  >
                    <Box direction={'row'} gap={'1rem'} align={'center'}>
                      <Image margin={{ vertical: '0.125rem' }} width={'32px'} height={'32px'} src={eventIcon} />
                      <Text size={'1.25rem'} weight={900}>
                        {event.data.name ?? EventTypeDictionary[event.type as keyof typeof EventTypeDictionary]}
                      </Text>
                    </Box>
                  </Box>
                  <EventInfo id={event.id} event={event} />
                </Box>
              );
            })}
            <Box
              as={'button'}
              align={'center'}
              onClick={() => {
                addEvent();
              }}
              style={{
                cursor: 'pointer',
                display: typeof filteredEvents[filteredEvents.length - 1]?.id === 'number' ? 'none' : 'flex',
              }}
              justify={'center'}
              height={'84px'}
              background={'#FFF'}
              round={'5px'}
              border={{ color: '#CCC', size: '1px', style: 'solid' }}
            >
              <Text size={'1rem'} weight={400}>
                {t('fieldFlow.ranchOverview.fieldDetails.event.create')}
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default EventDropdown;
