import { Box, Button, Layer, Spinner, Text } from 'grommet';
import { Select } from '../Input/Select';
import { DateInput } from '../Input/DateInput';
import { useMapContext } from '../../service/map/MapContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface MoveEvent {
  journeyId: string;
  growerId: string;
  herdName: string;
  herdId: string;
  herdVersionId: string;
  dateIn?: Date;
  paddockId?: string;
  paddockName?: string;
  fieldId?: string;
  fieldName?: string;
  remainingForage?: number;
}

export const RemoveHerdModal = ({ herd, onCancel }: { herd: any; onCancel: () => void }) => {
  const { journey, herds, removeSavedHerd, getLastHerdDate } = useMapContext();
  const [selectedHerd, setSelectedHerd] = useState(herd);
  const [loading, setLoading] = useState<boolean>(false);
  const [moveEvent, setMoveEvent] = useState<MoveEvent>({
    journeyId: journey?.id ?? '',
    growerId: journey?.leadId ?? journey?.opportunityId ?? '',
    herdName: herd.name,
    herdId: herd.id,
    herdVersionId: herd.versionId,
    fieldId: herd.fieldId,
    paddockId: herd.paddockId,
  });
  const { t } = useTranslation();
  const [minDate, setMinDate] = useState<Date>();

  useEffect(() => {
    getLastHerdDate(herd).then(({ lastDate }) => setMinDate(lastDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layer position='center' style={{ borderRadius: '12px' }}>
      <Box pad='24px' width='510px'>
        <Text size='26px' color='#282828' weight={800} style={{ lineHeight: '28px' }}>
          {t('fieldFlow.removeHerdModal.removeHerd')}
        </Text>
        <Text margin={{ top: '8px', bottom: '12px' }} color='#494949' weight={400} style={{ lineHeight: '21px' }}>
          {t('fieldFlow.removeHerdModal.removeHerdFromPaddock')}
        </Text>
        <Box direction={'row'} margin={{ bottom: '16px' }} justify={'between'}>
          <Select
            width={'214px'}
            titleWeight={400}
            label={t('fieldFlow.removeHerdModal.herdToBeRemoved')}
            handleEvent={true}
            onChange={(event) =>
              setMoveEvent((moveEvent) => {
                const herd = herds[event.selected];
                setSelectedHerd(herd);
                return {
                  ...moveEvent,
                  herdId: herd.id,
                  herdVersionId: herd.versionId,
                  herdName: herd.name,
                  fieldId: herd.fieldId,
                  paddockId: herd.paddockId,
                };
              })
            }
            options={herds}
            labelKey={'name'}
            value={selectedHerd}
          />
          <DateInput
            titleWeight={400}
            width={'214px'}
            label={'Date'}
            min={minDate}
            max={new Date()}
            value={moveEvent.dateIn}
            onChange={(dateIn) => {
              setMoveEvent((moveEvent) => {
                return { ...moveEvent, dateIn };
              });
            }}
          />
        </Box>
        <Box margin={{ top: '24px' }} gap={'12px'} justify={'between'} direction='row-responsive'>
          <Button
            plain
            focusIndicator={false}
            label={t('fieldFlow.removeHerdModal.cancel')}
            onClick={onCancel}
            style={{
              borderRadius: '0px',
              width: '100%',
              textAlign: 'center',
              border: '1px solid #282828',
              fontWeight: '700',
              fontSize: '16px',
              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
              padding: '8px 60px',
            }}
          />
          <Button
            disabled={!moveEvent.dateIn}
            focusIndicator={false}
            hoverIndicator={false}
            style={{
              width: '100%',
              borderRadius: '0px',
              fontWeight: '700',
              textAlign: 'center',
              fontSize: '16px',
              padding: '8px 60px',
              boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
              backgroundColor: '#D03450',
              color: '#FFF',
            }}
            label={
              loading ? (
                <Box justify={'center'} align={'center'}>
                  <Spinner size={'small'} height={'16px'} width={'16px'} />
                </Box>
              ) : (
                t('fieldFlow.removeHerdModal.removeHerd')
              )
            }
            onClick={async () => {
              setLoading(true);
              await removeSavedHerd(moveEvent, selectedHerd);
              setLoading(false);
              onCancel();
            }}
            plain
            color={'#282828'}
          />
        </Box>
      </Box>
    </Layer>
  );
};

export default RemoveHerdModal;
