import React from 'react';
import { Box, Layer, Text, Button } from 'grommet';

interface ConfirmationModalProps {
  dataCy: string;
  confirm: () => void;
  confirmLabel?: string;
  cancel: () => void;
  cancelLabel?: string;
  message: string | JSX.Element;
  title?: string;
  width?: string;
}

export const ConfirmationModal = ({
  dataCy,
  confirm,
  confirmLabel,
  cancel,
  cancelLabel,
  message,
  title,
  width,
}: ConfirmationModalProps): JSX.Element => {
  return (
    <Layer background={{ opacity: 0.7 }}>
      <Box
        data-cy={dataCy}
        background={'white'}
        direction={'column'}
        alignSelf={'center'}
        height={'fit-content'}
        width={width ?? 'unset'}
        pad={'24px'}
        className={'hideScrollBox'}
        overflow={'auto'}
        style={{ borderRadius: '1rem', display: 'unset', maxHeight: '95vh' }}
        align={'center'}
        justify={'center'}
      >
        <Text
          size={'33px'}
          weight={600}
          style={{
            lineHeight: '37px',
            marginTop: 0,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {title || 'Are you sure?'}
        </Text>
        <Box margin='1.5rem 0'>
          {typeof message === 'string' ? (
            <Text weight='bold' size='14px' alignSelf='start' color='#282828'>
              {message}
            </Text>
          ) : (
            message
          )}
        </Box>
        <Box direction={'row'} gap={'8px'} justify={'between'}>
          <Button
            data-cy={'cancel-modal-button'}
            onClick={cancel}
            style={{
              width: '12rem',
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: '#FFE137',
              padding: '10px',
              textAlign: 'center',
            }}
          >
            <Text weight={600} size={'1rem'} style={{ lineHeight: 'unset' }}>
              {cancelLabel || 'Cancel'}
            </Text>
          </Button>
          <Button
            data-cy={'confirm-modal-button'}
            onClick={confirm}
            style={{ width: '12rem', backgroundColor: '#FFE137', padding: '10px', textAlign: 'center' }}
          >
            <Text weight={600} size={'1rem'} style={{ lineHeight: 'unset' }}>
              {confirmLabel || 'Confirm'}
            </Text>
          </Button>
        </Box>
      </Box>
    </Layer>
  );
};

export default ConfirmationModal;
