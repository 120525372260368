import { Feature } from '@turf/helpers';

export default interface Field extends Feature {
  layout?: any;
}

export const EnrollmentStatus = {
  ENROLLED: 'ENROLLED',
  UNENROLLED: 'UNENROLLED',
} as const;
