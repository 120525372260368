import { Box, Image, Text } from 'grommet';
import herdIcon from '../../../../../../assets/BoundaryTool/infoIcons/herd.svg';
import React from 'react';
import { HerdDetails } from '../../../Herd/HerdDetails';
import { formatDate } from '../../../../service/common/utils';
import { useTranslation } from 'react-i18next';

interface HerdVersionInfoProps {
  herdVersion: any;
  versionEndDate: string;
  lastVersion?: boolean;
  opacity?: string;
}

export const HerdVersionInfo = ({ herdVersion, versionEndDate, lastVersion, opacity }: HerdVersionInfoProps) => {
  const { t } = useTranslation();
  return (
    <Box direction={'row'} pad={'0 16px'} style={{ minHeight: 'unset', opacity: opacity ?? 1 }}>
      <Box>
        <Box>
          <Text>
            {t('fieldFlow.From')} <strong>{formatDate(herdVersion.editData.date)}</strong>
          </Text>
          <Text>
            {t('fieldFlow.to')} <strong>{versionEndDate}</strong>
          </Text>
        </Box>
      </Box>
      <Box justify={'start'} align={'center'} margin={'0 12px'}>
        <Image height={'36px'} width={'36px'} src={herdIcon} />
        {!lastVersion && <Box height={'100%'} width={'1px'} border={{ color: '#282828', size: '1px', side: 'left' }} />}
      </Box>
      <Box width={'77.5%'} border={{ size: '1px', color: '#282828' }} round={'5px'} margin={{ bottom: '16px' }}>
        <HerdDetails herd={herdVersion} versionDisplay={true} />
      </Box>
    </Box>
  );
};

export default HerdVersionInfo;
