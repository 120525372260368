import { Box, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { SF_PRACTICES_MAP } from '../../../service/common/constants';
import { useMapContext } from '../../../service/map/MapContext';

export const SyncItem = (data: any, paymentMethodError: boolean, type: 'platform' | 'salesforce' | 'currentData'): JSX.Element => {
  const { t } = useTranslation();
  const { country, contractPaymentOption } = useMapContext();
  const contractPaymentOptionMap = {
    OPTION_A: 'Option A',
    OPTION_B: 'Option B',
    'Preço fixo': 'Option A',
    'Porcentagem do preço de venda': 'Option B',
  };
  const dataPaymentOptionMap = {
    'Preço fixo': 'Option A',
    'Porcentagem do preço de venda': 'Option B',
  };
  const paymentOption =
    dataPaymentOptionMap[data.option] || data.option || (contractPaymentOption ? contractPaymentOptionMap[contractPaymentOption!] : 'Option A');

  const getDisplayValue = () => {
    const value = type === 'salesforce' ? data.sf : data.platform;
    return value > 0 ? value.toFixed(2) : 'N/A';
  };

  if (type === 'platform' || type === 'salesforce') {
    return (
      <Box
        data-cy={data.name + type}
        key={data.name + type}
        direction={'row'}
        gap={'8px'}
        margin={'0.5rem 0'}
        justify={'between'}
        style={{ minHeight: '1rem' }}
      >
        <Box width={country !== 'br' ? '33.33%' : '50%'}>
          <Text data-cy={'practice-sync-error'} size='14px' color={paymentMethodError ? '#1B7C3D' : '#D03450'} alignSelf='center'>
            {t(`fieldFlow.fieldItem.practices.options.${SF_PRACTICES_MAP[data.name as keyof typeof SF_PRACTICES_MAP]}`)}
          </Text>
        </Box>
        <Box width={'33.33%'}>
          <Text data-cy={'payment-option-sync-error'} size='14px' alignSelf='center' color={'#D03450'}>
            {data.option ? t(`fieldFlow.syncErrorModal.headers.${paymentOption}`) : '--'}
          </Text>
        </Box>
        <Box width={country !== 'br' ? '33.33%' : '50%'}>
          <Text data-cy={'acreage-sync-error'} size='14px' margin={{ right: '' }} alignSelf='center'>
            {getDisplayValue()}
          </Text>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box key={data.name + type} direction={'row'} gap={'8px'} margin={'0.5rem 0'} justify={'between'} style={{ minHeight: '1rem' }}>
        <Box width={country !== 'br' ? '33.33%' : '50%'}>
          <Text size='14px' alignSelf='center'>
            {t(`fieldFlow.fieldItem.practices.options.${data.name}`)}
          </Text>
        </Box>
        <Box width={'33.33%'}>
          <Text size='14px' alignSelf='center'>
            {t(`fieldFlow.syncErrorModal.headers.${paymentOption}`)}
          </Text>
        </Box>
        <Box width={country !== 'br' ? '33.33%' : '50%'}>
          <Text size='14px' margin={{ right: '' }} alignSelf='center'>
            {getDisplayValue()}
          </Text>
        </Box>
      </Box>
    );
  }
};
