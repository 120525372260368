import { createReducer } from '@reduxjs/toolkit';

export interface IAuthState {
  error?: Error;
  user?: any; //todo need to type this after definitions
  awsToken?: string; //accessToken from AWS (roles and permissions)
  accessToken?: string; //idToken from AWS (identity), used for agoro BEs auth
  refreshToken?: string;
  code?: string; //code returned by AWS on login
  isAuthenticated: boolean;
  isLoading: boolean;
}

export const initialAuthState: IAuthState = {
  isAuthenticated: false,
  isLoading: false,
};

export const reducer = createReducer(initialAuthState, {
  GET_ACCESS_TOKEN_REQUESTED(state) {
    state.isLoading = true;
  },
  GET_ACCESS_TOKEN_SUCCEEDED(state, action) {
    const { accessToken, awsToken, refreshToken, code } = action.payload;
    state.accessToken = accessToken;
    state.awsToken = awsToken;
    state.refreshToken = refreshToken;
    state.code = code;
    state.isLoading = false;
    state.isAuthenticated = true;
  },
  GET_USER_SUCCEEDED(state, action) {
    state.user = action.payload;
  },
  REFRESH_SESSION_REQUESTED(state) {
    state.isLoading = true;
  },
  REFRESH_SESSION_ERROR(state, action) {
    state.error = action.payload;
    state.isLoading = false;
  },
  REFRESH_SESSION_SUCCEEDED(state, action) {
    const { accessToken, awsToken } = action.payload;
    state.accessToken = accessToken;
    state.awsToken = awsToken;
    state.isAuthenticated = true;
    state.isLoading = false;
  },
});
