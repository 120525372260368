// @ts-ignore
import SimpleSelect from '@mapbox/mapbox-gl-draw/src/modes/simple_select';
import { MAP_MODES } from './modes';

const AutoDirectSelectMode = { ...SimpleSelect };

AutoDirectSelectMode.clickOnFeature = function (state: any, e: any) {
  SimpleSelect.clickOnFeature.call(this, state, e);

  const featureId = e.featureTarget.properties.id;
  return this.changeMode(MAP_MODES.DIRECT_SELECT, { featureId });
};

export default AutoDirectSelectMode;
