import { BASE_URL } from '../common/env';
import { API } from '../common/api';

const getActiveCampaigns = async () => {
  return await API.get(`${BASE_URL}/base/growers/active-campaign`).then((res) => {
    const leadsData = res.data.map((data: any) => {
      return {
        createdById: data.CreatedById,
        createdDate: data.CreatedDate,
        endDate: data.EndDate,
        startDate: data.StartDate,
        id: data.Id,
        isActive: data.IsActive,
        name: data.Name,
      };
    });
    return {
      data: leadsData,
    };
  });
};

const getHowDidYouHearOptions = async () => {
  return await API.get(`${BASE_URL}/base/salesforce/leads/options/how-did-you-hear`).then((res) => {
    return res.data;
  });
};

const getRepresentativeOptions = async () => {
  return await API.get(`${BASE_URL}/base/salesforce/representatives`).then((res) => {
    return res.data;
  });
};

export const LeadService = {
  getActiveCampaigns,
  getHowDidYouHearOptions,
  getRepresentativeOptions,
};
