import { Box, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { useMapContext } from '../../service/map/MapContext';

const PanelHeader = () => {
  const { t } = useTranslation();
  const { companyName, totalFieldsAcreage } = useMapContext();

  return (
    <Box direction={'row'} gap={'2rem'} width={'100%'} justify={'end'} style={{ paddingRight: '64px' }}>
      <Box style={{ maxWidth: 'fit-content' }}>
        <Text weight={700}>{t('fieldFlow.farmName')}</Text>
        <Box
          width={'100%'}
          style={{
            padding: '0',
            width: 'fit-content',
            overflow: 'hidden',
            position: 'relative',
            display: 'inline-block',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Text>{companyName}</Text>
        </Box>
      </Box>
      <Box width={'fit-content'} style={{ minWidth: 'unset' }}>
        <Text data-cy={'total-acreage'} weight={700} style={{ whiteSpace: 'nowrap' }}>
          {t('fieldFlow.totalAcreage')}
        </Text>
        <Text>
          {totalFieldsAcreage} {t('fieldFlow.acreUnit')}
        </Text>
      </Box>
    </Box>
  );
};

export default PanelHeader;
