// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { activeStates } from '@mapbox/mapbox-gl-draw/src/constants';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { isVertex } from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import EditField from './fieldEditMode';
import { MAP_MODES } from './modes';
import * as turf from '@turf/turf';

const PointDeletion = { ...EditField };

PointDeletion.onSuperSetup = EditField.onSetup;
PointDeletion.onSetup = function (opts: any) {
  const state = EditField.onSetup.call(this, opts);

  state.setFinishedDrawingField = opts.setFinishedDrawingField;

  return state;
};

// Delete vertex on click
PointDeletion.onVertex = function (state: any, event: any) {
  const vertex = event.featureTarget.properties;
  let oldCoordinates;
  if (state.feature.type === 'Polygon') {
    oldCoordinates = JSON.parse(JSON.stringify(state.feature.coordinates));
  }

  state.feature.removeCoordinate(vertex.coord_path);

  if (state.feature.type === 'Polygon') {
    if (
      oldCoordinates.length !== state.feature.coordinates.length &&
      JSON.stringify(oldCoordinates[0]) !== JSON.stringify(state.feature.coordinates[0])
    ) {
      state.feature.coordinates = [];
    } else if (state.feature.coordinates.length > 1) {
      let resultPolygon: any = [state.feature.coordinates[0]].map((polygon: any) => [...polygon, polygon[0]]);

      for (const coordinate of state.feature.coordinates.slice(1)) {
        resultPolygon = turf.difference(turf.polygon(resultPolygon), turf.polygon([[...coordinate, coordinate[0]]]))?.geometry.coordinates;
      }

      state.feature.coordinates = resultPolygon.map((polygon: any) => polygon.slice(0, -1));
    }
  }

  if (state.feature.type === 'MultiPolygon') {
    state.feature.features = state.feature.features.filter((feature: any) => !!feature.coordinates.length);
  }

  if (!state.feature.isValid()) {
    this.changeMode(MAP_MODES.STATIC);
    setTimeout(() => this.deleteFeature([state.featureId]), 100);
    state.setFinishedDrawingField(false);
  }
};

// Called to determine how to render the editable feature on the mapbox
PointDeletion.toDisplayFeatures = function (state: any, geojson: any, push: any) {
  if (state.featureId === geojson.properties.id) {
    geojson.properties.active = activeStates.ACTIVE;
    push(geojson);
    createSupplementaryPoints(geojson, {
      map: this.map,
      // Don't display midpoints since they cannot be deleted
      midpoints: false,
      selectedPaths: state.selectedCoordPaths,
    }).forEach(push);
  } else {
    geojson.properties.active = activeStates.INACTIVE;
    push(geojson);
  }
  this.fireActionable(state);
};

// Change the onVertex trigger from onMouseDown to onClick.
// This is to match the onVertex trigger of MAP_MODES.DRAW_FIELD.
// Without this, when deleting the last vertex and changing to MAP_MODES.DRAW_FIELD,
// a new vertex would be added automatically where the last point was deleted.
// This would occur because the MAP_MODES.DRAW_FIELD onClick/onVertex functions are called only
// when the mouse button is released, but we were changing the mode when the mouse button was pressed.
PointDeletion.onMouseDown = function () {
  return undefined;
};
PointDeletion.onTouchStart = PointDeletion.onMouseDown;

PointDeletion.onClick = function (state: any, event: any) {
  if (isVertex(event) && event.type === 'mousedown') {
    return this.onVertex(state, event);
  }
  return undefined;
};
PointDeletion.onMouseDown = PointDeletion.onClick;

export default PointDeletion;
