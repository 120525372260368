import { AnyAction } from 'redux';

const initState: any = {
  activeCampaigns: [],
  activeCampaignsState: {
    lifeCycle: 'NONE',
  },
  selectedCampaign: null,
  fullLeads: [],
  howDidYouHearOptions: [],
  selectedHowDidYouHearOption: null,
  representativeOptions: [],
  selectedRepresentativeOption: null,
};

const reducer = (state = initState, action: AnyAction) => {
  switch (action.type) {
    case 'GET_ACTIVE_CAMPAIGNS_REQUESTED': {
      return {
        ...state,
        activeCampaignsState: { lifeCycle: 'PENDING' },
      };
    }
    case 'GET_ACTIVE_CAMPAIGNS_SUCCEEDED': {
      return {
        ...state,
        activeCampaigns: action.payload,
        activeCampaignsState: { lifeCycle: 'SUCCESS' },
      };
    }
    case 'CAMPAIGN_SELECTED': {
      return {
        ...state,
        selectedCampaign: action.payload,
      };
    }
    case 'GET_LEAD_LIST_SUCCEEDED': {
      return {
        ...state,
        fullLeads: action.payload,
        leadListState: { lifeCycle: 'SUCCESS' },
      };
    }
    case 'GET_HOW_DID_YOU_HEAR_OPTIONS_SUCCEEDED': {
      return {
        ...state,
        howDidYouHearOptions: action.payload,
      };
    }
    case 'HOW_DID_YOU_HEAR_OPTION_SELECTED': {
      return {
        ...state,
        selectedHowDidYouHearOption: action.payload,
      };
    }
    case 'GET_REPRESENTATIVE_OPTIONS_SUCCEEDED': {
      return {
        ...state,
        representativeOptions: action.payload,
      };
    }
    case 'REPRESENTATIVE_OPTION_SELECTED': {
      return {
        ...state,
        selectedRepresentativeOption: action.payload,
      };
    }
  }
  return state;
};

export default reducer;
