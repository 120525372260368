import { Box, Select, Text } from 'grommet';
import { useState } from 'react';

interface LocationInputProps {
  label: string;
  width?: string;
  value?: string | object;
  disabled?: boolean;
  titleWeight?: number;
  options: string[] | object[];
  clearButton?: boolean;
  labelKey?: string | ((...args: any[]) => string);
  onChange: (value: any) => void;
}

export const LocationInput = ({ label, width, value, disabled, titleWeight, options, clearButton, labelKey, onChange }: LocationInputProps) => {
  const [filteredOptions, setFilteredOptions] = useState(options);

  function getDisabledValue() {
    if (value) {
      if (typeof value === 'string' && value.length) {
        return value;
      } else if (labelKey) {
        if (typeof labelKey === 'string') return value[labelKey as keyof typeof value];
        return labelKey(value);
      }
    }
    return '--';
  }

  return (
    <Box width={width ?? '24.2%'} height={'44px'} direction={'column'}>
      <Text size={'16px'} style={{ lineHeight: '20px', whiteSpace: 'nowrap' }} color={'#282828'} weight={titleWeight ?? 700}>
        {label}
      </Text>
      <Box height={{ min: '29px' }} style={{ lineHeight: '16px', borderRadius: '0' }}>
        {disabled ? (
          <Text size={'16px'} style={{ lineHeight: '16px', whiteSpace: 'nowrap' }} color={'#282828'} weight={400}>
            {getDisabledValue()}
          </Text>
        ) : (
          <Select
            plain
            clear={clearButton}
            focusIndicator={false}
            size={'16px'}
            height={'24px'}
            style={{ lineHeight: '16px', padding: '4px', borderRadius: '0', fontWeight: '400' }}
            value={value}
            onOpen={() => setFilteredOptions(options)}
            onSearch={(search) =>
              setFilteredOptions((filteredOptions) => {
                //@ts-ignore
                return options.filter((option: any) => {
                  if (!labelKey) return option.toLowerCase().includes(search.toLowerCase());
                  if (typeof labelKey === 'string') {
                    return option[labelKey].toLowerCase().includes(search.toLowerCase());
                  }
                  return labelKey(option).toLowerCase().includes(search.toLowerCase());
                });
              })
            }
            options={filteredOptions}
            labelKey={labelKey}
            onChange={(event) => onChange(labelKey ? event.option : event.target.value)}
          />
        )}
      </Box>
    </Box>
  );
};
