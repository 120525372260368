import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import contextReducer from './reducers/context';
import leadReducer from './reducers/lead-reducer';
import contractReducer from './reducers/contract-reducer';
import producerReducer from './reducers/producer-reducer';
import userReducer from './reducers/user-reducer';
import estimateReducer from './reducers/estimate-reducer';
import fieldReducer from './reducers/field-reducer';
import dataCollectionReducer from './reducers/data-collection-reducer';

const logger = createLogger({
  collapsed: true,
});
const store = configureStore({
  reducer: {
    producer: producerReducer,
    leads: leadReducer,
    context: contextReducer,
    contract: contractReducer,
    user: userReducer,
    estimate: estimateReducer,
    field: fieldReducer,
    dataCollection: dataCollectionReducer,
  },
  middleware: [thunk, logger as any],
});
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
