import { Box, Text } from 'grommet';
import { Event, EventTypes, FeedingSubType, FireSubType, SupplementingSubType } from '../../types/EventTypes';
import eventIcon from '../../../../assets/BoundaryTool/infoIcons/events.svg';
import deleteIcon from '../../../../assets/BoundaryTool/deleteIcon.svg';
import { TextInput as TextInputGrommet } from 'grommet/components/TextInput';
import React from 'react';
import { Select } from '../Input/Select';
import { TextInput } from '../Input/TextInput';
import { Feature } from '@turf/turf';
import FireForm from './EventForms/FireForm';
import { DateRange } from '../Input/DateRange';
import { useMapContext } from '../../service/map/MapContext';
import { LocationInput } from '../Input/LocationInput';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

interface EventItemParams {
  event: Event;
  updateEvent: (herd: any) => void;
  removeEvent: (herd: any) => void;
  fields: Feature[];
  paddocks: { [key: string]: Feature[] };
  simple?: boolean;
}

export const getEventTypeDictionary = (t: TFunction) => {
  return {
    FEEDING: t('fieldFlow.eventItem.feeding'),
    SUPPLEMENTING: t('fieldFlow.eventItem.supplementing'),
    HAYING: t('fieldFlow.eventItem.haying'),
    FIRE: t('fieldFlow.eventItem.fire'),
  };
};

export const getEventSubtypeDictionary = (t: TFunction) => {
  return {
    HAY: t('fieldFlow.eventItem.subType.hay'),
    SILAGE: t('fieldFlow.eventItem.subType.silage'),
    SWEET_MIX: t('fieldFlow.eventItem.subType.sweetMix'),
    MINERAL: t('fieldFlow.eventItem.subType.mineral'),
    PROTEIN_TUBS: t('fieldFlow.eventItem.subType.proteinTubs'),
    CAKE: t('fieldFlow.eventItem.subType.cake'),
    OTHER: t('fieldFlow.eventItem.subType.other'),
    PRESCRIBED: t('fieldFlow.eventItem.subType.prescribed'),
    WILD: t('fieldFlow.eventItem.subType.wild'),
  };
};

export const EventItem = ({ event, updateEvent, removeEvent, fields, paddocks, simple }: EventItemParams): JSX.Element => {
  const { t } = useTranslation();
  const { currentVersion } = useMapContext();
  const EventTypeDictionary = getEventTypeDictionary(t);
  const EventSubtypeDictionary = getEventSubtypeDictionary(t);

  const eventTypeList = Object.keys(EventTypes).map((eventType) => EventTypeDictionary[eventType as keyof typeof EventTypeDictionary]);
  const dateRangeForm = (
    <DateRange
      startDate={event.data?.startDate}
      endDate={event.data?.endDate}
      minStartDate={currentVersion.startDate}
      maxStartDate={currentVersion.endDate}
      minEndDate={currentVersion.startDate}
      maxEndDate={currentVersion.endDate}
      onChange={(dates) => {
        event.data = { ...event.data, ...dates };
        updateEvent(event);
      }}
    />
  );
  const typeInfo = {
    FEEDING: {
      form: dateRangeForm,
      subType: FeedingSubType,
      subTypeList: Object.keys(FeedingSubType).map((type) => EventSubtypeDictionary[type as keyof typeof EventSubtypeDictionary]),
    },
    SUPPLEMENTING: {
      form: dateRangeForm,
      subType: SupplementingSubType,
      subTypeList: Object.keys(SupplementingSubType).map((type) => EventSubtypeDictionary[type as keyof typeof EventSubtypeDictionary]),
    },
    HAYING: {
      form: dateRangeForm,
      subType: undefined,
      subTypeList: [],
    },
    FIRE: {
      form: <FireForm event={event} updateEvent={updateEvent} startDate={currentVersion.startDate} endDate={currentVersion.endDate ?? new Date()} />,
      subType: FireSubType,
      subTypeList: Object.keys(FireSubType).map((type) => EventSubtypeDictionary[type as keyof typeof EventSubtypeDictionary]),
    },
  };

  function convertToType(name: string) {
    return Object.keys(EventTypeDictionary).find((key) => EventTypeDictionary[key as keyof typeof EventTypeDictionary] === name);
  }

  return (
    <Box
      key={event.id.toString()}
      pad={{ bottom: '1.5rem' }}
      gap={'1.25rem'}
      height={{ min: 'fit-content' }}
      border={!simple ? { side: 'bottom', size: '1px', color: '#494949' } : undefined}
    >
      <Box direction={'row'} align={'center'}>
        <img src={eventIcon} height={36} width={36} alt='Active indicator' />

        <Box width={'144px'} margin={{ left: '8px', right: '11px' }}>
          <TextInputGrommet
            plain
            focusIndicator={false}
            placeholder={'Event name'}
            value={event.data?.name ?? ''}
            style={{ height: '36px', border: '1px solid #282828' }}
            onChange={(e) => {
              event.data = event.data || {};
              event.data.name = e.target.value;
              updateEvent(event);
            }}
          />
        </Box>
        <img
          src={deleteIcon}
          height={24}
          width={24}
          alt='Delete Icon'
          style={{
            cursor: 'pointer',
          }}
          onClick={(e) => {
            e.stopPropagation();
            removeEvent(event);
          }}
        />
      </Box>

      <Box>
        <Text size={'16px'} style={{ lineHeight: '20px' }} color={'#1457A8'} weight={700} margin={{ bottom: '8px' }}>
          {t('fieldFlow.eventItem.title')}{' '}
        </Text>
        <Box direction={'row'} gap={'1rem'}>
          <Select
            label={t('fieldFlow.eventItem.eventType')}
            width={'33%'}
            value={EventTypeDictionary[event.type as keyof typeof EventTypeDictionary]}
            options={eventTypeList}
            onChange={(type) => {
              event.data = {
                name: event.data?.name,
                fromField: event.data?.fromField,
                fromPaddock: event.data?.fromPaddock,
              };
              event.type = EventTypes[convertToType(type) as keyof typeof EventTypes];
              updateEvent(event);
            }}
          />
          {event.type !== 'HAYING' && (
            <TextInput
              label={t('fieldFlow.eventItem.subType.title')}
              width={'33%'}
              value={event.data?.subType ?? ''}
              suggestions={typeInfo[event.type as keyof typeof typeInfo].subTypeList}
              onChange={(subType) => {
                event.data = event.data || {};
                event.data.subType = subType;
                updateEvent(event);
              }}
            />
          )}

          {event.type === 'FIRE' ? (
            <TextInput
              label={t('fieldFlow.eventItem.acresBurned')}
              width={'33%'}
              type={'number'}
              min={0}
              onlyIntegers={true}
              onlyPositive={true}
              value={event.data?.acresBurned?.toString() ?? ''}
              onChange={(acresBurned) => {
                event.data = event.data || {};
                event.data.acresBurned = parseFloat(acresBurned);
                updateEvent(event);
              }}
            />
          ) : (
            <TextInput
              label={t('fieldFlow.eventItem.totalAmount')}
              width={'33%'}
              type={'number'}
              min={0}
              onlyIntegers={true}
              onlyPositive={true}
              value={event.data?.amount?.toString() ?? ''}
              onChange={(amount) => {
                event.data = event.data || {};
                event.data.amount = Number(amount);
                updateEvent(event);
              }}
            />
          )}

          {event.type === 'HAYING' && <Box width={'33%'} style={{ visibility: 'hidden' }} />}
        </Box>
      </Box>

      {typeInfo[event.type as keyof typeof typeInfo].form}

      <Box>
        <Text size={'16px'} style={{ lineHeight: '20px' }} color={'#1457A8'} weight={700}>
          {t('fieldFlow.herdItem.locationInfo.title')}{' '}
        </Text>
        <Box direction={'row'} margin={{ top: '8px' }} gap={'1rem'} width={'66%'}>
          <LocationInput
            label={t('fieldFlow.field')}
            width={'50%'}
            value={fields.find((field) => field.id === event.fieldId)}
            options={fields}
            labelKey={(field) => field.properties.name}
            onChange={(field) => {
              event.data = event.data || {};
              event.data.fromField = field.properties.name;
              event.fieldId = field.id;
              if (paddocks[field.id].length === 1) {
                event.paddockIds = [paddocks[field.id][0].id!.toString()];
                event.data.fromPaddock = paddocks[field.id][0].properties!.name;
              } else if (event.fieldId !== field.id) {
                delete event.data.fromPaddock;
                event.paddockIds = [];
              }
              updateEvent(event);
            }}
          />

          {event.fieldId && paddocks[event.fieldId].length > 1 && (
            <LocationInput
              width={'50%'}
              label={t('fieldFlow.herdItem.locationInfo.paddock')}
              clearButton={true}
              value={paddocks[event.fieldId]?.find((paddock) => paddock.id === event.paddockIds[0])}
              options={paddocks[event.fieldId!]}
              labelKey={(paddock: any) => paddock.properties.name}
              onChange={(paddock: any) => {
                event.data = event.data || {};
                if (paddock) {
                  event.data.fromPaddock = paddock.properties.name;
                  event.paddockIds = [paddock.id];
                } else {
                  delete event.data.fromPaddock;
                  event.paddockIds = [];
                }
                updateEvent(event);
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EventItem;
