import React, { createContext, useContext } from "react";
import { ResponsiveContext } from "grommet";

interface ResponsiveContextWrapperType {
    size: string,
    fontSize: {
        xxSmall: string,
        xSmall: string,
        small: string,
        medium: string,
        large: string,
        xLarge: string,
        xxLarge: string,
        subtitle: string,
        title: string,
    }
}

const ResponsiveContextWrapper = createContext<ResponsiveContextWrapperType>(
    {} as ResponsiveContextWrapperType,
);
export const useResponsiveWrapper = () => useContext(ResponsiveContextWrapper);

export const getFontSize = (size: string = 'medium') => {
    switch (size) {
        case 'large': {
            return {
                xxSmall: '0.5rem',
                xSmall: '0.75rem',
                small: '0.825rem',
                medium: '1rem',
                large: '1.25rem',
                xLarge: '1.5rem',
                xxLarge: '1.75rem',
                subtitle: '2rem',
                title: '2.5rem'
            }
        }
        case 'medium': {
            return {
                xxSmall: '0.5rem',
                xSmall: '0.625rem',
                small: '0.75rem',
                medium: '0.825rem',
                large: '1rem',
                xLarge: '1.25rem',
                xxLarge: '1.5rem',
                subtitle: '1.75rem',
                title: '2rem',
            }
        }
        default: {
            return {
                xxSmall: '0.5rem',
                xSmall: '0.625rem',
                small: '0.75rem',
                medium: '0.825rem',
                large: '1rem',
                xLarge: '1.25rem',
                xxLarge: '1.5rem',
                subtitle: '1.75rem',
                title: '2rem',
            }
        }
    }
}

export function ResponsiveWrapperProvider(
    {
        children,
    }: {
        children: React.ReactNode;
    }) {
    const size = useContext(ResponsiveContext);
    const fontSize = getFontSize(size);

    return (
        <ResponsiveContextWrapper.Provider value={{ size, fontSize }}>
            {children}
        </ResponsiveContextWrapper.Provider>
    );
}