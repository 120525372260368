import { Box, Text } from 'grommet';
import { t } from 'i18next';

export type Levels = 'Farm' | 'Field' | 'Paddock';

export interface LevelTagProps {
  level: Levels;
  margin?: string;
}

interface LevelColorDict {
  Farm: string;
  Field: string;
  Paddock: string;
}

export const LevelTag = ({ level, margin }: LevelTagProps): JSX.Element => {
  const levelColorDict: LevelColorDict = {
    Farm: '#D03450',
    Field: '#E7A325',
    Paddock: '#96C5FF',
  };

  return (
    <Box
      margin={margin ?? ''}
      background={`${levelColorDict[level as keyof LevelColorDict]}30`}
      border={{ color: levelColorDict[level as keyof LevelColorDict], style: 'solid', size: '1px' }}
      pad={'0 0.5rem'}
      height={'fit-content'}
      style={{
        minWidth: 'unset',
        borderRadius: '5px',
      }}
    >
      <Text weight={400} style={{ whiteSpace: 'nowrap' }}>
        {`${t(`fieldFlow.mainWizard.levelTag.${level}`)}`}
      </Text>
    </Box>
  );
};

export default LevelTag;
