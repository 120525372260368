import { Journey } from '../types/GrowerService';
import { Lead } from '../types/Lead';

export function formatDate(date: Date | string) {
  if (!date) return '';
  const formatter = Intl.DateTimeFormat(undefined, { timeZone: 'UTC', month: '2-digit', day: '2-digit', year: 'numeric' });
  return formatter.format(new Date(date));
}

export function formatDateWithMonthName(date: Date | string) {
  if (!date) return '';
  const formatter = Intl.DateTimeFormat('en-US', { timeZone: 'UTC', month: 'long', day: '2-digit', year: 'numeric' });
  return formatter.format(new Date(date));
}

export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function nestedProperty(obj: any, path: string) {
  return path.split('.').reduce((value, el) => value[el], obj) ?? '';
}

export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export function getAdminURL(journey: Journey | undefined, lead: Lead | undefined) {
  const baseUrl = process.env?.['NX_REACT_APP_ADMIN_PORTAL_URL'] ?? process.env?.['REACT_APP_ADMIN_PORTAL_URL'];
  if (baseUrl) {
    if (journey) {
      return `${baseUrl}/campaigns?journeyId=${journey.id}`;
    }
    return `${baseUrl}/campaigns?salesforceId=${lead?.opportunityId ?? ''}`;
  }
  return '';
}

export const AUTH0_ROLES = {
  DELEGATED_ADMIN_ADMIN: 'Delegated Admin - Administrator',
  DELEGATED_ADMIN_USER: 'Delegated Admin - User',
  SAMPLING_CAMPAIGN_REVIEW: 'Sampling Campaigns Reviewer',
  SAMPLING_CREATOR_VIP: 'Sampling Campaign Creator VIP',
  SCRIPT_RUNNER: 'Script Runner',
  ADMIN_TEST_USER: 'Admin Test User',
  BOUNDARY_ANALYST: 'Boundary Analyst',
};
