import { Box, Text } from 'grommet';
import { FormNextLink } from 'grommet-icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DateRangeExample = () => {
  const { t } = useTranslation();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Text size='26px' color='#282828' weight={800} style={{ lineHeight: '28px' }}>
        {t('fieldFlow.dateRangeModal.example.title')}
      </Text>
      <Text textAlign={'justify'} margin={{ top: '8px' }} size='16px' color='#494949' weight={400} style={{ lineHeight: '21px' }}>
        {t('fieldFlow.dateRangeModal.example.message1')}
      </Text>
      <Box direction={'row'} gap={'1.75rem'} height={'32vh'} margin={'1rem 0'}>
        <Box align={'center'} width={'100%'}>
          <Text size={'1.25rem'} weight={900}>
            {t('fieldFlow.dateRangeModal.example.firstVersion')}
          </Text>
          <Box
            width={'100%'}
            height={'100%'}
            style={{ maxHeight: '250px' }}
            background={{ color: '#0D0D0D', opacity: 0.7 }}
            border={{ color: '#1457A8', size: '3px' }}
          >
            <Box justify={'center'} align={'center'} height={'50%'} width={'100%'} border={{ color: '#FFE137', style: 'dashed', side: 'bottom' }}>
              <Text weight={400}>{t('fieldFlow.dateRangeModal.example.paddock1')}</Text>
            </Box>
            <Box justify={'center'} align={'center'} height={'50%'} width={'100%'}>
              <Text weight={400}>{t('fieldFlow.dateRangeModal.example.paddock2')}</Text>
            </Box>
          </Box>
          <Text>{t('fieldFlow.dateRangeModal.example.timeframe')}</Text>
          <Text>01/01/2020 - 06/07/2020</Text>
        </Box>
        <FormNextLink height={'24px'} style={{ alignSelf: 'center', margin: '1.25rem 0 0 0' }} />
        <Box align={'center'} width={'100%'}>
          <Text size={'1.25rem'} weight={900}>
            {t('fieldFlow.dateRangeModal.example.secondVersion')}
          </Text>
          <Box
            width={'185px'}
            height={'100%'}
            style={{ maxHeight: '250px' }}
            background={{ color: '#0D0D0D', opacity: 0.7 }}
            border={{ color: '#1457A8', size: '3px' }}
          >
            <Box justify={'center'} align={'center'} height={'50%'} width={'100%'} border={{ color: '#FFE137', style: 'dashed', side: 'bottom' }}>
              <Text weight={400}>{t('fieldFlow.dateRangeModal.example.paddock1')}</Text>
            </Box>
            <Box direction={'row'} justify={'center'} align={'center'} height={'100%'} width={'100%'}>
              <Box width={'50%'} height={'100%'} align={'center'} justify={'center'} border={{ color: '#FFE137', style: 'dashed', side: 'right' }}>
                <Text style={windowHeight < 570 ? {} : { transform: 'rotate(180deg)', writingMode: 'vertical-lr' }} weight={400}>
                  {t('fieldFlow.dateRangeModal.example.paddock2')}
                </Text>
              </Box>
              <Box width={'50%'} height={'100%'} align={'center'} justify={'center'}>
                <Text style={windowHeight < 570 ? {} : { transform: 'rotate(180deg)', writingMode: 'vertical-lr' }} weight={400}>
                  {t('fieldFlow.dateRangeModal.example.paddock3')}
                </Text>
              </Box>
            </Box>
          </Box>
          <Text>{t('fieldFlow.dateRangeModal.example.timeframe')}</Text>
          <Text>07/07/2020 - 12/31/2021</Text>
        </Box>
      </Box>
    </>
  );
};

export default DateRangeExample;
