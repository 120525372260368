import { Feature, polygon } from '@turf/helpers';
import * as polygonClipping from 'polyclip-ts';
import { Polygon, Position } from '@turf/turf';

export class MapUtilities {
  static findFeaturesIntersection(f1: Feature, f2: Feature) {
    const result = polygonClipping.intersection((f1.geometry as Polygon).coordinates as any, (f2.geometry as Polygon).coordinates as any);
    return !!result.length;
  }

  static findMultiPolygonIntersection(f: Feature) {
    if (f.geometry.type !== 'MultiPolygon') return false;
    const polygons = f.geometry.coordinates.map((c) => polygon(c as Position[][]));
    let intersects = false;
    for (let i = 0; i < polygons.length; i++) {
      for (let j = i; j < polygons.length; j++) {
        if (i === j) continue;
        if (this.findFeaturesIntersection(polygons[i], polygons[j])) {
          intersects = true;
          break;
        }
      }
    }
    return intersects;
  }
}
