import lineIntersect from '@turf/line-intersect';
import booleanDisjoint from '@turf/boolean-disjoint';
import lineOffset from '@turf/line-offset';
import lineToPolygon from '@turf/line-to-polygon';
import difference from '@turf/difference';
import { lineString } from '@turf/helpers';
import * as turf from '@turf/turf';
import { MAP_MODES } from './modes';

const SplitPolygonMode: any = {};

SplitPolygonMode.onSetup = function (opt: any) {
  const featureId = opt.featureId,
    featureIds = opt.featureIds;
  const main = featureIds?.length > 0 ? featureIds.map((featureId: string) => this.getFeature(featureId)) : this.getFeature(featureId);

  setTimeout(() => {
    this.changeMode(MAP_MODES.PASSING_MODE, {
      onDraw: (cuttingLineString: any) => {
        const allPoly: any[] = [];
        if (Array.isArray(main)) {
          main.forEach((feature: any) => {
            const polycut = polygonCut(feature, cuttingLineString.geometry);
            if (polycut && polycut.geometry.coordinates.length >= 2) {
              polycut!.id = feature.id;
              allPoly.push(polycut);
            } else {
              allPoly.push({ ...feature, type: 'Feature', geometry: { coordinates: feature.coordinates, type: feature.type }, ctx: undefined });
            }
          });
        } else if (main.type === 'MultiPolygon') {
          const features = main.features;
          features.forEach((feature: any) => {
            const polycut = polygonCut(feature, cuttingLineString.geometry);
            if (polycut !== null) {
              polycut!.id = feature.id;
              if (!polycut!.properties || !polycut!.properties['fieldId']) {
                polycut!.properties = polycut!.properties || {};
                polycut!.properties['fieldId'] = main.id;
              }
              allPoly.push(polycut);
            } else {
              allPoly.push({
                ...feature,
                properties: { fieldId: main.id, ...feature.properties },
                type: 'Feature',
                geometry: { coordinates: feature.coordinates, type: feature.type },
                ctx: undefined,
              });
            }
          });
        } else {
          const polycut = polygonCut(main, cuttingLineString.geometry);
          if (!polycut || polycut.geometry.coordinates.length < 2) {
            if (!main.properties['fieldId']) main.properties['fieldId'] = main.id;
            allPoly.push({ ...main, type: 'Feature', geometry: { coordinates: main.coordinates, type: main.type }, ctx: undefined });
          } else {
            polycut!.id = main.id;
            polycut!.properties!['fieldId'] = main.properties?.fieldId || main.id;
            allPoly.push(polycut);
          }
        }

        this.deleteFeature([cuttingLineString.id], { silent: true });
        const ids: string[] = [];
        allPoly.forEach((poly) => {
          const feature = this.newFeature(poly);
          if (feature.type === 'MultiPolygon') {
            feature.features = feature.features.map((featureA: any) => {
              featureA.coordinates = poly.geometry.coordinates[feature.features.indexOf(featureA)];
              featureA.properties = poly.properties;
              return featureA;
            });
          } else {
            feature.coordinates = poly.coordinates;
            feature.properties = poly.properties;
          }
          this.addFeature(feature);
        });
        this.fireUpdate(
          allPoly
            .map((poly) => this.getFeature(poly.id))
            .reduce((p, feature) => {
              if (feature.type === 'MultiPolygon') {
                this.deleteFeature([feature.id], { silent: true });
                feature.features.map((feature: any) => {
                  ids.push(feature.id);
                  this.addFeature(feature);
                  p.push(feature);
                  return feature;
                });
              } else {
                if (feature.coordinates[0][0] !== feature.coordinates[0][feature.coordinates[0].length - 1]) {
                  feature.coordinates[0].push(feature.coordinates[0][0]);
                }
                ids.push(feature.id);
                p.push(feature);
              }
              return p;
            }, []),
        );

        setTimeout(() => this.changeMode(MAP_MODES.SPLIT_FIELD, { featureIds: ids }), 0);
      },
    });
  }, 0);

  return {
    main,
  };
};

SplitPolygonMode.toDisplayFeatures = function (state: any, geojson: any, display: any) {
  display(geojson);
};

SplitPolygonMode.fireUpdate = function (newF: any) {
  this.map.fire('draw.update', {
    action: 'SplitPolygon',
    features: newF,
  });
};

export default SplitPolygonMode;

// Adopted from https://gis.stackexchange.com/a/344277/145409
function polygonCut(poly: any, line: any) {
  const line_width = 0.001,
    line_width_unit = 'kilometers';

  const offsetLine: any[] = [];
  const retVal = null;
  let i, j, forCut;
  let thickLineString, thickLinePolygon, clipped;

  if ((poly.type !== 'Polygon' && poly.type !== 'MultiPolygon') || line.type !== 'LineString') {
    return retVal;
  }

  const intersectPoints = lineIntersect(poly, line);
  if (intersectPoints.features.length === 0) {
    return retVal;
  }

  const first = turf.point(line.coordinates[0]);
  const last = turf.point(line.coordinates.at(-1));
  if (turf.inside(first, poly) || turf.inside(last, poly)) {
    return retVal;
  }

  if (booleanDisjoint(line, poly)) {
    return retVal;
  }

  offsetLine[0] = lineOffset(line, line_width, {
    units: line_width_unit,
  });

  offsetLine[1] = lineOffset(line, -line_width, {
    units: line_width_unit,
  });

  for (i = 0; i <= 1; i++) {
    forCut = i;
    const polyCoords: any[] = [];
    for (j = 0; j < line.coordinates.length; j++) {
      polyCoords.push(line.coordinates[j]);
    }
    for (j = offsetLine[forCut].geometry.coordinates.length - 1; j >= 0; j--) {
      polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
    }
    polyCoords.push(line.coordinates[0]);

    thickLineString = lineString(polyCoords);
    thickLinePolygon = lineToPolygon(thickLineString);
    clipped = difference(poly, thickLinePolygon);
  }

  return clipped;
}
