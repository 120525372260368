import { ContractInfo, ContractPracticeList } from '../../../service/contract/interfaces';
import { cloneDeep } from 'lodash';
import { treatContractDataFormat } from '../../../redux/actions/contract-action';

export const isTextAreaValid = (value: any) => {
  return !(!value || value === '' || (value.ops && value.ops.length <= 1 && value.ops[0].insert === '\n'));
};

export const isAreaEnrolledSmallerThanTotalArea = (value: any, formValues: ContractInfo) => {
  return Number(value) <= Number(formValues.enrollmentInfoSection.totalAreaEnrolled);
};

export const isLeased = (formValues: any) => {
  return (
    !!formValues.enrollmentInfoSection.areaLeased &&
    !isNaN(parseFloat(formValues.enrollmentInfoSection.areaLeased)) &&
    parseFloat(formValues.enrollmentInfoSection.areaLeased) !== 0
  );
};

export const isOther = (formValues: any) => {
  return (
    !!formValues.enrollmentInfoSection.areaOther &&
    !isNaN(parseFloat(formValues.enrollmentInfoSection.areaOther)) &&
    parseFloat(formValues.enrollmentInfoSection.areaOther) !== 0
  );
};

export const isLeaseOrOtherDateFilled = (value: any, formValues: any) => {
  if (isLeased(formValues) || isOther(formValues)) {
    return !!value;
  }
  return true;
};

export const showLeaseDateInputs = (formValues: any) => {
  return isLeased({ enrollmentInfoSection: formValues }) || isOther({ enrollmentInfoSection: formValues });
};

export const removeTrailingZeros = (number: string | number, fractionDigits: number = 2): string => {
  let numberAsNumber = typeof number == 'number' ? number : Number(number);
  if (isNaN(numberAsNumber)) {
    return numberAsNumber.toString();
  }

  // Converted to number and back to remove zeros from numbers like 1.000001 -> 1.00 -> 1
  return Number(numberAsNumber.toFixed(fractionDigits)).toFixed(fractionDigits);
};

export function applyMask(value: string, mask: string) {
  //Remove masked chars
  value = value.replace(/[^\w\s]/g, '');
  let valueIndex = 0;
  let maskedValue = '';
  for (let i = 0; i < mask.length && valueIndex < value.length; i++) {
    if (mask[i] !== '#') {
      maskedValue += mask[i];
    } else if (value[valueIndex]) {
      maskedValue += value[valueIndex];
      valueIndex++;
    } else {
      return maskedValue;
    }
  }
  return maskedValue;
}

function getContractOnlyPracticeInfo(practices: ContractPracticeList) {
  const contractOnlyPractices: any = {};
  for (const [key, value] of Object.entries(practices)) {
    contractOnlyPractices[key] = {
      quarter: value.quarter,
      startDate: value.startDate,
    };
  }
  return contractOnlyPractices;
}

function getEstimateOnlyPracticeInfo(practices: ContractPracticeList) {
  const contractOnlyPractices: any = {};
  for (const [key, value] of Object.entries(practices)) {
    contractOnlyPractices[key] = {
      totalArea: value.totalArea,
      optionA: value.optionA,
      optionB: value.optionB,
      paymentOption: value.paymentOption,
    };
  }
  return contractOnlyPractices;
}

function deepEqual(xInput: any, yInput: any): any {
  let x = cloneDeep(xInput);
  let y = cloneDeep(yInput);
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  if (tx === 'number' || (tx === 'string' && !isNaN(Number(x)))) {
    x = removeTrailingZeros(x);
  }
  if (ty === 'number' || (ty === 'string' && !isNaN(Number(y)))) {
    y = removeTrailingZeros(y);
  }
  if (x === null) {
    x = '';
  }
  if (y === null) {
    y = '';
  }
  return x && y && tx === 'object' && tx === ty ? ok(x).length === ok(y).length && ok(x).every((key) => deepEqual(x[key], y[key])) : x === y;
}

export function deepEqualContractData(contractInfo, riskAssessment) {
  const contractData = {
    ...contractInfo,
    practices: getContractOnlyPracticeInfo(contractInfo.practices),
  };
  delete contractData._meta;

  const riskAssessmentData = riskAssessment
    ? {
        ...riskAssessment.data,
        practices: getContractOnlyPracticeInfo(riskAssessment.data.practices),
      }
    : undefined;

  const deepEqualContractRiskAssessment = () => {
    if (!riskAssessmentData) {
      return true;
    }
    let contractClone: any = cloneDeep(contractData);
    let raClone: any = cloneDeep(riskAssessmentData);
    treatContractDataFormat(raClone);
    delete contractClone.history;
    delete raClone.history;
    delete contractClone.contractVersionId;
    delete raClone.contractVersionId;
    return deepEqual(contractClone, raClone);
  };

  const deepEqualEstimates = () => {
    if (!riskAssessmentData) {
      return true;
    }
    const contractEstimateData = getEstimateOnlyPracticeInfo(contractInfo.practices);
    const riskAssessmentEstimateData = getEstimateOnlyPracticeInfo(riskAssessment.data.practices);
    return deepEqual(contractEstimateData, riskAssessmentEstimateData);
  };

  return { deepEqualContractRiskAssessment, deepEqualEstimates, riskAssessmentData };
}
