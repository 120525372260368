import { Box, Layer, Spinner, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import React from 'react';

const FieldProcessingModal = () => {
  const { t } = useTranslation();

  return (
    <Layer background={{ opacity: 0.7 }}>
      <Box
        data-cy={'field-processing-modal'}
        background={'white'}
        direction={'column'}
        alignSelf={'center'}
        height={'fit-content'}
        width={'80%'}
        pad={'24px'}
        className={'hideScrollBox'}
        overflow={'auto'}
        style={{ borderRadius: '1rem', display: 'unset', maxHeight: '95vh' }}
        align={'center'}
        justify={'center'}
      >
        <Text
          size={'33px'}
          weight={600}
          style={{
            lineHeight: '37px',
            marginTop: 0,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {t('fieldFlow.fieldProcessing.processingModalTitle')}
        </Text>
        <Box margin='1.5rem 0'>
          <Text
            style={{
              fontSize: '20px',
              lineHeight: '30px',
              textAlign: 'center',
            }}
          >
            {t('fieldFlow.fieldProcessing.processingModalText')}
          </Text>
        </Box>
        <Box height={'100%'} align={'center'} justify={'center'}>
          <Spinner color={'#FFE137'} size={'large'} />
        </Box>
      </Box>
    </Layer>
  );
};

export default FieldProcessingModal;
