import React from 'react';
import styled from 'styled-components';

import outerBorderIconActive from '../../../../assets/BoundaryTool/drawingPanel/outer-border-active.svg';
import outerBorderIconInactive from '../../../../assets/BoundaryTool/drawingPanel/outer-border.svg';
import deletePointIconActive from '../../../../assets/BoundaryTool/drawingPanel/delete-point-active.svg';
import deletePointIconInactive from '../../../../assets/BoundaryTool/drawingPanel/delete-point.svg';
import trashBinIconActive from '../../../../assets/BoundaryTool/drawingPanel/trash-bin-active.svg';
import trashBinIconInactive from '../../../../assets/BoundaryTool/drawingPanel/trash-bin.svg';
import innerBorderIconActive from '../../../../assets/BoundaryTool/drawingPanel/inner-border-active.svg';
import innerBorderIconInactive from '../../../../assets/BoundaryTool/drawingPanel/inner-border.svg';
import circularBorderIconInactive from '../../../../assets/BoundaryTool/drawingPanel/circular-border.svg';
import circularBorderIconActive from '../../../../assets/BoundaryTool/drawingPanel/circular-border-active.svg';
import uploadBorder from '../../../../assets/BoundaryTool/drawingPanel/upload-border.svg';

import { MAP_MODES } from './modes/modes';
import { Box, Text } from 'grommet';
import { useMapContext } from '../../service/map/MapContext';
import { useTranslation } from 'react-i18next';

interface DrawingButton {
  active: boolean;
  disabled?: boolean;
  iconActive: string;
  iconInactive: string;
  dataCyId: string;
  label: string;
  onClick: () => void;
}

const DrawingButtonStyle = styled.button`
  cursor: pointer;
  align-items: center;
  line-height: 16px;
  outline: none;
  border: none;
  background: transparent;
  border-radius: 0;
  color: #191408;
  text-align: center;
  margin: 0 0.5rem 0 0;
`;

const DrawingTools = () => {
  const { t } = useTranslation();

  const { mode, setMode, restartDraw, hasBoundaries, wizardStep, setShowConfirmationModal, setShowUploadModal } = useMapContext();

  const buttons: DrawingButton[] = [];
  if (wizardStep === 'FIELD') {
    buttons.push(
      ...[
        {
          active: mode === MAP_MODES.DIRECT_SELECT || mode === MAP_MODES.DRAW_FIELD,
          disabled: false,
          dataCyId: 'outer_border_draw_button',
          iconActive: outerBorderIconActive,
          iconInactive: outerBorderIconInactive,
          label: t('fieldFlow.drawingPanel.outerBorder'),
          onClick: () => {
            if (mode === MAP_MODES.DIRECT_SELECT || mode === MAP_MODES.DRAW_FIELD) {
              setMode(MAP_MODES.SIMPLE_SELECT);
            } else {
              setMode(MAP_MODES.DRAW_FIELD);
            }
          },
        },
        {
          active: mode === MAP_MODES.CIRCULAR_BORDER,
          disabled: false,
          dataCyId: 'circular_border_draw_button',
          iconActive: circularBorderIconActive,
          iconInactive: circularBorderIconInactive,
          label: t('fieldFlow.drawingPanel.circularBorder'),
          onClick: () => {
            if (mode === MAP_MODES.DIRECT_SELECT || mode === MAP_MODES.CIRCULAR_BORDER) {
              setMode(MAP_MODES.SIMPLE_SELECT);
            } else {
              setMode(MAP_MODES.CIRCULAR_BORDER);
            }
          },
        },
        {
          active: mode === MAP_MODES.INNER_BORDER,
          dataCyId: 'inner_border_button',
          disabled: !hasBoundaries(),
          iconActive: innerBorderIconActive,
          iconInactive: innerBorderIconInactive,
          label: t('fieldFlow.drawingPanel.innerBorder'),
          onClick: () => {
            if (mode === MAP_MODES.INNER_BORDER) {
              setMode(MAP_MODES.SIMPLE_SELECT);
            } else {
              setMode(MAP_MODES.INNER_BORDER);
            }
          },
        },
        {
          active: mode === MAP_MODES.POINT_DELETION,
          dataCyId: 'delete_point_button',
          disabled: !hasBoundaries(),
          iconActive: deletePointIconActive,
          iconInactive: deletePointIconInactive,
          label: t('fieldFlow.drawingPanel.deletePoint'),
          onClick: () => {
            if (mode === MAP_MODES.POINT_DELETION) {
              setMode(MAP_MODES.SIMPLE_SELECT);
            } else {
              setMode(MAP_MODES.POINT_DELETION);
            }
          },
        },
        {
          active: true,
          dataCyId: 'restart_draw_button',
          disabled: false,
          iconActive: trashBinIconActive,
          iconInactive: trashBinIconInactive,
          label: t('fieldFlow.drawingPanel.restart'),
          onClick: () => {
            try {
              restartDraw();
              setMode(MAP_MODES.DRAW_FIELD);
            } catch (e) {
              console.log(e);
            }
          },
        },
        {
          active: true,
          dataCyId: 'upload_border_button',
          disabled: false,
          iconActive: uploadBorder,
          iconInactive: uploadBorder,
          label: t('fieldFlow.drawingPanel.uploadBorder'),
          onClick: () => {
            setShowUploadModal(true);
          },
        },
      ],
    );
  } else if (wizardStep === 'PADDOCK') {
    buttons.push(
      ...[
        {
          active: mode === MAP_MODES.SPLIT_FIELD || mode === MAP_MODES.PASSING_MODE,
          dataCyId: 'split_field_button',
          disabled: !hasBoundaries(),
          iconActive: deletePointIconActive,
          iconInactive: deletePointIconInactive,
          label: t('fieldFlow.drawingPanel.splitField'),
          onClick: () => {
            if (mode === MAP_MODES.SPLIT_FIELD || mode === MAP_MODES.PASSING_MODE) {
              return;
            }
            setMode(MAP_MODES.SPLIT_FIELD);
          },
        },
        {
          active: true,
          dataCyId: 'restart_draw_button',
          disabled: false,
          iconActive: trashBinIconActive,
          iconInactive: trashBinIconInactive,
          label: t('fieldFlow.drawingPanel.clearAll'),
          onClick: () => {
            setShowConfirmationModal(true);
          },
        },
      ],
    );
  }

  return (
    <React.Fragment>
      {buttons.map((button: DrawingButton) => (
        <DrawingButtonStyle
          data-cy={button.dataCyId}
          key={button.label}
          onClick={button.onClick}
          style={
            button.disabled === true
              ? {
                  opacity: 0.5,
                  pointerEvents: 'none',
                }
              : { opacity: 1, pointerEvents: 'auto' }
          }
          disabled={button.disabled}
        >
          <Box style={{ alignItems: 'center' }}>
            <Text weight={button.disabled ? 400 : 'bold'} size='12px' alignSelf='start' margin={'0 0 0.1rem 0'} color={'#282828'}>
              {button.label}
            </Text>
            <img src={button.active ? button.iconActive : button.iconInactive} height={36} width={36} alt='Active indicator' />
          </Box>
        </DrawingButtonStyle>
      ))}
    </React.Fragment>
  );
};

export default DrawingTools;
