import { useCallback, useMemo, useReducer } from 'react';
import CognitoContext from './CognitoContext';
import { reducer } from './CognitoReducer';
import { initialAuthState } from './CognitoReducer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getStaticFilesBaseUrl } from '../../service/common/api';
import { CognitoIdentityProviderClient, GetUserCommand, InitiateAuthCommand } from '@aws-sdk/client-cognito-identity-provider';
import axios from 'axios';
import { BASE_URL } from '../../service/common/env';

export interface ICognitoProviderProps {
  children?: React.ReactNode;
}

const cognitoClient = new CognitoIdentityProviderClient({
  region: 'us-east-2',
});

const CognitoProvider = ({ children }: ICognitoProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const navigate = useNavigate();

  const accessToken = state.accessToken ?? localStorage.getItem('accessToken');
  const refreshToken = state.refreshToken ?? localStorage.getItem('refreshToken');
  const [searchParams] = useSearchParams();
  const queryCode = searchParams.get('code');

  const login = () => {
    const redirectLocation = window.location.pathname + (window.location.search ? window.location.search : '');
    localStorage.setItem('redirectLocation', redirectLocation);

    const cognitoHostedUiUrl = process.env.REACT_APP_COGNITO_HOSTED_UI_URL;
    const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
    const redirectUrl = getStaticFilesBaseUrl();
    window.location.href = `${cognitoHostedUiUrl}/login?client_id=${clientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${redirectUrl}/`;
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('redirectLocation');

    const cognitoHostedUiUrl = process.env.REACT_APP_COGNITO_HOSTED_UI_URL;
    const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
    const redirectUrl = getStaticFilesBaseUrl();
    window.location.href = `${cognitoHostedUiUrl}/logout?client_id=${clientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${redirectUrl}/`;
  };

  const getTokensOrLogin = useCallback(async (): Promise<string> => {
    if (!queryCode && !accessToken) login();
    if (queryCode) {
      dispatch({ type: 'GET_ACCESS_TOKEN_REQUESTED' });
      const tokens = await getTokensFromLambda(queryCode);

      localStorage.setItem('accessToken', tokens.accessToken);
      localStorage.setItem('refreshToken', tokens.refreshToken);
      localStorage.setItem('awsToken', tokens.awsToken);
      navigate(localStorage.getItem('redirectLocation') as string);

      dispatch({ type: 'GET_ACCESS_TOKEN_SUCCEEDED', payload: { ...tokens, code: queryCode } });
      getLoggedUserFromCognito(tokens.awsToken);

      return tokens.accessToken;
    }

    dispatch({ type: 'GET_ACCESS_TOKEN_REQUESTED' });
    const tokens = {
      accessToken: state.accessToken ?? localStorage.getItem('accessToken'),
      refreshToken: state.refreshToken ?? localStorage.getItem('refreshToken'),
      awsToken: state.awsToken ?? localStorage.getItem('awsToken'),
    };
    dispatch({ type: 'GET_ACCESS_TOKEN_SUCCEEDED', payload: tokens });
    return tokens.accessToken as string;
  }, [dispatch, queryCode, accessToken]);

  const refreshSessionSilently = useCallback(async (): Promise<string> => {
    if (state.isLoading) return accessToken as string;

    dispatch({ type: 'REFRESH_SESSION_REQUESTED' });

    if (!refreshToken) {
      const error = new Error('Cant refresh session - no refresh token found');
      dispatch({ type: 'REFRESH_SESSION_ERROR', payload: error });
      throw error;
    }

    const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
    const command = new InitiateAuthCommand({
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
      ClientId: clientId,
    });

    const tokens = await cognitoClient.send(command);
    if (!tokens?.AuthenticationResult?.IdToken) {
      const error = new Error('Cant refresh session - no token on response');
      dispatch({ type: 'REFRESH_SESSION_ERROR', payload: error });
      throw error;
    }

    const newAccessToken = tokens.AuthenticationResult.IdToken;
    const awsToken = tokens.AuthenticationResult.AccessToken;
    localStorage.setItem('accessToken', newAccessToken);
    dispatch({ type: 'REFRESH_SESSION_SUCCEEDED', payload: { accessToken: newAccessToken, awsToken } });

    return newAccessToken;
  }, [dispatch, refreshToken, accessToken, state.isLoading]);

  const getLoggedUserFromCognito = useCallback(
    async (userAccessToken?: string) => {
      const command = new GetUserCommand({
        AccessToken: userAccessToken ?? (accessToken as string),
      });
      const user = await cognitoClient.send(command);
      const attributes = Object.fromEntries(
        user.UserAttributes?.map((attr) => {
          return [attr.Name, attr.Value];
        }) ?? [],
      );
      const parsedUser = {
        ...attributes,
        username: user.Username,
      };
      return dispatch({ type: 'GET_USER_SUCCEEDED', payload: parsedUser });
    },
    [dispatch],
  );

  const getTokensFromLambda = async (cognitoCode: string): Promise<{ awsToken: string; accessToken: string; refreshToken: string }> => {
    const tokenGenEndpoint = `${BASE_URL}/agoro-token-gen`;
    const redirectUrl = getStaticFilesBaseUrl();

    const res = await axios.post(tokenGenEndpoint, {
      code: cognitoCode,
      redirect_uri: `${redirectUrl}/`,
    });
    return {
      accessToken: res.data.id_token,
      awsToken: res.data.access_token,
      refreshToken: res.data.refresh_token,
    };
  };

  const context = CognitoContext;
  const contextValue = useMemo(() => {
    return {
      ...state,
      login,
      logout,
      getTokensOrLogin,
      getLoggedUserFromCognito,
      refreshSessionSilently,
    };
  }, [state, login, logout, getTokensOrLogin, getLoggedUserFromCognito, refreshSessionSilently]);

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export default CognitoProvider;
