import { Box, Text } from 'grommet';

interface DetailsInfoProps {
  label: string;
  value: string;
  valueColor?: string;
}

export const DetailsInfo = ({ label, value, valueColor }: DetailsInfoProps) => {
  return (
    <Box width={{ min: 'fit-content', width: 'calc(33% - 1.125rem)' }} margin={{ top: '0.75rem' }}>
      <Text size={'16px'} style={{ lineHeight: '20px', whiteSpace: 'nowrap' }} color={'#282828'} weight={700}>
        {label}
      </Text>
      <Text style={{ whiteSpace: 'nowrap' }} color={valueColor ?? '#282828'}>
        {value ? value : '--'}
      </Text>
    </Box>
  );
};
