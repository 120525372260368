import { EventTypes, IDCCoverCroppingEvent, IDCFertilizerEvent, IDCHarvestEvent, IDCPlantingEvent, IDCTillageEvent, IEvents } from '../interfaces';

export function getDateText(dateStr: string | Date): string {
  if (!dateStr) return '';
  const date: Date = typeof dateStr === 'string' ? new Date(dateStr) : dateStr;
  return date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  });
}

export function eventsModified(eventUpdated, eventFull, typeAttributes: string[]) {
  return typeAttributes.some((attribute) => {
    if (eventUpdated[attribute] && eventFull[attribute] && Array.isArray(eventUpdated[attribute])) {
      return eventUpdated[attribute].join() !== eventFull[attribute]?.join();
    }
    return eventUpdated[attribute]?.toString()?.split('T')?.[0] !== eventFull[attribute]?.toString()?.split('T')?.[0];
  });
}

export function calculateInitialAppliedAcreage(fieldEvents: any[], fieldAcreage: number) {
  let usedAcreage = fieldEvents.reduce((acc, ev) => acc + Number(ev.appliedAcreage).toFixed(2), 0);
  const finalValue = (fieldAcreage - usedAcreage).toFixed(2);
  return Number(finalValue) > 0 ? finalValue : 0;
}

export const ErroredEventMapping: { [key in EventTypes]: (event: IEvents, otherEvent?: IDCPlantingEvent | IDCHarvestEvent[]) => boolean } = {
  Tillage: (event) => {
    const typedEvent = event as IDCTillageEvent;
    return !!typedEvent.missingData || !!typedEvent.duplicatedDate;
  },
  CoverCrop: (event) => {
    const typedEvent = event as IDCCoverCroppingEvent;
    return !!typedEvent.missingData || !!typedEvent.duplicatedDate || !!typedEvent.dateConflict || !!typedEvent.phEventOverlap;
  },
  Planting: (event, harvestEvents) => {
    const typedEvent = event as IDCPlantingEvent;
    const issuesWithPlanting =
      !!typedEvent.missingData ||
      !!typedEvent.duplicatedDate ||
      !!typedEvent.phEventOverlap ||
      !!typedEvent.ccEventOverlap ||
      !!typedEvent.missingHarvestEvent;
    if (!harvestEvents) return issuesWithPlanting;

    const relatedHarvest = (harvestEvents as IDCHarvestEvent[]).filter((harvest) => harvest.plantingId === event.id);
    let multipleHarvest = relatedHarvest.some((harvest) => harvest.duplicatedDate || harvest.missingData);

    return issuesWithPlanting || multipleHarvest;
  },
  Harvest: (event, plantingEvent) => {
    if (!plantingEvent) throw Error('Harvest evaluation needs to receive planting event');
    const typedEvent = event as IDCHarvestEvent;
    return !!typedEvent.missingData || !!typedEvent.duplicatedDate || ErroredEventMapping.Planting(plantingEvent as IDCPlantingEvent);
  },
  Fertilization: (event) => {
    const typedEvent = event as IDCFertilizerEvent;
    return !!typedEvent.missingData || !!typedEvent.duplicatedDate;
  },
  Seeding: (event) => {
    return false;
  },
  FieldData: (event) => {
    return false;
  },
};
