// @ts-ignore
import DirectSelect from '@mapbox/mapbox-gl-draw/src/modes/direct_select';
import { MAP_MODES } from './modes';
//@ts-ignore
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';

const DirectSelectMode = { ...DirectSelect };

DirectSelectMode.startDragging = function (state: any, e: any) {
  // todo remove this, we should use the correct mode to avoid dragging
  if (!CommonSelectors.isVertex(e)) {
    return;
  }
  this.map.dragPan.disable();
  state.canDragMove = true;
  state.dragMoveLocation = e.lngLat;
};

DirectSelectMode.clickNoTarget = function () {
  this.changeMode(MAP_MODES.AUTO_DIRECT_SELECT);
};

DirectSelectMode.clickInactive = function () {
  this.changeMode(MAP_MODES.AUTO_DIRECT_SELECT);
};

DirectSelectMode.onTrash = function (state: any) {
  this.deleteFeature([state.featureId], { silent: true });
  this.clearSelectedFeatures();
  this.changeMode(MAP_MODES.AUTO_DIRECT_SELECT, {});
};

export default DirectSelectMode;
