import { Box, Spinner, Text } from 'grommet';
import { useMapContext } from '../../service/map/MapContext';
import { FieldItem } from './FieldItem';
import { getPaddedBBox } from '../../service/common/utils';
import * as turf from '@turf/turf';
import { fitBoundsOption } from '../../service/map/MapService';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UnenrolledFieldItem } from './UnenrolledFieldItem';
import { useState } from 'react';
import { FormDown, FormUp } from 'grommet-icons';
import eyeIcon from '../../../../assets/BoundaryTool/eye-icon.svg';
import noEyeIcon from '../../../../assets/BoundaryTool/no-eye-icon.svg';
import { useSelector } from 'react-redux';

//Todo: this scrollbar is not like the layout. Make it a ScrollBar component
export const FieldStep = ({ t }: any) => {
  const {
    fields,
    focusField,
    updateField,
    map,
    setDeleteField,
    changeFieldEnrollmentStatus,
    syncErrors,
    country,
    toggleHideFields,
    producerData,
    practicesOnContract,
    uploadingBoundaries,
  } = useMapContext();
  const { upsellingBoundaryTool } = useFlags();
  //todo: this scrollbar is not like the layout. Make it a ScrollBar component

  const [openEnrolled, setOpenEnrolled] = useState<boolean>(true);
  const [openUnenrolled, setOpenUnenrolled] = useState<boolean>(false);
  const [fieldsHidden, setFieldsHidden] = useState<boolean>(false);

  const region = useSelector((state: any) => {
    return state.user.userData.country;
  });

  const fieldsSorted = fields.reduce(
    (res: any, f) => {
      if (f.properties?.unenrolledAt) {
        res.unenrolled.push(f);
        return res;
      }
      res.enrolled.push(f);
      return res;
    },
    { enrolled: [], unenrolled: [] },
  );

  const getFieldCollapsibles = () => {
    return (
      <Box overflow={'auto'}>
        <Box direction={'column'} pad={'12px 0'} style={{ minHeight: 'unset', borderBottom: 'solid 1px #ccc', padding: '1rem' }}>
          <Box
            width={'100%'}
            direction={'row'}
            justify={'between'}
            align={'center'}
            gap={'16px'}
            height={'30px'}
            onClick={() => setOpenEnrolled(!openEnrolled)}
          >
            <Text data-cy={`enroll-collapsible-title`} textAlign={'start'} size={'20px'} style={{ fontWeight: 900, color: '#282828' }}>
              {t('fieldFlow.mainWizard.Fields.enrollCollapsibleTitle')} ({fieldsSorted.enrolled.length})
            </Text>
            <Box direction={'row'} align={'center'}>
              {openEnrolled ? <FormUp height={'12px'} width={'12px'} /> : <FormDown height={'12px'} width={'12px'} />}
            </Box>
          </Box>

          {openEnrolled && (
            <Box>
              <Box gap={'4px'}>
                {fieldsSorted.enrolled.map((field) => (
                  <FieldItem
                    key={field.id}
                    t={t}
                    field={field}
                    focusField={(field: any) => {
                      focusField(field);
                      const newCenter = getPaddedBBox(turf.bbox(field.properties?.originalBoundary ?? field));
                      map?.fitBounds([newCenter[0], newCenter[1], newCenter[2], newCenter[3]], fitBoundsOption);
                    }}
                    saveField={updateField}
                    deleteField={setDeleteField}
                    changeFieldEnrollmentStatus={changeFieldEnrollmentStatus}
                    syncErrors={syncErrors}
                    userRegion={region}
                    country={country}
                    upsellingBoundaryTool={upsellingBoundaryTool}
                    hidden={fieldsHidden}
                    producerData={producerData}
                    practicesOnContract={practicesOnContract}
                  />
                ))}
              </Box>
            </Box>
          )}
        </Box>

        {region !== 'BR' && (
          <Box
            direction={'column'}
            pad={'12px 0'}
            margin={'0 0 2rem 0'}
            style={{ minHeight: 'unset', borderBottom: 'solid 1px #ccc', padding: '1rem' }}
          >
            <Box direction={'row'} gap={'8px'}>
              {fieldsHidden ? (
                <img
                  data-cy={`show-field-button`}
                  src={noEyeIcon}
                  height={24}
                  width={24}
                  alt='Show Fields Icon'
                  style={{
                    cursor: 'pointer',
                    alignSelf: 'center',
                  }}
                  onClick={(event) => {
                    setFieldsHidden(false);
                    toggleHideFields(false);
                  }}
                />
              ) : (
                <img
                  data-cy={`hide-field-button`}
                  src={eyeIcon}
                  height={24}
                  width={24}
                  alt='Hide Fields Icon'
                  style={{
                    cursor: 'pointer',
                    alignSelf: 'center',
                  }}
                  onClick={(event) => {
                    setFieldsHidden(true);
                    toggleHideFields(true);
                  }}
                />
              )}

              <Box
                width={'100%'}
                direction={'row'}
                justify={'between'}
                align={'center'}
                gap={'16px'}
                height={'30px'}
                onClick={() => setOpenUnenrolled(!openUnenrolled)}
              >
                <Text data-cy={`unenroll-collapsible-title`} textAlign={'start'} size={'20px'} style={{ fontWeight: 900, color: '#282828' }}>
                  {t('fieldFlow.mainWizard.Fields.unenrollCollapsibleTitle')} ({fieldsSorted.unenrolled.length})
                </Text>
                <Box direction={'row'} align={'center'}>
                  {openUnenrolled ? <FormUp height={'12px'} width={'12px'} /> : <FormDown height={'12px'} width={'12px'} />}
                </Box>
              </Box>
            </Box>

            {openUnenrolled && (
              <Box>
                <Box gap={'16px'}>
                  {fieldsSorted.unenrolled.map((field) => (
                    <UnenrolledFieldItem
                      key={field.id}
                      t={t}
                      field={field}
                      focusField={(field: any) => {
                        focusField(field);
                        const newCenter = getPaddedBBox(turf.bbox(field.properties?.originalBoundary ?? field));
                        map?.fitBounds([newCenter[0], newCenter[1], newCenter[2], newCenter[3]], fitBoundsOption);
                      }}
                      changeFieldEnrollmentStatus={changeFieldEnrollmentStatus}
                      country={country}
                      hidden={fieldsHidden}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box style={{ border: 'solid 1px #ccc', marginBottom: '1rem', marginTop: '1rem', borderRadius: '5px' }} height={'100%'}>
      {uploadingBoundaries && (
        <Box height={'100%'} align={'center'} justify={'center'}>
          <Spinner color={'#FFE137'} size={'medium'} />
        </Box>
      )}
      {!uploadingBoundaries && fields.length === 0 ? (
        <Text data-cy={'no-fields-text'} size={'16px'} color={'#232735'} textAlign={'center'} style={{ lineHeight: '24px' }}>
          {t('fieldFlow.mainWizard.Fields.notesPlaceholder')}
        </Text>
      ) : (
        <></>
      )}

      {(!!fieldsSorted.unenrolled.length || !!fieldsSorted.enrolled.length) && getFieldCollapsibles()}
    </Box>
  );
};
