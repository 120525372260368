import { Box, Text } from 'grommet';
import { useState } from 'react';
import { FormDown, FormUp } from 'grommet-icons';
import { useMapContext } from '../../../../service/map/MapContext';
import { PracticesIconsComponent } from '../../../Field/PracticesIconsComponent';
import { useTranslation } from 'react-i18next';
import { FieldThumbnail } from '../../../Field/FieldThumbnail';

interface FieldDropdownProps {
  field: any;
}

export const FieldDropdown = ({ field }: FieldDropdownProps) => {
  const { t } = useTranslation();

  const { country } = useMapContext();
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Box
      style={{
        minHeight: 'unset',
        borderRadius: '5px',
        border: '1px solid #CCC',
      }}
    >
      <Box onClick={() => setExpanded(!expanded)} pad={'0.75rem'}>
        <Box direction={'row'} justify={'between'}>
          <Box direction={'row'} align={'center'}>
            <FieldThumbnail feature={field} height={36} width={36} />
            <Text margin={'0 0.75rem'} weight={700} size={'1.5rem'}>
              {field.properties.name}
            </Text>
            <Text margin={'0 0.75rem'} weight={500} size={'16px'}>
              {field.properties[country === 'br' ? 'areaHectare' : 'area'].toFixed(2)} {t('fieldFlow.acreUnit')}
            </Text>
          </Box>
          {expanded ? (
            <Box justify={'center'}>
              <FormUp />
            </Box>
          ) : (
            <Box justify={'center'}>
              <FormDown />
            </Box>
          )}
        </Box>
      </Box>
      {expanded && (
        <Box direction={'row'} pad={'0 0.75rem 0.75rem 0.75rem'}>
          <Box gap={'8px'} width={'50%'}>
            <Box>
              <Text textAlign={'start'} weight={400} size={'16px'} color={'#282828'} style={{ lineHeight: '24px' }}>
                {t('fieldFlow.fieldItem.ownership.title')}
              </Text>

              <Text textAlign={'start'} weight={700} size={'16px'} color={'#282828'} style={{ lineHeight: '24px' }}>
                {t('fieldFlow.fieldItem.ownership.options.' + field.properties.situation.toLowerCase())}
              </Text>
            </Box>
            <Box>
              <Text textAlign={'start'} weight={400} size={'16px'} color={'#282828'} style={{ lineHeight: '24px' }}>
                {t('fieldFlow.fieldItem.irrigation.title')}
              </Text>

              <Text textAlign={'start'} weight={700} size={'16px'} color={'#282828'} style={{ lineHeight: '24px' }}>
                {t('fieldFlow.fieldItem.irrigation.options.' + (field.properties.irrigated ? 'irrigated' : 'nonIrrigated'))}
              </Text>
            </Box>
          </Box>
          <Box gap={'8px'} width={'50%'}>
            <Box>
              <Text textAlign={'start'} weight={400} size={'16px'} color={'#282828'} style={{ lineHeight: '24px' }}>
                {t(`fieldFlow.fieldItem.practiceType.title`)}
              </Text>

              <Text textAlign={'start'} weight={700} size={'16px'} color={'#282828'} style={{ lineHeight: '24px' }}>
                {t('fieldFlow.fieldItem.practiceType.options.' + (field.properties.practiceType === 'ROW_CROP' ? 'rowCrop' : 'pasture'))}
              </Text>
            </Box>
            <Box>
              <Text textAlign={'start'} weight={400} size={'16px'} color={'#282828'} style={{ lineHeight: '24px' }}>
                {t(`fieldFlow.ranchOverview.practicesSelected`)}
              </Text>

              <PracticesIconsComponent practices={field.properties?.practices} />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FieldDropdown;
