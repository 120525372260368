import { Box, Image, Layer, Spinner, Text } from 'grommet';
import herdIcon from '../../../../../assets/BoundaryTool/infoIcons/herd.svg';
import { Close } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import HerdVersionInfo from './components/HerdVersionInfo';
import './HerdHistoryModal.css';
import { useMapContext } from '../../../service/map/MapContext';
import { formatDate } from '../../../service/common/utils';
import { useTranslation } from 'react-i18next';

interface HerdHistoryModalProps {
  herd: any;
  onCancel: () => void;
}

export const HerdHistoryModal = ({ herd, onCancel }: HerdHistoryModalProps) => {
  const { t } = useTranslation();
  const { getHerdHistory } = useMapContext();
  const [herdVersions, setHerdVersions] = useState<any[]>([]);

  useEffect(() => {
    getHerdHistory(herd).then((herdVersions: any[]) => {
      setHerdVersions(herdVersions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [herd]);

  return (
    <Layer position='center' style={{ borderRadius: '12px' }}>
      <Box width='800px' style={{ maxHeight: '97vh' }} className={'hideScrollBox'} overflow={'auto'}>
        <Box background={'#FFF'} round={'5px'} style={{ minHeight: 'unset', position: 'fixed', zIndex: 99 }} width={'100%'}>
          <Box round={'5px'} direction={'row'} justify={'between'} pad={'12px 24px'} style={{ minHeight: 'unset' }}>
            <Box direction={'row'} gap={'0.75rem'} align={'center'}>
              <Image height={'36px'} width={'36px'} src={herdIcon} />
              <Text weight={900} margin={'-0.25rem 0 0 0'} size={'1.25rem'}>
                {herd.name}
              </Text>
            </Box>
            <Box justify={'center'}>
              <Close size={'20px'} onClick={() => onCancel()} style={{ cursor: 'pointer' }} />
            </Box>
          </Box>
          <Box height={'1px'} width={'100%'} border={{ color: 'rgba(73, 73, 73, 0.5)', size: '1px', side: 'top' }} />
        </Box>

        <Box margin={{ top: '60px', bottom: '16px' }} />
        <>
          {herdVersions.length ? (
            herdVersions.map((herdVersion: any, index: number) => {
              return (
                <HerdVersionInfo
                  key={herdVersion}
                  herdVersion={herdVersion}
                  versionEndDate={
                    index !== 0
                      ? formatDate(herdVersions[index - 1].editData.date)
                      : herdVersion.endDate
                      ? formatDate(herdVersion.endDate)
                      : t('fieldFlow.today')
                  }
                  lastVersion={index === herdVersions.length - 1}
                  opacity={index === 0 ? '1' : '0.7'}
                />
              );
            })
          ) : (
            <Box direction={'row'} gap={'8px'} width={'100%'} margin={{ bottom: '24px' }} align={'center'} justify={'center'}>
              <Spinner size={'medium'} />
            </Box>
          )}
        </>
      </Box>
    </Layer>
  );
};

export default HerdHistoryModal;
