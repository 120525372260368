import React, { useEffect, useState } from 'react';
import { Box, Button, Spinner, Text } from 'grommet';
import DrawingTools from './DrawingTools';
import { useMapContext } from '../../service/map/MapContext';
import { MAP_MODES } from './modes/modes';
import { useTranslation } from 'react-i18next';

const buttonStyle: React.CSSProperties = {
  paddingBottom: '0.5rem',
  paddingTop: '0.5rem',
  paddingRight: '0',
  paddingLeft: '0',
  width: '5rem',
  fontSize: '14px',
  border: 'none',
  borderRadius: '0px',
  color: '#191408',
};

const DrawingPanel = () => {
  const { t } = useTranslation();

  const {
    finishedDrawingField,
    finishedDrawingPaddock,
    addField,
    updateField,
    wizardStep,
    startSplittingField,
    restartDraw,
    fields,
    focusField,
    setMode,
    setDrawnPaddocksForField,
    uploadingDrawnField,
    setUploadingDrawnField,
  } = useMapContext();

  const [isEditingField, setEditingField] = useState<boolean>(false);

  useEffect(() => {
    setEditingField(!!fields.find((field) => field.properties?.['editing']));
  }, [fields]);

  function getButtonLabel() {
    if (uploadingDrawnField) {
      return (
        <Box align={'center'}>
          <Spinner color={wizardStep === 'FIELD' ? '#282828' : '#FFF'} size={'xsmall'} />
        </Box>
      );
    }
    if (wizardStep === 'FIELD') {
      return isEditingField ? t('fieldFlow.drawingPanel.update') : t('fieldFlow.drawingPanel.add');
    }
    return t('fieldFlow.drawingPanel.done');
  }

  return (
    <Box direction={'row'} background={'#fff'} pad={'0.6rem 1rem'} responsive={true} style={{ borderRadius: '5px' }}>
      <Box>
        <Text weight='bold' size='14px' alignSelf='start' color='#282828'>
          {t('fieldFlow.drawingPanel.title')}
        </Text>
        <Box direction={'row-responsive'} margin={'0.5rem 0 0 0'}>
          <Box alignSelf='start' style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <DrawingTools />
          </Box>
          <Box alignSelf='end' direction='row' gap='small'>
            <Button
              data-cy={'add-field-button'}
              label={getButtonLabel()}
              fill={true}
              disabled={wizardStep === 'FIELD' ? !finishedDrawingField : !finishedDrawingPaddock}
              style={{
                ...buttonStyle,
                background: wizardStep === 'FIELD' ? '#FFE137' : '#1B7C3D',
                color: wizardStep === 'FIELD' ? '#282828' : '#FFF',
              }}
              onClick={() => {
                setUploadingDrawnField(true);
                if (wizardStep === 'FIELD') {
                  if (isEditingField) {
                    updateField();
                  } else {
                    addField();
                  }
                } else {
                  setMode(MAP_MODES.STATIC);
                  restartDraw();
                  const field = fields.find((field) => field.properties && field.properties['editing']);
                  if (!field) {
                    setUploadingDrawnField(false);
                    return;
                  }
                  startSplittingField('');
                  setDrawnPaddocksForField(field);
                  focusField(field);
                }
              }}
            />
          </Box>
        </Box>
      </Box>
      {wizardStep !== 'FIELD' && (
        <Box pad={'0 1rem'}>
          <ul>
            <li>
              <Text weight='bold' size='14px' alignSelf='start' color='#282828'>
                {t('fieldFlow.drawingPanel.instructions.drawBoundary')}
              </Text>
            </li>
            <li>
              <Text weight='bold' size='14px' alignSelf='start' color='#282828'>
                {t('fieldFlow.drawingPanel.deletePoint')}
              </Text>
            </li>
            <li>
              <Text weight='bold' size='14px' alignSelf='start' color='#282828'>
                {t('fieldFlow.drawingPanel.instructions.endLine')}
              </Text>
            </li>
          </ul>
        </Box>
      )}
    </Box>
  );
};

export default DrawingPanel;
