import { Box, Text, TextInput as TextInputGrommet } from 'grommet';

interface TextInputInterface {
  label: string;
  width?: string;
  value: string;
  titleWeight?: number;
  onChange: (value: string) => void;
  type?: string;
  min?: number;
  max?: number;
  suggestions?: string[];
  disabled?: boolean;
  error?: boolean;
  onlyPositive?: boolean;
  onlyIntegers?: boolean;
  onlyChars?: boolean;
}

export const TextInput = ({
  label,
  width,
  value,
  titleWeight,
  onChange,
  type,
  min,
  max,
  suggestions,
  disabled,
  error,
  onlyPositive,
  onlyIntegers,
  onlyChars,
}: TextInputInterface) => {
  return (
    <Box width={width ?? '24.2%'} height={'44px'} direction={'column'}>
      <Text size={'16px'} style={{ lineHeight: '20px', whiteSpace: 'nowrap' }} color={'#282828'} weight={titleWeight ?? 700}>
        {label}
      </Text>
      {disabled ? (
        <Text size={'16px'} style={{ lineHeight: '16px', whiteSpace: 'nowrap' }} color={'#282828'} weight={400}>
          {value && value.length ? value : '--'}
        </Text>
      ) : (
        <TextInputGrommet
          plain
          type={type}
          focusIndicator={false}
          size={'16px'}
          min={min}
          max={max}
          height={'24px'}
          suggestions={suggestions?.sort().filter((suggestion) => suggestion.toLowerCase().indexOf(value.toLowerCase()) !== -1)}
          onSuggestionSelect={(event) => onChange(event.suggestion)}
          style={{
            lineHeight: '16px',
            padding: '4px',
            border: error ? '1px solid red' : '1px solid #808080',
            borderRadius: '0',
            fontWeight: '400',
          }}
          value={value}
          onKeyDown={(event: any) => {
            if (type === 'number' && ((onlyIntegers && event.key === '.') || (typeof min === 'number' && min >= 0 && event.key === '-'))) {
              return event.preventDefault();
            }
          }}
          onChange={(event) => {
            let value = event.target.value;
            if (type === 'text' && onlyChars) value = value.replace(/[0-9]/g, '');
            onChange(value);
          }}
          onBlur={(event) => {
            if (type === 'number' && onlyPositive && Number(event.target.value) < 1) onChange('1');
          }}
        />
      )}
    </Box>
  );
};
