import React, { useEffect, useState } from 'react';
import './App.css';
import './css/buttons.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAppInitializedAction } from './redux/actions/context';
import RoutingProvider from './providers/RoutingProvider';
import { AgoroHeader } from './components/agoro-header/AgoroHeader.component';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid } from 'grommet';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { hotjar } from 'react-hotjar';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import { User } from './redux/reducers/user-reducer';
import useCognito from './components/Cognito/UseCognito';

const App = () => {
  const { pathname } = useLocation();
  const [isLaunchDarklyInitialized, setIsLaunchDarklyInitialized] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ldClient = useLDClient();
  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID ? Number(process.env.REACT_APP_HOTJAR_ID) : 3288859, 6);
  const { getTokensOrLogin, refreshSessionSilently, user, isAuthenticated, isLoading } = useCognito();

  useEffect(() => {
    if (isLoading) return;
    if (!isAuthenticated) {
      getTokensOrLogin();
    }
    dispatch(getAppInitializedAction(getTokensOrLogin, refreshSessionSilently, user));
  }, [user, dispatch, getTokensOrLogin, refreshSessionSilently, isAuthenticated, isLoading]);

  const isInitialized = useSelector((state: any) => {
    return state.context.isInitialized;
  });
  const userData: User = useSelector((state: any) => {
    return state.user.userData;
  });
  const userLanguage = userData?.language;

  const removeLoaderContainer = () => {
    document.getElementById('loader-container')?.remove();
  };

  if (userLanguage) {
    if (!i18n.language || i18n.language !== userLanguage) {
      i18n.changeLanguage(userLanguage).then(() => {
        setTimeout(() => {
          removeLoaderContainer();
        }, 700);
      });
    } else {
      removeLoaderContainer();
    }
  }

  useEffect(() => {
    if (ldClient && userData) {
      ldClient
        .identify({
          kind: 'user',
          key: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          name: userData.fullName,
          email: userData.email,
          country: userData.country,
          roles: userData.roles,
          partnerTier: userData.partnerTier,
        })
        .then((_) => {
          setIsLaunchDarklyInitialized(true);
        });
    }
    if (hotjar.initialized() && userData) {
      hotjar.identify(userData.id, { userProperty: { roles: userData.roles } });
    }
  }, [ldClient, userData]);

  useEffect(() => {
    if (hotjar.initialized()) {
      hotjar.stateChange(pathname);
    }
  }, [pathname]);

  const goBack = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return isLaunchDarklyInitialized && isInitialized && userData ? (
    <Grid
      height={{ min: '100vh' }}
      className='App'
      fill
      rows={['auto', 'flex']}
      columns={['auto', 'flex']}
      areas={[
        { name: 'header', start: [0, 0], end: [1, 0] },
        { name: 'main', start: [0, 1], end: [1, 1] },
      ]}
    >
      <Box gridArea='header' align='center' justify='center'>
        <AgoroHeader backButtonCallback={goBack} backButtonTitle='Back To Overview' backNavigate={!matchPath(pathname, '/')} />
      </Box>
      <Box gridArea='main'>
        <I18nextProvider i18n={i18n}>
          <RoutingProvider />
        </I18nextProvider>
      </Box>
    </Grid>
  ) : null;
};

export default App;
