import { Box, Text } from 'grommet';
import structureMarker from '../../../../assets/BoundaryTool/infoIcons/waterSource.svg';
import { TextInput as TextInputGroomet } from 'grommet/components/TextInput';
import deleteIcon from '../../../../assets/BoundaryTool/deleteIcon.svg';
import infoIcon from '../../../../assets/BoundaryTool/info-icon.svg';
import { DateInput } from '../Input/DateInput';
import React from 'react';
import { Feature } from '@turf/helpers';
import { useMapContext } from '../../service/map/MapContext';
import { LocationInput } from '../Input/LocationInput';
import { TextInput } from '../Input/TextInput';
import { Trans, useTranslation } from 'react-i18next';

export enum StructureType {
  WATER_SOURCE = 'WATER_SOURCE',
}

export enum StructureSubType {
  POND = 'POND',
  TANK = 'TANK',
}

interface StructureItemParams {
  structure: any;
  updateStructure: (structure: any) => void;
  removeStructure: (structure: any) => void;
  fields: Feature[];
  paddocks: { [key: string]: Feature[] };
  simple?: boolean;
}

export const StructureItem = ({ structure, updateStructure, removeStructure, fields, paddocks, simple }: StructureItemParams) => {
  const { t } = useTranslation();
  const { currentVersion } = useMapContext();

  const structureSubType = {
    [StructureSubType.TANK]: t('fieldFlow.waterItem.tank'),
    [StructureSubType.POND]: t('fieldFlow.waterItem.pond'),
  };

  function getMinDateIn(): Date {
    return new Date(Math.max(...[currentVersion.startDate, structure.startDate].filter((date) => !!date).map((date) => new Date(date!).getTime())));
  }

  function getMaxDateIn(): Date {
    return new Date(
      Math.min(...[currentVersion.endDate, structure.endDate, new Date()].filter((date) => !!date).map((date) => new Date(date!).getTime())),
    );
  }

  return (
    <Box
      key={structure.id.toString()}
      height={{ min: !simple ? '360px' : '180px' }}
      border={!simple ? { side: 'bottom', size: '1px', color: '#494949' } : undefined}
    >
      <Box direction={'row'} align={'center'}>
        <img src={structureMarker} height={36} width={36} alt='Active indicator' />

        <Box width={'144px'} margin={{ left: '8px', right: '11px' }}>
          <TextInputGroomet
            plain
            focusIndicator={false}
            placeholder={'Source name'}
            value={structure.name}
            style={{ height: '36px', border: '1px solid #282828' }}
            onChange={(e) => {
              structure.name = e.target.value;
              updateStructure(structure);
            }}
          />
        </Box>

        <img
          src={deleteIcon}
          height={24}
          width={24}
          alt='Delete Icon'
          style={{
            cursor: 'pointer',
          }}
          onClick={(event) => {
            event.stopPropagation();
            removeStructure(structure);
          }}
        />
      </Box>

      <Text size={'16px'} style={{ lineHeight: '20px' }} color={'#1457A8'} weight={700} margin={{ top: !simple ? '16px' : '0' }}>
        {t('fieldFlow.waterItem.title')}
      </Text>

      <Box direction={'row'} margin={{ top: '16px' }} gap={'3.2%'}>
        <TextInput
          label={t('fieldFlow.waterItem.structureType')}
          width={'31%'}
          value={structure.subtype}
          suggestions={Object.values(structureSubType)}
          onChange={(subType) => {
            structure.subtype = subType;
            updateStructure(structure);
          }}
        />
      </Box>

      <Text size={'16px'} style={{ lineHeight: '20px' }} color={'#1457A8'} weight={700} margin={{ top: '24px' }}>
        {t('fieldFlow.herdItem.locationInfo.title')}
      </Text>

      <Box direction={'row'} margin={{ top: '8px' }} gap={'3.2%'}>
        <DateInput
          label={t('fieldFlow.herdItem.locationInfo.dateIn')}
          width={'33%'}
          value={structure.dateIn}
          min={getMinDateIn()}
          max={getMaxDateIn()}
          onChange={(dateIn) => {
            structure.dateIn = dateIn;
            if (!!dateIn && dateIn < new Date(getMinDateIn())) structure.dateIn = getMinDateIn();
            if (!!dateIn && dateIn > new Date(getMaxDateIn())) structure.dateIn = getMaxDateIn();
            updateStructure(structure);
          }}
        />

        <LocationInput
          label={t('fieldFlow.field')}
          width={'33%'}
          value={fields.find((field) => field.id === structure.fieldId)}
          options={fields}
          labelKey={(field) => field.properties.name}
          onChange={(field) => {
            structure.paddockId = undefined;
            structure.fieldId = field.id;
            if (paddocks[field.id].length === 1) {
              structure.latitude = undefined;
              structure.longitude = undefined;
              structure.paddockId = paddocks[field.id][0].id?.toString();
            }
            updateStructure(structure);
          }}
        />

        {structure.fieldId && paddocks[structure.fieldId].length > 1 && (
          <LocationInput
            label={t('fieldFlow.herdItem.locationInfo.paddock')}
            width={'33%'}
            value={paddocks[structure.fieldId]?.find((paddock) => paddock.id === structure.paddockId)}
            options={paddocks[structure.fieldId!]}
            labelKey={(paddock: any) => paddock.properties.name}
            onChange={(paddock: any) => {
              structure.paddockId = paddock.id;
              structure.latitude = undefined;
              structure.longitude = undefined;
              updateStructure(structure);
            }}
          />
        )}

        <Box width={'24.2%'} />
      </Box>

      {/*todo change this Version 1 text to the layout name*/}
      {!simple && (
        <Text size={'16px'} style={{ lineHeight: '20px' }} color={'#282828'} weight={400} margin={{ top: '16px' }}>
          <Trans i18nKey={'fieldFlow.waterItem.selectMessage'} />
        </Text>
      )}

      {!simple && (
        <Box direction={'row'} margin={{ top: '16px' }}>
          <img src={infoIcon} height={24} width={24} alt='info Icon' />

          <Text size={'16px'} style={{ lineHeight: '20px' }} color={'#282828'} weight={400} margin={{ left: '12px' }}>
            {t('fieldFlow.waterItem.dragMessage')}
          </Text>
        </Box>
      )}
    </Box>
  );
};
