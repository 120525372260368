import { API } from '../common/api';
import { BASE_URL } from '../common/env';

const getProducerList = async () => {
  return API.get(`${BASE_URL}/base/growers/producers`).then((res) => {
    return res.data;
  });
};

const getCycleStatuses = () => {
  return API.get(`${BASE_URL}/soil-sampling-service/campaigns/cycle?searchColumn=salesforceId`).then((res) => {
    return res.data;
  });
};

const getProducerById = async (growerId: string) => {
  return API.get(`${BASE_URL}/base/growers/leads/${growerId}`).then((res) => {
    return res.data;
  });
};

const getJourneyById = async (journeyId: string) => {
  return API.get(`${BASE_URL}/base/producer/journey/${journeyId}`).then((res) => {
    return res.data;
  });
};

const createJourney = async (leadId?: string, opportunityId?: string) => {
  return API.post(`${BASE_URL}/base/producer`, { leadId, opportunityId }).then((res) => {
    return {
      ...res.data,
      id: res.data.journeyId,
    };
  });
};

export const ProducerService = {
  getProducerList,
  getCycleStatuses,
  getProducerById,
  getJourneyById,
  createJourney,
};
