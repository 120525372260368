import * as React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastBody } from './ToastBody';
import { CloseButton } from './ToastClose';
import { options } from './toastOptions';

export type ToastMessageType = {
  title?: string;
  message: string;
  type: 'success' | 'warning' | 'error' | 'info';
  toastId: string;
};

const messageType = {
  success: '#07bc0c',
  warning: '#f1c40f',
  error: '#ff4a6b',
  info: '#3498db',
};

const AgoToast = {
  showToast(props: ToastMessageType): React.ReactText {
    const { title, message, type, toastId } = props;
    return toast(
      ToastBody({
        title,
        message,
      }),
      {
        ...options,
        toastId,
        style: {
          borderLeft: `5px solid ${type && messageType[type]}`,
          whiteSpace: 'pre-line',
        },
        // @ts-ignore
        closeButton: CloseButton,
      },
    );
  },
};

export default AgoToast;
