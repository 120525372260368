import * as turf from '@turf/turf';
import { NavigateFunction } from 'react-router-dom';
import AgoToast from '../../../../components/Toast/AgoToast';

export function formatDate(date: Date | string) {
  if (!date) return '';
  const formater = Intl.DateTimeFormat(undefined, { timeZone: 'UTC', month: '2-digit', day: '2-digit', year: 'numeric' });
  return formater.format(new Date(date));
}

export function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function getToday() {
  return new Date();
}

export function dateInRange(date: Date, startDate: Date, endDate?: Date) {
  date = new Date(date);
  startDate = new Date(startDate);
  endDate = endDate && new Date(endDate);
  return date >= startDate && endDate && date <= endDate;
}

export function navigateBackToProfile(journeyId: string, navigate: NavigateFunction) {
  navigate(`/leads/${journeyId}?activeTab=fields`);
}

export function showToast(title: string, message: string, type: any, toastId: string) {
  //todo: change this on refactor
  AgoToast.showToast({ title, message, type, toastId });
}

export function getPaddedBBox(bbox: turf.helpers.BBox) {
  const bboxPolygon = turf.bboxPolygon(bbox);
  const paddedPolygon = turf.transformScale(bboxPolygon, 2);
  return turf.bbox(paddedPolygon);
}

export function getRandomNumber(): number {
  const array = new Uint32Array(1);
  window?.crypto?.getRandomValues(array);
  return array[0];
}
