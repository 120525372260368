// @ts-ignore
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
// @ts-ignore
import DrawPolygon from '@mapbox/mapbox-gl-draw/src/modes/draw_polygon';
// @ts-ignore
import { isVertex } from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
// @ts-ignore
import createVertex from '@mapbox/mapbox-gl-draw/src/lib/create_vertex';

import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { MAP_MODES } from './modes';
import {
  activeStates,
  geojsonTypes,
  meta,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@mapbox/mapbox-gl-draw/src/constants';
import difference from '@turf/difference';

const FieldInnerBorderMode = { ...DrawPolygon };

FieldInnerBorderMode.onSetup = function (opts: any) {
  const featureId = opts.featureId;
  const feature = this.getFeature(featureId);

  if (!feature) {
    throw new Error('You must provide a featureId to enter inner_border mode');
  }

  const polygon = this.newFeature({
    type: geojsonTypes.FEATURE,
    properties: {},
    geometry: {
      type: geojsonTypes.POLYGON,
      coordinates: [[]],
    },
  });

  this.addFeature(polygon);

  doubleClickZoom.disable(this);

  const state = {
    featureId,
    feature,
    dragMoveLocation: opts.startPos || null,
    dragMoving: false,
    canDragMove: false,
    selectedCoordPaths: opts.coordPath ? [opts.coordPath] : [],
    polygon,
    currentVertexPosition: 0,
  };

  this.setSelectedCoordinates(this.pathsToCoordinates(featureId, state.selectedCoordPaths));
  this.setSelected(featureId);

  return state;
};

FieldInnerBorderMode.onStop = function (state: any) {
  doubleClickZoom.enable(this);

  // check to see if we've deleted this feature
  if (this.getFeature(state.polygon.id) === undefined) {
    return;
  }

  //remove last added coordinate
  state.polygon.removeCoordinate(`0.${state.currentVertexPosition}`);

  if (state.polygon.isValid()) {
    const features = this.getSelected().map((f: any) => f.toGeoJSON());
    const innerFeature = state.polygon.toGeoJSON();
    const originalFeature = features[0];
    const diffFeature = difference(originalFeature, innerFeature);
    diffFeature!.id = originalFeature.id;
    this.clearSelectedFeatures();
    this.deleteFeature(originalFeature.id, { silent: true });
    this.deleteFeature(state.polygon.id, { silent: true });
    this.addFeature(this.newFeature(diffFeature));
    this.select(diffFeature!.id);
  } else {
    this.deleteFeature([state.polygon.id], { silent: true });
    this.changeMode(MAP_MODES.OUTER_BORDER, { featureId: state.feature.id });
  }
};

FieldInnerBorderMode.pathsToCoordinates = function (featureId: number, paths: any) {
  return paths.map((coordPath: any) => ({
    feature_id: featureId,
    coord_path: coordPath,
  }));
};

FieldInnerBorderMode.clickOnVertex = function (state: any) {
  if (state.polygon.coordinates[0].length < 4) {
    return;
  }
  return this.changeMode(MAP_MODES.OUTER_BORDER, {
    featureId: state.feature.id,
  });
};

FieldInnerBorderMode.onClickSuper = FieldInnerBorderMode.onClick;
FieldInnerBorderMode.onClick = function (state: any, e: any) {
  // only allow clicks inside the original polygon
  if (this.isMouseInsideOriginalFeature(state, e)) {
    this.onClickSuper(state, e);
  }
};

FieldInnerBorderMode.clickAnywhereSuper = FieldInnerBorderMode.clickAnywhere;
FieldInnerBorderMode.clickAnywhere = function (state: any, e: any) {
  // don't allow clicks when inner polygon is invalid
  if (state.isInnerPolygonValid === false) {
    return;
  }

  this.clickAnywhereSuper(state, e);

  if (state.currentVertexPosition === 2) {
    // Trigger re-render of the outer polygon layer so it can hide itself
    const outerPolygon = state.feature;
    this.doRender(outerPolygon.id);
  }
};

FieldInnerBorderMode.displayInnerBoundery = function (state: any, geojson: any, display: any) {
  // Don't render a polygon until it has two positions
  // (and a 3rd which is just the first repeated)
  if (geojson.geometry.coordinates.length === 0) {
    return;
  }
  if (state.currentVertexPosition === 0) {
    return;
  }

  // If the mouse is outside the original polygon, just show the last valid polygon
  if (state.isMouseInsideOriginalFeature === false) {
    display(state.lastDisplayedFeature);
    return;
  }

  const coordinateCount = geojson.geometry.coordinates[0].length;
  // 2 coordinates after selecting a draw type
  // 3 after creating the first point
  geojson.properties.active = activeStates.ACTIVE;
  geojson.properties.meta = meta.FEATURE;

  if (coordinateCount === 3) {
    // If we've only drawn two positions (plus the closer),
    // make a LineString instead of a Polygon
    const lineCoordinates = [
      [geojson.geometry.coordinates[0][0][0], geojson.geometry.coordinates[0][0][1]],
      [geojson.geometry.coordinates[0][1][0], geojson.geometry.coordinates[0][1][1]],
    ];

    const lineFeature = {
      type: geojsonTypes.FEATURE,
      properties: geojson.properties,
      geometry: {
        coordinates: lineCoordinates,
        type: geojsonTypes.LINE_STRING,
      },
    };
    display(lineFeature);
    state.lastDisplayedFeature = lineFeature;
    return;
  }

  // Calculate and display difference between original polygon and draw in inner polygon
  try {
    const originalFeature = state.feature.toGeoJSON();
    const diffFeature = difference(originalFeature, geojson);
    diffFeature!.id = originalFeature.id;
    diffFeature!.properties = {
      ...geojson.properties,
      active: activeStates.ACTIVE,
    };
    state.isInnerPolygonValid = true;
    display(diffFeature);
    state.lastDisplayedFeature = diffFeature;
  } catch (error: any) {
    display(state.lastDisplayedFeature);
    // Ignore TopologyExceptions which means that the 2 polygons cannot be diffed,
    // because of the new polygon shape. The previously valid diffed polygon will be displayed.
    if (error && error.name === 'TopologyException') {
      state.isInnerPolygonValid = false;
    } else {
      throw error;
    }
  }
};

FieldInnerBorderMode.displayOuterBoundery = function (state: any, geojson: any, display: any) {
  if (state.currentVertexPosition < 2) {
    geojson.properties.active = activeStates.ACTIVE;
    return display(geojson);
  }
};

FieldInnerBorderMode.toDisplayFeatures = function (state: any, geojson: any, display: any) {
  const isGeojsonTheInnerPolygon = geojson.properties.id === state.polygon.id;

  if (isGeojsonTheInnerPolygon) {
    if (state.currentVertexPosition > 2) {
      display(createVertex(state.featureId, geojson.geometry.coordinates[0][0], '0.0'));
    }

    return this.displayInnerBoundery(state, geojson, display);
  }
  this.displayOuterBoundery(state, geojson, display);
};

FieldInnerBorderMode.onMouseMoveSuper = FieldInnerBorderMode.onMouseMove;
FieldInnerBorderMode.onMouseMove = function (state: any, e: any) {
  this.onMouseMoveSuper(state, e);

  // Calculate if mouse is inside original feature.
  // This is used to change the cursor, stop changing the inner border and
  // to prevent clicks outside the boundaries of the field.
  state.isMouseInsideOriginalFeature = this.isMouseInsideOriginalFeature(state, e);

  // Never show disallowed cursor when hovering vertices
  // since we remove the last vertex before calculating the new field
  // and the vertices have a large clicking area
  if (isVertex(e)) {
    return;
  }

  // Show a disallowed cursor if we cannot calculate the diff between the two poligons,
  // or if the mouse is outside the original polygon.
  if (state.isInnerPolygonValid === false) {
    this.updateUIClasses({ mouse: 'not-allowed' });
  }
};

FieldInnerBorderMode.isMouseInsideOriginalFeature = function (state: any, e: any) {
  const point = [e.lngLat.lng, e.lngLat.lat];
  const polygon = state.feature;
  if (polygon.features) {
    return !!polygon.features.find((feature: any) => booleanPointInPolygon(point, feature));
  }
  return booleanPointInPolygon(point, polygon);
};

export default FieldInnerBorderMode;
