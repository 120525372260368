//@ts-ignore
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
//@ts-ignore
import isEventAtCoordinates from '@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates';
//@ts-ignore
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
//@ts-ignore
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
//@ts-ignore
import createVertex from '@mapbox/mapbox-gl-draw/src/lib/create_vertex';
//@ts-ignore
import DrawLineString from '@mapbox/mapbox-gl-draw/src/modes/draw_line_string';
import { MAP_MODES } from './modes';

DrawLineString.onSetup = function (opts: any) {
  opts = opts || {};
  const featureId = opts.featureId;

  let line, currentVertexPosition;
  let direction = 'forward';
  if (featureId) {
    line = this.getFeature(featureId);
    if (!line) {
      throw new Error('Could not find a feature with the provided featureId');
    }
    let from = opts.from;
    if (from && from.type === 'Feature' && from.geometry && from.geometry.type === 'Point') {
      from = from.geometry;
    }
    if (from && from.type === 'Point' && from.coordinates && from.coordinates.length === 2) {
      from = from.coordinates;
    }
    if (!from || !Array.isArray(from)) {
      throw new Error('Please use the `from` property to indicate which point to continue the line from');
    }
    const lastCoord = line.coordinates.length - 1;
    if (line.coordinates[lastCoord][0] === from[0] && line.coordinates[lastCoord][1] === from[1]) {
      currentVertexPosition = lastCoord + 1;
      // add one new coordinate to continue from
      line.addCoordinate(currentVertexPosition, ...line.coordinates[lastCoord]);
    } else if (line.coordinates[0][0] === from[0] && line.coordinates[0][1] === from[1]) {
      direction = 'backwards';
      currentVertexPosition = 0;
      // add one new coordinate to continue from
      line.addCoordinate(currentVertexPosition, ...line.coordinates[0]);
    } else {
      throw new Error('`from` should match the point at either the start or the end of the provided LineString');
    }
  } else {
    line = this.newFeature({
      type: Constants.geojsonTypes.FEATURE,
      properties: {
        meta: 'cuttingLine',
      },
      geometry: {
        type: Constants.geojsonTypes.LINE_STRING,
        coordinates: [],
      },
    });
    currentVertexPosition = 0;
    this.addFeature(line);
  }

  this.clearSelectedFeatures();
  doubleClickZoom.disable(this);
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this.activateUIButton(Constants.types.LINE);
  this.setActionableState({
    trash: true,
  });

  return {
    line,
    currentVertexPosition,
    direction,
  };
};

DrawLineString.clickAnywhere = function (state: any, e: any) {
  if (e.originalEvent.button === 2) {
    return;
  }
  if (
    (state.currentVertexPosition > 1 && isEventAtCoordinates(e, state.line.coordinates[state.currentVertexPosition - 1])) ||
    (state.direction === 'backwards' && isEventAtCoordinates(e, state.line.coordinates[state.currentVertexPosition + 1]))
  ) {
    return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.line.id] });
  }
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
  if (state.direction === 'forward') {
    state.currentVertexPosition++;
    state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
  } else {
    state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
  }
};

DrawLineString.clickOnVertex = function (state: any, event: any) {
  return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.line.id] });
};

DrawLineString.onMouseMove = function (state: any, e: any) {
  state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
  if (CommonSelectors.isVertex(e)) {
    this.updateUIClasses({ mouse: Constants.cursors.POINTER });
  }
};

DrawLineString.onTap = DrawLineString.onClick = function (state: any, event: any) {
  if (event.originalEvent.button === 2) {
    return this.onRightCLick(state, event);
  }
  if (CommonSelectors.isVertex(event)) return this.clickOnVertex(state, event);
  this.clickAnywhere(state, event);
};

DrawLineString.onKeyUp = function (state: any, e: any) {
  if (CommonSelectors.isEnterKey(e)) {
    this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.line.id] });
  } else if (CommonSelectors.isEscapeKey(e)) {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT);
  }
};

DrawLineString.onRightCLick = function (state: any, event: any) {
  if (state.currentVertexPosition) {
    state.line.removeCoordinate(state.currentVertexPosition);
    state.currentVertexPosition--;
    state.line.updateCoordinate(state.currentVertexPosition, event.lngLat.lng, event.lngLat.lat);
    this.updateUIClasses({ mouse: Constants.cursors.ADD });
    if (state.currentVertexPosition === 0) {
      this.map.fire(Constants.events.MODE_CHANGE, { mode: MAP_MODES.SPLIT_FIELD });
    }
  }
};

DrawLineString.onStop = function (state: any) {
  doubleClickZoom.enable(this);
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.line.id) === undefined) return;

  //remove last added coordinate
  state.line.removeCoordinate(`${state.currentVertexPosition}`);
  if (state.line.isValid()) {
    this.map.fire(Constants.events.CREATE, {
      features: [state.line.toGeoJSON()],
    });
  } else {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(Constants.modes.STATIC, {}, { silent: true });
  }
};

DrawLineString.onTrash = function (state: any) {
  this.deleteFeature([state.line.id], { silent: true });
  this.changeMode(Constants.modes.SIMPLE_SELECT);
};

DrawLineString.toDisplayFeatures = function (state: any, geojson: any, display: any) {
  const isActiveLine = geojson.properties.id === state.line.id;
  geojson.properties.active = isActiveLine ? Constants.activeStates.ACTIVE : Constants.activeStates.INACTIVE;
  if (!isActiveLine) return display(geojson);
  // Only render the line if it has at least one real coordinate
  if (geojson.geometry.coordinates.length < 2) return;
  geojson.properties.meta = 'cutting';
  const vertex = createVertex(
    state.line.id,
    geojson.geometry.coordinates[state.direction === 'forward' ? geojson.geometry.coordinates.length - 2 : 1],
    `${state.direction === 'forward' ? geojson.geometry.coordinates.length - 2 : 1}`,
    false,
  );
  vertex.properties.meta = 'finish';
  display(vertex);
  display(geojson);
};

export default DrawLineString;
