import { Box, Select, Text, Button } from 'grommet';
import React, { useState } from 'react';
import FieldDetails from './components/FieldDetails';
import { useSearchParams } from 'react-router-dom';
import RanchOverview from './components/RanchOverview';
import { FieldLayout } from '../../types/FieldLayout';
import { formatDate } from '../../service/common/utils';
import { useMapContext } from '../../service/map/MapContext';
import OffsiteDetails from '../Offsite/OffsiteDetails';
import EndCurrentVersionModal from '../Modals/EndCurrentVersionModal';
import { useTranslation } from 'react-i18next';
import RanchOverviewV2 from './components/RanchOverviewV2';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const InformationPanel = (): JSX.Element => {
  const { t } = useTranslation();
  const { hideGrazing } = useFlags();
  const { state, setState, currentVersion, setCurrentVersion, layouts, fields } = useMapContext();
  const [searchParams] = useSearchParams();
  const fieldId = searchParams.get('fieldId');
  const [showEndCurrentVersionModal, setShowEndCurrentVersionModal] = useState<boolean>(false);
  const formatVersion = (version: FieldLayout) => {
    return `${version.name}, ${formatDate(version.startDate)}, ${
      version.endDate ? formatDate(version.endDate) : t('fieldFlow.dateRangeModal.current')
    }`;
  };

  const isChannelPartner = useSelector((state: any) => {
    return !!state.user.userData.channelPartnerId;
  });

  const getPanelInfo = () => {
    if (state === 'OVERVIEW') {
      return <RanchOverview />;
    } else if (state === 'OVERVIEW_V2') {
      return <RanchOverviewV2 />;
    } else if (fieldId === 'OFFSITE') {
      return <OffsiteDetails />;
    } else {
      return <FieldDetails />;
    }
  };

  return (
    <>
      {layouts && (
        <Box>
          {showEndCurrentVersionModal && (
            <EndCurrentVersionModal
              closeDateRangeModal={() => {
                setShowEndCurrentVersionModal(false);
              }}
            />
          )}
          {layouts.length && !hideGrazing && state !== 'OVERVIEW_V2' && !isChannelPartner && (
            <Box
              direction={'row'}
              width={'300px'}
              round={'5px'}
              pad={'0.75rem 0.75rem'}
              border={{ color: '#282828', style: 'solid', size: '1px' }}
              style={{
                position: 'absolute',
                zIndex: 11,
                top: 84,
                left: 24,
                background: '#ffffff',
              }}
            >
              <Box direction={'column'} gap={'0.25rem'}>
                <Box direction={'row'} justify={'between'}>
                  <Text weight={900}>{t('fieldFlow.dateRangeList.title')}</Text>
                  {!!currentVersion && !currentVersion.endDate && (
                    <Button
                      focusIndicator={false}
                      hoverIndicator={false}
                      style={{
                        borderRadius: '0px',
                        fontWeight: '900',
                        fontSize: '10px',
                        lineHeight: '12px',
                        textAlign: 'center',
                        width: '106px',
                        height: '24px',
                        padding: '6px 8px',
                        backgroundColor: '#FFE137',
                        justifyContent: 'center',
                      }}
                      onClick={() => {
                        setShowEndCurrentVersionModal(true);
                      }}
                      label={t('fieldFlow.dateRangeList.endVersion')}
                      plain
                    />
                  )}
                </Box>
                <Box round={'5px'}>
                  <Select
                    plain
                    focusIndicator={false}
                    size={'16px'}
                    height={'24px'}
                    style={{
                      lineHeight: '16px',
                      fontSize: '12px',
                      textAlign: 'center',
                      width: '240px',
                      padding: '4px',
                      borderRadius: '0',
                      fontWeight: '700',
                    }}
                    options={layouts}
                    value={currentVersion ?? layouts[0]}
                    valueKey={formatVersion}
                    labelKey={formatVersion}
                    onChange={(event) => {
                      setCurrentVersion(layouts[event.selected]);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
          {fields.length > 0 && !hideGrazing && state !== 'OVERVIEW_V2' && !isChannelPartner && (
            <Box
              direction={'row'}
              width={'185px'}
              round={'5px'}
              pad={'0.75rem 1rem'}
              border={{ color: '#282828', style: 'solid', size: '1px' }}
              style={{
                position: 'absolute',
                zIndex: 11,
                bottom: '32px',
                left: '24px',
                background: '#ffffff',
              }}
            >
              <Button
                focusIndicator={false}
                hoverIndicator={false}
                style={{
                  borderRadius: '0px',
                  fontWeight: '800',
                  fontSize: '0.75rem',
                  textAlign: 'center',
                  width: '100%',
                  padding: '8px 0',
                  backgroundColor: '#FFE137',
                  justifyContent: 'center',
                }}
                onClick={() => setState('SECOND_SETUP')}
                label={t('fieldFlow.infoPanel.newLayout')}
                plain
              />
            </Box>
          )}
          <Box
            width={'40%'}
            background={'white'}
            style={{
              position: 'absolute',
              right: '24px',
              top: '24px',
              bottom: '24px',
              zIndex: 1,
              borderRadius: '5px',
              padding: state === 'OVERVIEW_V2' ? '16px' : undefined,
            }}
          >
            {getPanelInfo()}
          </Box>
        </Box>
      )}
    </>
  );
};

export default InformationPanel;
