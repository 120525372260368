import { Box, Button, Image, Text } from 'grommet';
import React, { useEffect, useRef, useState } from 'react';
import { FormDown, FormUp } from 'grommet-icons';
import LevelTag from '../../../Wizard/components/LevelTag';
import waterSource from '../../../../../../assets/BoundaryTool/infoIcons/waterSource.svg';
import { Feature } from '@turf/helpers';
import { StructureItem } from '../../../Structure/StructureItem';
import { DetailsInfo } from '../DetailsInfo';
import { useSearchParams } from 'react-router-dom';
import { formatDate } from '../../../../service/common/utils';
import { useTranslation } from 'react-i18next';

interface WaterSourceDropdownParams {
  structures: any[];
  field: Feature;
  paddocks: Feature[];
  addNewStructure: () => void;
  updateStructure: (structure: any) => void;
  saveStructure: (structure: any) => Promise<void>;
  removeStructure: (structure: any) => void;
}

export const WaterSourceDropdown = ({
  structures,
  field,
  paddocks,
  addNewStructure,
  updateStructure,
  saveStructure,
  removeStructure,
}: WaterSourceDropdownParams): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [highlight, setHighlight] = useState<string | undefined>();
  const [savingStructure, setSavingStructure] = useState<boolean>(false);
  const elementsRef = useRef<any>({});

  useEffect(() => {
    const type = searchParams.get('type');
    const elementId = searchParams.get('elementId');

    if (type && type === 'STRUCTURE' && elementId) {
      setExpanded(true);
      setHighlight(elementId);
    } else {
      setHighlight(undefined);
    }
  }, [searchParams]);

  useEffect(() => {
    if (highlight && elementsRef.current[highlight]) {
      elementsRef.current[highlight].scrollIntoView({ behavior: 'smooth' });
    }
  }, [highlight, elementsRef]);

  return (
    <Box style={{ minHeight: 'unset' }}>
      <Box
        onClick={() => setExpanded(!expanded)}
        style={{
          borderRadius: expanded ? '5px 5px 0 0' : '5px',
          border: '1px solid #808080',
          padding: '0.5rem 0.75rem',
          cursor: 'pointer',
        }}
      >
        <Box direction={'row'} justify={'between'}>
          <Box direction={'row'} gap={'0.75rem'} align={'center'}>
            <Image height={'36px'} width={'36px'} src={waterSource} />
            <Text weight={900} margin={'-0.25rem 0 0 0'} size={'1.25rem'}>
              {t('fieldFlow.waterItem.waterSources')}({structures.length})
            </Text>
            <LevelTag level={'Paddock'} />
          </Box>
          {expanded ? (
            <Box justify={'center'}>
              <FormUp />
            </Box>
          ) : (
            <Box justify={'center'}>
              <FormDown />
            </Box>
          )}
        </Box>
      </Box>
      {expanded && (
        <Box
          overflow={'hidden'}
          pad={'1rem'}
          gap={'1rem'}
          style={{ borderTop: 0 }}
          round={'0 0 5px 5px'}
          border={{ color: '#808080', size: '1px', style: 'solid' }}
        >
          {structures.map((structure) => {
            if (typeof structure.id === 'number') {
              return (
                <Box key={structure.id}>
                  <StructureItem
                    simple={true}
                    structure={structure}
                    fields={[field]}
                    paddocks={{ [field.id!]: paddocks }}
                    updateStructure={updateStructure}
                    removeStructure={() => removeStructure(structure)}
                  />

                  <Box margin={{ top: '19px' }} alignSelf={'end'} align={'end'} direction={'row'} gap={'16px'}>
                    <Button
                      focusIndicator={false}
                      hoverIndicator={false}
                      style={{
                        width: '80px',
                        minHeight: '28px',
                        borderRadius: '0px',
                        border: '1px solid #282828',
                        fontWeight: '800',
                        fontSize: '10px',
                        lineHeight: '12px',
                        textAlign: 'center',
                        padding: '6px 0',
                        backgroundColor: 'white',
                        justifyContent: 'center',
                      }}
                      onClick={() => removeStructure(structure)}
                      label={t('fieldFlow.cancel')}
                      plain
                    />

                    <Button
                      focusIndicator={false}
                      hoverIndicator={false}
                      disabled={structure.invalid}
                      style={{
                        width: '90px',
                        minHeight: '28px',
                        borderRadius: '0px',
                        fontWeight: '800',
                        fontSize: '10px',
                        lineHeight: '12px',
                        textAlign: 'center',
                        padding: '8px 0',
                        backgroundColor: '#1B7C3D',
                        color: 'white',
                        justifyContent: 'center',
                      }}
                      onClick={async () => {
                        if (savingStructure) return;

                        setSavingStructure(true);
                        await saveStructure(structure);
                        setSavingStructure(false);
                      }}
                      label={savingStructure ? 'Saving...' : t('fieldFlow.saveChanges')}
                      plain
                    />
                  </Box>
                </Box>
              );
            }
            return (
              <Box
                ref={(ref) => {
                  elementsRef.current[structure.id] = ref;
                }}
                key={structure.id}
                round={'5px'}
                border={{ color: highlight === structure.id ? '#1457A8' : '#CCC', size: '1px', style: 'solid' }}
              >
                <Box
                  direction={'row'}
                  justify={'between'}
                  align={'center'}
                  pad={'0.25rem 0.75rem'}
                  border={{ color: highlight === structure.id ? '#1457A8' : '#CCC', size: '1px', style: 'solid', side: 'bottom' }}
                >
                  <Box direction={'row'} gap={'1rem'} align={'center'}>
                    <Image margin={{ vertical: '0.125rem' }} width={'32px'} height={'32px'} src={waterSource} />
                    <Text size={'1.25rem'} weight={900}>
                      {structure.name}
                    </Text>
                    <Text weight={400}>{paddocks.find((paddock) => paddock.id === structure.paddockId)?.properties!['name']}</Text>
                  </Box>
                </Box>
                <Box direction={'row'} align={'center'} gap={'1rem'} pad={'0.25rem 0.75rem'} margin={{ bottom: '0.75rem' }} wrap={true}>
                  <DetailsInfo label={t('fieldFlow.field')} value={field?.properties!['name']} />
                  <DetailsInfo label={t('fieldFlow.waterItem.structureType')} value={structure.subtype || ''} />
                  <DetailsInfo label={t('fieldFlow.herdItem.locationInfo.dateIn')} value={formatDate(structure.dateIn)} />
                </Box>
              </Box>
            );
          })}
          <Box
            as={'button'}
            align={'center'}
            onClick={addNewStructure}
            style={{
              cursor: 'pointer',
              display: typeof structures[structures.length - 1]?.id === 'number' ? 'none' : 'flex',
            }}
            justify={'center'}
            height={'84px'}
            background={'#FFF'}
            round={'5px'}
            border={{ color: '#CCC', size: '1px', style: 'solid' }}
          >
            <Text size={'1rem'} weight={400}>
              {t('fieldFlow.waterItem.create')}
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default WaterSourceDropdown;
