import { Box, Button, Layer, Spinner, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useMapContext } from '../../service/map/MapContext';

const FinalizeIneligibleAreasModal = ({ closeModal }: { closeModal: () => void }) => {
  const { t } = useTranslation();
  const { fields, finalizeFieldProcessing } = useMapContext();
  const [loading, setLoading] = useState<boolean>(false);

  const wetlandsCount = fields.reduce((t, f) => t + (f.properties?.processedProperties?.wetlands?.boundaries?.length ?? 0), 0);
  const publicLandsCount = fields.reduce((t, f) => t + (f.properties?.processedProperties?.publicLands?.boundaries?.length ?? 0), 0);

  return (
    <Layer background={{ opacity: 0.7 }}>
      <Box
        data-cy={'finalize-ineligible-areas-modal'}
        background={'white'}
        direction={'column'}
        alignSelf={'center'}
        height={'fit-content'}
        width={'100%'}
        pad={'24px'}
        className={'hideScrollBox'}
        overflow={'auto'}
        style={{ borderRadius: '1rem', display: 'unset', maxHeight: '95vh' }}
        align={'center'}
        justify={'center'}
      >
        <Text
          size={'33px'}
          weight={600}
          style={{
            lineHeight: '37px',
            marginTop: 0,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {t('fieldFlow.fieldProcessing.finalizeModalTitle')}
        </Text>
        <Box margin='1.5rem 0'>
          <Text
            style={{
              fontSize: '20px',
              lineHeight: '30px',
              textAlign: 'center',
            }}
          >{`We detected ${wetlandsCount} wetlands and ${publicLandsCount} public lands. What should be removed?`}</Text>
        </Box>
        {loading ? (
          <Box height={'100%'} align={'center'} justify={'center'}>
            <Spinner color={'#FFE137'} size={'large'} />
          </Box>
        ) : (
          <Box direction={'row'} gap={'8px'} justify={'between'}>
            <Button
              data-cy={'cancel-modal-button'}
              onClick={() => closeModal()}
              style={{
                width: 'fit-content',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: '#FFE137',
                padding: '8px',
                textAlign: 'center',
                height: 'fit-content',
              }}
            >
              <Text weight={600} size={'12px'} style={{ lineHeight: 'unset' }}>
                {'Back'}
              </Text>
            </Button>
            <Box direction={'row'} gap={'8px'} justify={'between'}>
              <Button
                data-cy={'no-removal-button'}
                onClick={() => {
                  setLoading(true);
                  finalizeFieldProcessing([]).then(() => {
                    setLoading(false);
                    closeModal();
                  });
                }}
                style={{
                  width: 'fit-content',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  borderColor: '#FFE137',
                  padding: '8px',
                  textAlign: 'center',
                  height: 'fit-content',
                }}
              >
                <Text weight={600} size={'12px'} style={{ lineHeight: 'unset' }}>
                  {'No removal'}
                </Text>
              </Button>
              <Button
                data-cy={'public-lands-button'}
                onClick={() => {
                  setLoading(true);
                  finalizeFieldProcessing(['PUBLIC_LANDS']).then(() => {
                    setLoading(false);
                    closeModal();
                  });
                }}
                style={{
                  width: 'fit-content',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  borderColor: '#FFE137',
                  padding: '8px',
                  textAlign: 'center',
                  height: 'fit-content',
                }}
              >
                <Text weight={600} size={'12px'} style={{ lineHeight: 'unset' }}>
                  {'Public lands only'}
                </Text>
              </Button>
              <Button
                data-cy={'wetlands-button'}
                onClick={() => {
                  setLoading(true);
                  finalizeFieldProcessing(['WETLANDS']).then(() => {
                    setLoading(false);
                    closeModal();
                  });
                }}
                style={{
                  width: 'fit-content',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  borderColor: '#FFE137',
                  padding: '8px',
                  textAlign: 'center',
                  height: 'fit-content',
                }}
              >
                <Text weight={600} size={'12px'} style={{ lineHeight: 'unset' }}>
                  {'Wetlands only'}
                </Text>
              </Button>
              <Button
                data-cy={'remove-all-button'}
                onClick={() => {
                  setLoading(true);
                  finalizeFieldProcessing(['PUBLIC_LANDS', 'WETLANDS']).then(() => {
                    setLoading(false);
                    closeModal();
                  });
                }}
                style={{
                  width: '6rem',
                  backgroundColor: '#FFE137',
                  padding: '10px',
                  textAlign: 'center',
                  height: 'fit-content',
                }}
              >
                <Text weight={600} size={'12px'} style={{ lineHeight: 'unset' }}>
                  {'Remove all'}
                </Text>
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Layer>
  );
};

export default FinalizeIneligibleAreasModal;
