import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

export const DEFAULT_LOCALE = 'en';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  // .use(LanguageDetector)
  /**
   * default translation files are located in public/locales/{__LANGUAGE__}/translation.json
   */
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: DEFAULT_LOCALE,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    returnNull: false,
  });

export default i18n;
