import { Box, Text } from 'grommet';
import React from 'react';
import { Feature } from '@turf/helpers';
import { TextInput } from '../Input/TextInput';
import applyToAllIcon from '../../../../assets/BoundaryTool/apply-to-all.svg';
import { StyledTooltip } from '../common/StyledTooltip';
import i18next, { TFunction } from 'i18next';

export interface Paddock {
  fieldId: string;
  fieldName: string;
  id: string;
  name: string;
  dominantPlant: string;
  area: number;
  areaHectare: number;
  applyToAll: (dominanntPlant: string) => void;
  index: number;
}

export const PaddockItem = (
  feature: Feature,
  updatePaddock: (feature: Feature) => void,
  size: string,
  applyToAll: (dominantPlant: string) => void,
  index: number,
  t: TFunction,
) => {
  const dominantPlants = [
    'Bahia',
    'Big Blue Stem',
    'Switchgrass',
    'Small Blue Stem',
    'Blue Gramma',
    'Bufallo Grass',
    'Native Grasses',
    'Bermudagrass',
    'Mixed Grasses',
    'Fescue',
    'Junegrass',
    'Bulbous Blue',
    'Blue Bench',
    'Idaho Fescue',
    'Ryegrass',
    'Woolgrass',
    'Crested Wheat',
    'Tall Wheatgrass',
    'Smooth Brome',
    'Cheat Grass',
    'Native Bluestem mix',
    'Brachiaria',
  ];

  const paddock = {
    ...feature.properties,
    id: feature.id,
  } as Paddock;

  return (
    <Box
      key={feature.id}
      style={{ borderRadius: '5px' }}
      height={{ min: '2rem', height: '2rem' }}
      pad={'0 16px'}
      justify={'between'}
      direction={'row'}
    >
      <Box width={'5%'}>
        <Text size={'16px'} weight={400} margin={{ top: '4px' }} style={{ lineHeight: '20px' }}>
          {index}
        </Text>
      </Box>

      <TextInput
        width='30%'
        label={''}
        value={feature.properties!['name'] ?? ''}
        error={!feature.properties!['name']}
        onChange={(name) => {
          feature.properties!['name'] = name;
          updatePaddock(feature);
        }}
      />
      <Box width={'35%'} direction={'row'}>
        <TextInput
          width={size !== 'large' ? '100px' : '140px'}
          label={''}
          value={feature.properties!['dominantPlant'] ?? ''}
          suggestions={dominantPlants}
          onChange={(dominantPlant) => {
            feature.properties!['dominantPlant'] = dominantPlant;
            updatePaddock(feature);
          }}
        />
        <Box style={{ visibility: index !== 0 ? 'hidden' : 'unset' }}>
          <StyledTooltip label={t('fieldFlow.mainWizard.Paddocks.applyPlants')}>
            <img
              style={{ height: '1.7rem', marginLeft: '0.4rem' }}
              onClick={() => {
                if (feature.properties!['dominantPlant']) {
                  return applyToAll(feature.properties!['dominantPlant']);
                }
              }}
              alt={'duplicate'}
              src={applyToAllIcon}
            />
          </StyledTooltip>
        </Box>
      </Box>
      <Box width={'15%'}>
        <Text size={'16px'} weight={400} margin={{ top: '4px' }} style={{ lineHeight: '20px' }}>
          {paddock[i18next.language === 'pt-BR' ? 'areaHectare' : 'area'].toFixed(2)}
        </Text>
      </Box>
    </Box>
  );
};
