import { cloneDeep } from 'lodash';
import { API } from '../common/api';
import { BASE_URL, CONTRACT_URL, ESTIMATE_URL, PDF_URL } from '../common/env';
import { CalculatorResult, IEstimate, IEstimateData, PracticeTypes, Scenario } from './interfaces';
import { QueueService } from './queue.service';
import i18next from 'i18next';
import AgoToast from '../../components/Toast/AgoToast';
import { IProducer } from '../common/interfaces';
import { Journey } from '../../pages/customer-profile/types/GrowerService';
import { coverAnnualDefaultValue } from '../../pages/estimation-tool/common/components/BrazilCoverCrop/BrazilCoverAnnualForm';

const getEstimateById = async (estimateId: string) => {
  return await API.get(`${ESTIMATE_URL}/estimate-service/estimates/${estimateId}`).then(async (res) => {
    if (res.data.data.length === 0) {
      return {
        currentEstimate: res.data,
      };
    }
    const { calculatorResultCombined, calculatorResultA, calculatorResultB } = await calculateEstimate(
      res.data.data,
      res.data.contractVersionId,
      estimateId,
    );
    const transformedScenarios = mergeTillagePracticesInScenarios(calculatorResultCombined.updatedScenarios);
    res.data.data.scenarios = transformedScenarios;
    return {
      paymentInfo: calculatorResultCombined,
      currentEstimate: res.data,
      optionAPaymentInfo: calculatorResultA,
      optionBPaymentInfo: calculatorResultB,
    };
  });
};

const getDraft = async (forceCreation = false, usCometV2: boolean) => {
  return API.get(`${ESTIMATE_URL}/estimate-service/estimates/draft`)
    .then((res) => {
      return res.data;
    })
    .catch(async () => {
      if (forceCreation) {
        const result = await API.put(`${ESTIMATE_URL}/estimate-service/estimates?ignoreSync=true`, {
          data: [],
          type: 'REGULAR',
          growerId: null,
          journeyId: null,
          cometVersion: usCometV2 ? 'v2' : 'v1',
        });
        return result.data;
      }
      return null;
    });
};

const getStandaloneEstimate = async (usCometV2: boolean) => {
  return getDraft(false, usCometV2).then(async (res) => {
    if (res.data.length === 0) {
      return {
        currentEstimate: res,
      };
    }
    const { calculatorResultCombined, calculatorResultA, calculatorResultB } = await calculateEstimate(res.data, res.contractVersionId, null);
    const transformedScenarios = mergeTillagePracticesInScenarios(calculatorResultCombined.updatedScenarios);
    res.data.scenarios = transformedScenarios;
    return {
      paymentInfo: calculatorResultCombined,
      optionAPaymentInfo: calculatorResultA,
      optionBPaymentInfo: calculatorResultB,
      currentEstimate: res,
    };
  });
};

const mergeTillagePracticesInScenarios = (scenarios: any[]) => {
  const mergedScenarios = cloneDeep(scenarios);
  return mergedScenarios.map((scenario: any) => {
    if (scenario.practiceType === 'PASTURE') {
      scenario.practiceType = 'PASTURELAND';
    }
    scenario.practices = transformPractices(scenario.practices);
    return scenario;
  });
};

const getIsLegumeBoolean = (isLegume: any) => {
  if (isLegume === false) {
    return false;
  }
  return true;
};

const transformCoverAnnual = (practice: any, readableCode: string) => {
  practice.details = {
    isLegume: getIsLegumeBoolean(practice.details?.isLegume),
    timesApplied: practice?.details?.timesApplied ?? coverAnnualDefaultValue.value,
  };
  practice.readableCode = readableCode;
  return practice;
};

const transformCoverCropping = (practice: any, readableCode: string) => {
  practice.details = {
    isLegume: getIsLegumeBoolean(practice.details?.isLegume),
  };
  practice.readableCode = readableCode;
  return practice;
};

const transformNitrogenReduction = (practice: any) => {
  practice.details = {
    yearsEnrolled: practice?.details?.yearsEnrolled || 5,
  };
  practice.readableCode = 'Nitrogen reduction';
  return practice;
};

const transformReduceTillage = (practice: any) => {
  practice.details = {
    new: practice?.details?.new || 'no',
    current: practice?.details?.current || 'intensive',
  };
  practice.readableCode = 'Reduced Tillage';
  practice.name = 'Reduced Tillage';
  practice.shortCode = practice?.details?.new === 'no' ? 'NT' : 'IT';
  practice.id = practice?.details?.new === 'no' ? '202291f1-1331-4c08-bae7-9752b20f838d' : '10478263-c57f-400c-b0c3-3cd9e5ae5362';
  return practice;
};

const addReadableCodeTo = (practice: any, readableCode: string) => {
  practice.readableCode = readableCode;
  return practice;
};

const transformPractices = (practices: any) => {
  return practices.map((practice: any) => {
    if (practice.shortCode === 'CC') {
      return transformCoverCropping(practice, 'Cover cropping');
    }
    if (practice.shortCode === 'NRR') {
      return transformNitrogenReduction(practice);
    }
    if (practice.shortCode === 'LA') {
      return addReadableCodeTo(practice, 'Legume Addition');
    }
    if (practice.shortCode === 'IT') {
      return transformReduceTillage(practice);
    }
    if (practice.shortCode === 'NT') {
      return transformReduceTillage(practice);
    }
    if (practice.shortCode === 'RPBS') {
      return addReadableCodeTo(practice, 'Biodiversity/Seeding');
    }
    if (practice.shortCode === 'RPF') {
      return addReadableCodeTo(practice, 'Fertilization');
    }
    if (practice.shortCode === 'RPIG') {
      return addReadableCodeTo(practice, 'Improved grazing');
    }
    if (practice.shortCode === 'ICL1' || practice.shortCode === 'ICL2') {
      return addReadableCodeTo(practice, 'ICL');
    }
    if (practice.shortCode === 'NO') {
      return addReadableCodeTo(practice, 'Nitrogen Optimization');
    }
    if (practice.shortCode === 'IN') {
      return addReadableCodeTo(practice, 'Inoculant');
    }
    if (practice.shortCode === 'CP') {
      return transformCoverCropping(practice, 'Cover perennial');
    }
    if (practice.shortCode === 'CA') {
      return transformCoverAnnual(practice, 'Cover annual');
    }
    return practice;
  });
};

const getAvailablePractices = async () => {
  return await API.get(`${ESTIMATE_URL}/estimate-service/practices`).then((res) => {
    const displayablePractices = res.data.filter((practice: any) => practice.shortCode !== 'NT');
    const ordering = {};
    const sortOrder = ['IT', 'NT', 'CC', 'CP', 'CA', 'NO', 'NRR', 'LA', 'RPBS', 'RPF', 'RPIG', 'IN', 'ICL1', 'ICL2'];
    for (let i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;
    displayablePractices.sort((a, b) => {
      return ordering[a.shortCode] - ordering[b.shortCode] || a.shortCode.localeCompare(b.shortCode);
    });
    return transformPractices(displayablePractices);
  });
};

const getTypeOfTillageImrpovement = (practice: any) => {
  if (practice?.details?.new === 'no') {
    return 'No Tillage';
  }
  return 'Reduced Tillage';
};

const splitTillagePractice = (scenarios: Scenario[]) => {
  return scenarios.map((scenario) => {
    const practiceWithReadableCode = transformPractices(scenario.practices);
    const practices = practiceWithReadableCode.map((practice: any) => {
      if (practice.readableCode === 'Reduced Tillage') {
        return {
          ...practice,
          shortCode: getTypeOfTillageImrpovement(practice) === 'No Tillage' ? 'NT' : 'IT',
          name: getTypeOfTillageImrpovement(practice) === 'No Tillage' ? 'No Tillage' : 'Reduced Tillage',
          id:
            getTypeOfTillageImrpovement(practice) === 'No Tillage' ? '202291f1-1331-4c08-bae7-9752b20f838d' : '10478263-c57f-400c-b0c3-3cd9e5ae5362',
        };
      }
      return practice;
    });
    return {
      ...scenario,
      practices,
    };
  });
};

const calculateEstimate = async (scenarios: any, contractVersionId: string, estimateId: string | null) => {
  scenarios.forEach((sce) => {
    if (
      sce.details.isLegacy &&
      sce.practices.some((pr) => pr.shortCode === 'NT' || pr.shortCode === 'IT') &&
      !sce.practices.some((pr) => pr.shortCode === 'LA')
    ) {
      // If only Tillage selected on legacy field -> remove Tillage
      sce.practices = [];
    }
  });
  const payload = {
    scenarios: splitTillagePractice(scenarios),
    country: scenarios.length > 0 && scenarios[0].location?.country ? scenarios[0].location.country : 'US',
    contractVersionId,
    estimateId,
  };
  return await API.put(`${ESTIMATE_URL}/estimate-service/calculator/calculate-all`, payload).then((res) => {
    return res.data;
  });
};

const syncEstimate = (estimate) => {
  return API.post(`${CONTRACT_URL}/contract-service/contract/sync`, estimate);
};

const saveEstimate = async (estimate: any) => {
  const sync = () => {
    return syncEstimate(estimate);
  };
  const response = await API.put(`${ESTIMATE_URL}/estimate-service/estimates?ignoreSync=true`, estimate).then((res) => {
    res.data.data = mergeTillagePracticesInScenarios(res.data.data);
    return res.data;
  });

  if (estimate.sync && estimate.growerId) {
    QueueService.enqueue(sync);
  }
  return response;
};

const getBrazilSpecies = async () => {
  return API.get(`${ESTIMATE_URL}/estimate-service/calculator/brazil-species-v2`).then((res) => res.data);
};

const getBrazilCounties = (state: string) => {
  return API.get(`${BASE_URL}/estimate-service/calculator/brazil-counties/${state}`).then((res) => res.data);
};

const generatePracticesFromField = (
  practices,
  species: string[],
  inputs: string[],
  practiceType: PracticeTypes,
  iclFactor: { [key: string]: number },
) => {
  const practiceShortCode = {
    coverCropping: 'CC',
    nitrogenReduction: 'NRR',
    legume: 'LA',
    reducedTillage: 'IT',
    noTillage: 'NT',
    seeding: 'RPBS',
    fertilization: 'RPF',
    grazing: 'RPIG',
    nitrogenOptimization: 'NO',
    ROW_CROP: {
      ICL: 'ICL2',
    },
    PASTURELAND: {
      ICL: 'ICL1',
    },
    coverPerennial: 'CP',
    coverAnnual: 'CA',
    inoculant: 'IN',
  };

  const practiceReadableCode = {
    coverCropping: 'Cover cropping',
    nitrogenReduction: 'Nitrogen reduction',
    legume: 'Legume Addition',
    reducedTillage: 'Reduced Tillage',
    noTillage: 'No Tillage',
    seeding: 'Biodiversity/Seeding',
    fertilization: 'Fertilization',
    grazing: 'Improved grazing',
    ICL: 'ICL',
    coverAnnual: 'Cover annual',
    coverPerennial: 'Cover perennial',
    inoculant: 'Inoculant',
  };

  const ICLDetails = {
    summer: {
      crop: 'Soja',
      production: 3600,
      dryMatter: 3600 / iclFactor['Soja'] - 3600,
    },
    fall: {
      crop: 'Soja',
      production: 3600,
      dryMatter: 3600 / iclFactor['Soja'] - 3600,
    },
    winter: {
      crop: 'Brachiaria',
      production: undefined,
      dryMatter: 2700,
    },
  };

  const practiceDetails = {
    RPBS: {
      species: species[0],
      legume: 'Não',
    },
    RPF: {
      fertilizer: inputs.filter((input) => ['Sem insumos', 'Calcário', 'P'].indexOf(input) === -1)[0],
    },
    RPIG: {
      grazingIntensity: 'Remoção anual 25-75%',
    },
    ICL1: ICLDetails,
    ICL2: ICLDetails,
    IN: {
      rate: 0.29,
    },
  };

  for (const practice of practices) {
    practice.shortCode = practiceShortCode[practice.metadata.practiceName] || practiceShortCode[practiceType!][practice.metadata.practiceName];
    practice.readableCode = practiceReadableCode[practice.metadata.practiceName];
    practice.details = practiceDetails[practice.shortCode];
    practice.availablePaymentOptions = ['OPTION_A'];
  }

  return practices;
};

const mapFertilizationPractice = (practice, option) => {
  if (practice.readableCode === 'Fertilization') {
    return {
      ...practice,
      details: {
        ...practice.details,
        fertilizer: option,
      },
    };
  }
  return practice;
};

const mapSeedingPractice = (field, newDryMatter, option) => {
  if (field.practiceType === 'PASTURELAND') {
    field.details.dryMatter = newDryMatter;
    field.practices = field.practices.map((practice) => {
      if (practice.readableCode === 'Biodiversity/Seeding') {
        return {
          ...practice,
          details: {
            ...practice.details,
            species: option,
            legume:
              (field.baseline && option === field.baseline.species) || (!field.baseline && option === 'Brachiaria brizantha cv Marandu')
                ? 'Sim'
                : practice.details.legume ?? 'Não',
            production: 'mean production',
          },
        };
      }
      return practice;
    });
  }
  return field;
};

const mapSeedingLegume = (field, legume) => {
  field.practices = field.practices.map((practice) => {
    if (practice.readableCode === 'Biodiversity/Seeding') {
      return {
        ...practice,
        details: {
          ...practice.details,
          legume: legume,
        },
      };
    }
    return practice;
  });
  return field;
};

const mapCoverAnnualPractice = (practice, option) => {
  if (practice.readableCode === 'Cover annual') {
    return {
      ...practice,
      details: {
        ...practice.details,
        timesApplied: option,
      },
    };
  }
  return practice;
};

const getICLFactor = () => {
  return API.get(`${ESTIMATE_URL}/estimate-service/calculator/icl-factor`).then((res) => res.data);
};

const downloadEstimateAsPdf = (
  template: string,
  data: IEstimateData,
  region: string,
  shouldConvertLead: boolean,
  option?: string,
  isChannelPartner?: boolean,
) => {
  return API.post(
    `${PDF_URL}/pdf/download?lang=${i18next.language}`,
    {
      templateName: template,
      data: data,
    },
    'blob',
  )
    .then((res) => {
      const file = new Blob([res.data], {
        type: 'application/pdf',
      });
      const fileURL = document.createElement('a');
      fileURL.href = URL.createObjectURL(file);

      const nameParts: string[] = []; // Estimate Name + First Name + Last Name + Report.pdf
      nameParts.push(data.estimate?.name || (region === 'BR' ? 'Nova_estimativa' : 'New_estimate'));
      if (data.producer?.name && data.producer.name !== 'Producer') {
        nameParts.push(data.producer.name);
      }

      if (region === 'BR') {
        nameParts.push(`Relatorio-${!option ? 'combinado' : option === 'optionA' ? 'precoFixo' : 'porcentagem'}.pdf`);
      } else {
        nameParts.push(`Report-${!option ? 'combined' : option === 'optionA' ? 'option-A' : 'option-B'}.pdf`);
      }
      fileURL.download = nameParts.join('_').replace(/ /g, '_');
      fileURL.click();
      URL.revokeObjectURL(fileURL.href);
      fileURL.remove();
      if (shouldConvertLead && isChannelPartner && !data.producer?.opportunityId && region !== 'BR') {
        return API.post(`${BASE_URL}/base/salesforce/convert-lead`, {
          leadId: data.producer?.leadId,
          accountFields: {
            name: `${data.producer?.company}`,
          },
          contactsFields: {
            firstName: data.producer?.firstName,
            lastName: data.producer?.lastName,
            email: data.producer?.email,
            phone: data.producer?.phone,
          },
          opportunityFields: {
            name: `${data.producer?.company} ${data.producer?.name}`,
            stageName: 'OA & Pitch Meeting',
          },
        });
      }
    })
    .catch(() => {
      AgoToast.showToast({
        title: 'Error while generating report.',
        message: 'Please try again.',
        type: 'error',
        toastId: 'REPORT_DOWNLOAD',
      });
    });
};

const getProducerDefaultName = (country: string) => {
  if (country === 'US') {
    return 'Producer';
  }
  return 'Produtor';
};

const parseEstimateData = (
  user: Record<string, any>,
  journey: Journey,
  producer: IProducer,
  calculatorResult: CalculatorResult,
  estimate: IEstimate,
): IEstimateData => {
  let growerName = journey ? journey.firstName + ' ' + journey.lastName : getProducerDefaultName(user.country);

  if (producer?.FirstName || producer?.Name) {
    const names: string[] = [];
    if (producer.FirstName) {
      names.push(producer.FirstName);
    }
    if (producer.LastName) {
      names.push(producer.LastName);
    }
    if (producer.Name && !names.length) {
      names.push(producer.Name);
    }
    growerName = names.join(' ') || '';
  }
  return {
    producer: {
      name: growerName,
      firstName: producer?.FirstName,
      lastName: producer?.LastName,
      company: producer?.Company,
      email: producer?.Email,
      phone: producer?.Phone,
      leadId: producer?.leadId,
      opportunityId: producer?.opportunityId,
    },
    agoroUser: user,
    estimate,
    calculatorResult,
  };
};

const isEstimateDifferentFromField = (allFields, fieldsFromFS) => {
  const fieldsMatched: string[] = [];
  for (const estimateField of allFields) {
    const found = fieldsFromFS.find((field) => {
      const practicesMatched: string[] = [];

      for (const practice of estimateField.practices) {
        const found = field.practices.find((fieldPractice) => {
          return fieldPractice.readableCode === practice.readableCode;
        });
        if (found) {
          practicesMatched.push(found.readableCode);
        } else {
          break;
        }
      }

      return (
        field.location.state === estimateField.location.state &&
        field.location.county === estimateField.location.county &&
        field.area === estimateField.area &&
        practicesMatched.length === field.practices.length &&
        field.practices.length === estimateField.practices.length &&
        fieldsMatched.indexOf(field.id) === -1
      );
    });

    if (found) {
      fieldsMatched.push(found.id);
    } else {
      break;
    }
  }

  return (
    fieldsFromFS.length > 0 && allFields.length > 0 && (fieldsFromFS.length !== fieldsMatched.length || fieldsFromFS.length !== allFields.length)
  );
};

export {
  generatePracticesFromField,
  getAvailablePractices,
  getStandaloneEstimate,
  getEstimateById,
  calculateEstimate,
  saveEstimate,
  getBrazilSpecies,
  getBrazilCounties,
  mapFertilizationPractice,
  mapCoverAnnualPractice,
  mapSeedingPractice,
  mapSeedingLegume,
  getICLFactor,
  parseEstimateData,
  downloadEstimateAsPdf,
  isEstimateDifferentFromField,
  getDraft,
};
