import { Box, Layer, Text, Heading, Button } from 'grommet';
import { useMapContext } from '../../../service/map/MapContext';
import { useTranslation } from 'react-i18next';
import SyncTable from './SyncTable';
import { useCallback } from 'react';

interface SyncErrorModalProps {
  cancel: () => void;
}

export const SyncErrorModal = ({ cancel }: SyncErrorModalProps): JSX.Element => {
  const { t } = useTranslation();
  const { syncErrors } = useMapContext();

  const getIssueCount = useCallback(() => {
    const issues: Record<string, boolean> = {
      missingPractice: false,
      wrongPaymentType: false,
    };
    const practiceOccurrences: Record<string, number> = {};
    syncErrors.practices.forEach((error) => {
      practiceOccurrences[error.name] ? (practiceOccurrences[error.name] += 1) : (practiceOccurrences[error.name] = 1);
    });
    issues.wrongPaymentType = Object.values(practiceOccurrences).some((errorOccurrence) => errorOccurrence > 1);
    issues.missingPractice = Object.values(practiceOccurrences).some((errorOccurrence) => errorOccurrence === 1);
    return { issues, practiceOccurrences };
  }, [syncErrors.practices]);

  const issueAnalysis = getIssueCount();

  return (
    <Layer background={{ opacity: 0.7 }} onEsc={cancel} onClickOutside={cancel}>
      <Box
        data-cy={'sync-error-modal'}
        background={'white'}
        direction={'column'}
        alignSelf={'center'}
        pad={'24px'}
        className={'hideScrollBox'}
        overflow={'auto'}
        style={{ borderRadius: '1rem', display: 'unset', width: '708px', maxHeight: '95vh' }}
        align={'center'}
        justify={'center'}
      >
        <Heading fill margin={'0'} style={{ textAlign: 'center', fontWeight: 700 }}>
          {t('fieldFlow.syncErrorModal.title')}
        </Heading>
        <Box margin='1rem 0 1.5rem 0'>
          <Text data-cy={'sync-error-message'} size='16px' alignSelf='center' color='#282828' textAlign={'center'}>
            {t('fieldFlow.syncErrorModal.message.chosen')}
            {issueAnalysis.issues.missingPractice ? t('fieldFlow.syncErrorModal.message.practice') : ''}
            {issueAnalysis.issues.wrongPaymentType && issueAnalysis.issues.missingPractice ? t('fieldFlow.syncErrorModal.message.and') : ''}
            {issueAnalysis.issues.wrongPaymentType ? t('fieldFlow.syncErrorModal.message.payment') : ''}
            {t('fieldFlow.syncErrorModal.message.match')}
          </Text>
        </Box>
        <SyncTable practiceData={syncErrors.practices} type={'platform'} issueCount={issueAnalysis.practiceOccurrences} />
        <SyncTable practiceData={syncErrors.practices} type={'salesforce'} issueCount={issueAnalysis.practiceOccurrences} />
        <Box direction={'row'} gap={'8px'} justify={'between'} style={{ justifyContent: 'end', marginTop: '2rem' }}>
          <Button
            data-cy={'go-back-button'}
            onClick={cancel}
            style={{ width: '12rem', backgroundColor: '#FFE137', padding: '10px', textAlign: 'center', border: '1px solid #000' }}
          >
            <Text weight={600} size={'1rem'} style={{ lineHeight: 'unset' }}>
              {t('fieldFlow.syncErrorModal.cancel')}
            </Text>
          </Button>
        </Box>
      </Box>
    </Layer>
  );
};

export default SyncErrorModal;
