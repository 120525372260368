import { Box, Image, Text } from 'grommet';
import { getIconDictionary } from '../../service/common/constants';
import React from 'react';
import { StyledTooltip } from '../common/StyledTooltip';
import { useTranslation } from 'react-i18next';

interface PracticesIconsComponentInterface {
  practices: Array<string>;
}

export const PracticesIconsComponent = ({ practices }: PracticesIconsComponentInterface): JSX.Element => {
  const { t } = useTranslation();
  const iconDictionary = getIconDictionary(t);
  return (
    <Box direction={'row'} gap={'0.75rem'} justify={'center'}>
      {!!practices && !!practices.length ? (
        practices.map((practice, index) => {
          return (
            <Box key={index + '-' + practice}>
              <StyledTooltip
                dropProps={{ align: { bottom: 'top' } }}
                showToolTip={true}
                label={iconDictionary[practice as keyof typeof iconDictionary].tooltip}
              >
                <Image height={24} width={24} src={iconDictionary[practice as keyof typeof iconDictionary].icon} data-cy={`practice-icon-${practice}`} />
              </StyledTooltip>
            </Box>
          );
        })
      ) : (
        <Text>--</Text>
      )}
    </Box>
  );
};
