import { AnyAction } from 'redux';

const initState: any = {
  fullProducerList: [],
  statusFilter: null,
  searchFilter: null,
  sortBy: {
    field: 'name',
    sortDirection: 'asc',
  },
  cycleFilter: {},
  journeyInformation: null,
  producerInformation: null,
  cycleInformation: null,
  producerListState: { lifeCycle: 'NONE' },
  producerInformationState: { lifeCycle: 'NONE' },
  journeyInformationState: { lifeCycle: 'NONE' },
  cycleInformationState: { lifeCycle: 'NONE' },
};

const getFilterResult = (statusFilter: string, producerItem: any) => {
  if (!statusFilter) {
    return true;
  }
  return producerItem.case.status === statusFilter;
};

const getSortField = (producer: any, fieldName: string) => {
  if (fieldName === 'status') {
    return producer.case.status;
  }
  return producer[fieldName];
};

const getCompareResult = (field: string, producer1: any, producer2: any) => {
  return getSortField(producer1, field).localeCompare(getSortField(producer2, field));
};

const producerComparator = (sortBy: any, producer1: any, producer2: any) => {
  const { field, sortDirection } = sortBy;
  const ascSortResult = getCompareResult(field, producer1, producer2);
  return sortDirection === 'asc' ? ascSortResult : ascSortResult * -1;
};

const getSearchFilter = (searchFilter: string, producerItem: any) => {
  if (!searchFilter) {
    return true;
  }
  if (producerItem.case.status.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
    return true;
  }
  if (producerItem.name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
    return true;
  }
  if (producerItem.location.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) {
    return true;
  }
  return false;
};

const getCycleFilter = (cycleFilter: any, producerItem: any) => {
  const stages: string[] = [];
  for (const stage in cycleFilter) {
    if (cycleFilter[stage]) {
      stages.push(stage);
    }
  }

  return !!(!stages.length || producerItem.cycles[1]?.some((cycleStage) => stages.indexOf(cycleStage) !== -1));
};

const getProcessedProducerList = (fullProducerList: Array<any>, statusFilter: string, searchFilter: string, sortBy: any, cycleFilter: any) => {
  return fullProducerList
    .filter((producer: any) => getFilterResult(statusFilter, producer))
    .filter((producer: any) => getSearchFilter(searchFilter, producer))
    .filter((producer: any) => getCycleFilter(cycleFilter, producer))
    .sort((producer1, producer2) => producerComparator(sortBy, producer1, producer2));
};

const getSortDirection = (actionSortBy: any, stateSortBy: any) => {
  if (actionSortBy.field === stateSortBy.field) {
    return stateSortBy.sortDirection === 'asc' ? 'desc' : 'asc';
  }
  return 'asc';
};

const reducer = (state = initState, action: AnyAction) => {
  switch (action.type) {
    case 'GET_PRODUCER_LIST_SUCCEEDED': {
      if (['SUCCESS', 'ERROR'].indexOf(state.cycleInformationState.lifeCycle) !== -1) {
        for (const producer of action.payload) {
          producer.cycles = state.cycleInformation[producer.opportunityId] || state.cycleInformation[producer.leadId] || {};
        }
      }
      state = {
        ...state,
        fullProducerList: action.payload,
        processedProducerList: getProcessedProducerList(action.payload, state.statusFilter, state.searchFilter, state.sortBy, state.cycleFilter),
        producerListState: { lifeCycle: 'SUCCESS' },
      };
      return state;
    }
    case 'GET_CYCLE_STATUSES_SUCCEEDED': {
      state.cycleInformationState = { lifeCycle: 'SUCCESS' };
      state.cycleInformation = action.payload;
      if (state.producerListState.lifeCycle === 'SUCCESS') {
        for (const producer of state.processedProducerList) {
          producer.cycles = action.payload[producer.opportunityId] || action.payload[producer.leadId] || {};
        }
        for (const producer of state.fullProducerList) {
          producer.cycles = action.payload[producer.opportunityId] || action.payload[producer.leadId] || {};
        }
      }
      return state;
    }
    case 'GET_CYCLE_STATUSES_ERRORED': {
      state.cycleInformationState = { lifeCycle: 'ERROR' };
      state.cycleInformation = state.cycleInformation || {};
      if (state.producerListState.lifeCycle === 'SUCCESS') {
        for (const producer of state.processedProducerList) {
          producer.cycles = {};
        }
        for (const producer of state.fullProducerList) {
          producer.cycles = {};
        }
      }
      return state;
    }
    case 'GET_PRODUCER_BY_ID_SUCCEEDED': {
      return {
        ...state,
        producerInformation: action.payload,
        producerInformationState: { lifeCycle: 'SUCCESS' },
      };
    }
    case 'GET_JOURNEY_BY_ID_SUCCEEDED': {
      return {
        ...state,
        journeyInformation: action.payload,
        journeyInformationState: { lifeCycle: 'SUCCESS' },
      };
    }
    case 'PRODUCER_STATUS_FILTERED': {
      return {
        ...state,
        statusFilter: action.payload,
        processedProducerList: getProcessedProducerList(state.fullProducerList, action.payload, state.searchFilter, state.sortBy, state.cycleFilter),
      };
    }
    case 'PRODUCER_SEARCH_APPLIED': {
      return {
        ...state,
        searchFilter: action.payload,
        processedProducerList: getProcessedProducerList(state.fullProducerList, state.statusFilter, action.payload, state.sortBy, state.cycleFilter),
      };
    }
    case 'PRODUCER_SORT_CLICKED': {
      const newSortBy = {
        field: action.payload.field,
        sortDirection: getSortDirection(action.payload, state.sortBy),
      };
      return {
        ...state,
        sortBy: newSortBy,
        processedProducerList: getProcessedProducerList(state.fullProducerList, state.statusFilter, state.searchFilter, newSortBy, state.cycleFilter),
      };
    }
    case 'PRODUCER_FILTERED': {
      return {
        ...state,
        cycleFilter: action.payload,
        processedProducerList: getProcessedProducerList(state.fullProducerList, state.statusFilter, state.searchFilter, state.sortBy, action.payload),
      };
    }
    case 'CLEAR_PRODUCER_FILTERS': {
      const defaultSortBy = {
        field: 'name',
        sortDirection: 'asc',
      };
      return {
        ...state,
        statusFilter: null,
        searchFilter: null,
        sortBy: defaultSortBy,
        processedProducerList: getProcessedProducerList(state.fullProducerList, '', '', defaultSortBy, {}),
      };
    }
    case 'CLEAR_PRODUCER_STORE': {
      return {
        ...state,
        journeyInformation: null,
        producerInformation: null,
        producerListState: { lifeCycle: 'NONE' },
        producerInformationState: { lifeCycle: 'NONE' },
        journeyInformationState: { lifeCycle: 'NONE' },
      };
    }
  }
  return state;
};

export default reducer;
