import { GeoJSONObject, Position } from '@turf/turf';

export interface MapSearchResultFeature extends ReturnType<JSON['parse']> {
  place_name: string;
  place_type: string[];
  center: Position;
}
export interface MapSearchResult extends GeoJSONObject {
  features: MapSearchResultFeature[];
  query: string[];
  attribution: string;
}

export type MapCompleteType = {
  label: JSX.Element;
  value: MapSearchResultFeature;
};

const GEOCODING_DEFAULT_TYPES = ['region', 'district', 'place', 'locality', 'neighborhood', 'address', 'postcode'];

const searchAddress = async (
  params: string,
  searchDataTypes?: Array<string>,
  autocomplete = false,
  otherQueries?: string,
): Promise<MapSearchResult | undefined> => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${params}.json?access_token=${'' + process.env['REACT_APP_MAP_BOX_KEY']}&types=${
    searchDataTypes?.join(',') ?? GEOCODING_DEFAULT_TYPES.join(',')
  }&autocomplete=${autocomplete}${otherQueries ?? ''}`;
  try {
    const response = await fetch(url);
    return await response.json();
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const AddressSearchService = {
  searchAddress,
};
