import { useEffect, useState } from 'react';
import { MarkerHelper } from './map/MarkerHelper';
import mapboxgl from 'mapbox-gl';
import { Feature } from '@turf/helpers';
import { API } from './api';
import { FieldLayout } from '../types/FieldLayout';
// @ts-ignore
import { Herd } from '../components/Herd/HerdItem';
import { useStore } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
// @ts-ignore
import { addDays, getRandomNumber } from './common/utils';
import { EditData } from '../types/EditChanges';
import { states } from './map/MapContext';
import { Journey } from '../types/Journey';

export const useHerdService = (
  events: any[],
  paddocks: { [key: string]: Feature[] },
  state: states,
  setState: (state: states) => void,
  currentVersion?: FieldLayout,
  map?: mapboxgl.Map,
  journey?: Journey,
) => {
  const [searchParams] = useSearchParams();
  const fieldId = searchParams.get('fieldId');
  const store = useStore();
  const navigate = useNavigate();
  const { journeyId } = useParams();
  const [herds, setHerds] = useState<any[]>([]);
  const [herdsInvalid, setHerdsInvalid] = useState(false);

  //Function was not updating new paddocks on version change, had to pass them as parameter to avoid a new useEffect.
  function handleHerdMarkers(herd, newPaddocks?: Record<string, any>) {
    if (!map) {
      return;
    }

    const type = herdRemovedThisVersion(herd) ? 'HERD_REMOVED' : 'HERD';
    const latestRotation = herd.unsavedMoveEvent
      ? { data: herd.unsavedMoveEvent }
      : herd?.rotation?.[0] ?? { data: { paddockId: herd.paddockId, fieldId: herd.fieldId } };

    if (latestRotation.fieldId === 'OFFSITE') {
      if (herd?.marker) {
        herd?.marker?.remove();
        delete herd.marker;
      }
      return herd;
    }

    return MarkerHelper.generateNewMarkerOnMap(
      herd,
      newPaddocks ?? paddocks,
      map,
      type,
      () => {
        if (state === 'OVERVIEW' || state === 'DETAILS') {
          setState('DETAILS');
          navigate(`/v2/fields/${journeyId}/details?fieldId=${latestRotation.fieldId || herd.fieldId}&elementId=${herd.id}&type=HERD`);
        }
      },
      state === 'OVERVIEW' || state === 'DETAILS' ? 'pointer' : 'default',
      {
        paddockId: latestRotation.data.paddockId ?? 'OFFSITE',
        fieldId: latestRotation.data.fieldId,
      },
    );
  }

  function updateHerdData(updatedHerdData, newPaddocks?: Record<string, any>) {
    if (!map) {
      return;
    }

    const treatedHerds = updatedHerdData.map((herd) => handleHerdMarkers(herd, newPaddocks));
    updatedHerdData.forEach((herd, index) => {
      if (treatedHerds[index]?.marker) updatedHerdData[index].marker = treatedHerds[index]?.marker;
    });
    filterHerdMarkers(updatedHerdData);
    setHerds(updatedHerdData);
  }

  const getHerds = async (newPaddocks?: { [key: string]: Feature[] }) => {
    const dateRange = currentVersion ? currentVersion.startDate + (currentVersion.endDate ? ',' + currentVersion.endDate : '') : '';
    const params: any = {
      journeyId: journey?.id,
      dateRange,
      rotation: true,
    };
    await clearHerds();
    return API.get(store, '/field-service/herds', params)
      .then((herdResults) => {
        const treatedHerds = herdResults.data.map((herd: any) => {
          if (herd.rotation.length === 0 || (herd.rotation.length === 1 && herd.rotation[0].data.dateIn < currentVersion!.startDate)) {
            herd.rotation.unshift({
              timestamp: currentVersion!.startDate,
              fieldId: 'OFFSITE',
              data: {
                herdId: herd.id,
                fieldId: 'OFFSITE',
                paddockId: 'OFFSITE',
                fieldName: 'OFFSITE',
                paddockName: 'OFFSITE',
                dateIn: addDays(currentVersion!.startDate, -1),
              },
              local: true,
            });
          }

          return {
            ...herd,
            fieldId: herd.rotation?.[0]?.fieldId ?? herd.fieldId,
            paddockId: herd.rotation?.[0]?.data?.paddockId ?? herd.paddockId,
            startDate: herd.startDate ? new Date(herd.startDate) : undefined,
            endDate: herd.endDate ? new Date(herd.endDate) : undefined,
            isCurrent: !herd.endDate,
          };
        });
        updateHerdData(treatedHerds, newPaddocks);
      })
      .catch(console.log);
  };

  function filterHerdMarkers(herdData?: Record<string, any>[] | Record<string, any>) {
    if (!herdData) herdData = herds;
    if (!herdData.length) herdData = [herdData];

    for (const herd of herdData as Array<Record<string, any>>) {
      if (herd.marker) {
        if (!fieldId) {
          herd.marker.addTo(map);
          continue;
        }

        if (!herd?.rotation || herd?.rotation.length === 0) {
          if (herd.fieldId !== fieldId) herd.marker.remove();
        } else {
          if (herd?.rotation?.[0]?.fieldId !== fieldId) {
            herd.marker.remove();
          } else {
            herd.marker.addTo(map);
          }
        }
      }
    }
  }

  function removeAllMarkers() {
    for (const herd of herds) {
      if (herd.marker) herd.marker.remove();
    }
  }

  useEffect(() => {
    if (state === 'SECOND_SETUP') return removeAllMarkers();
    filterHerdMarkers(herds);
  }, [fieldId, state]);

  function getOffsiteHerds() {
    return herds.filter((h) => h.rotation?.[0]?.fieldId === 'OFFSITE');
  }

  function updateHerd(herd: any) {
    setHerds((herds) => {
      const index = herds.findIndex((herdSearch) => herdSearch.id === herd.id);
      herds[index] = {
        ...herd,
        invalid: isHerdInvalid(herd),
      };
      return [...herds];
    });
  }

  useEffect(() => {
    setHerdsInvalid(herds.some(isHerdInvalid));
  }, [herds]);

  function isHerdInvalid(item: Herd) {
    return (
      !item.name ||
      !item.startDate ||
      !item.fieldId ||
      !item.dateIn ||
      (item.fieldId !== 'OFFSITE' && !item.paddockId) ||
      (!item.isCurrent && !item.endDate)
    );
  }

  function addHerd(fieldId?: string, fieldName?: string) {
    let paddockId: any = undefined;
    let paddockName: any = undefined;

    if (fieldId && paddocks[fieldId].length === 1) {
      paddockId = paddocks[fieldId][0].id;
      paddockName = paddocks[fieldId][0].properties?.name;
    }

    const newHerd = handleHerdMarkers({
      id: getRandomNumber(),
      name: `Herd #${herds.length + 1}`,
      fieldId,
      fieldName,
      paddockId,
      paddockName,
      isCurrent: true,
      operationType: null,
      animal: null,
      breed: null,
      invalid: true,
    });
    filterHerdMarkers(newHerd);
    setHerds([...herds, newHerd]);
  }

  function duplicateHerd(herd: any) {
    const rotationData = herd.rotation?.[0]?.data;

    const newHerd = handleHerdMarkers({
      ...herd,
      isCurrent: !herd.endDate,
      data: { ...herd.data },
      id: getRandomNumber(),
      versionId: undefined,
      fieldId: rotationData?.fieldId,
      fieldName: rotationData?.fieldName,
      paddockId: rotationData?.paddockId,
      paddockName: rotationData?.paddockName,
      dateIn: undefined,
      marker: undefined,
      name: `Herd #${herds.length + 1}`,
      rotation: undefined,
      editData: undefined,
    });
    newHerd.invalid = isHerdInvalid(newHerd);
    filterHerdMarkers(newHerd);
    setHerds([...herds, newHerd]);
  }

  function removeHerd(herd: any) {
    setHerds((herds) => {
      if (herd.marker) {
        herd.marker.remove();
      }

      return herds.filter((herdSearch) => herdSearch.id !== herd.id);
    });
  }

  async function clearHerds() {
    for (const herd of herds) {
      if (herd.marker) herd.marker.remove();
    }

    setHerds([]);
  }

  function editHerd(herdId: string, editChanges: EditData) {
    return API.patch(store, `/field-service/herds/${herdId}`, editChanges).then((response) => {
      setHerds((herds) => {
        const oldHerd = herds.find((herd) => herd.id === herdId);
        const newHerd = response.data;

        herds[herds.indexOf(oldHerd)] = {
          ...oldHerd,
          ...newHerd,
          startDate: newHerd.startDate ? new Date(newHerd.startDate) : undefined,
          endDate: newHerd.endDate ? new Date(newHerd.endDate) : undefined,
          dateIn: newHerd.dateIn ? new Date(newHerd.dateIn) : undefined,
          rotation: oldHerd.rotation,
        };
        return [...herds];
      });
    });
  }

  function saveHerd(herd: any) {
    const body = [
      {
        ...herd,
        growerId: journey?.leadId ?? journey?.opportunityId,
        journeyId: journey?.id,
        invalid: undefined,
        marker: undefined,
        id: typeof herd.id === 'number' ? undefined : herd.id,
      },
    ];

    return API.post(store, '/field-service/herds', body).then((response) => {
      setHerds((herds) => {
        const index = herds.findIndex((herdSearch: any) => herdSearch.id === herd.id);
        const newHerd = response.data[0];

        herds[index].marker?.remove();
        herds[index] = handleHerdMarkers({
          ...newHerd,
          startDate: newHerd.startDate ? new Date(newHerd.startDate) : undefined,
          endDate: newHerd.endDate ? new Date(newHerd.endDate) : undefined,
          dateIn: newHerd.dateIn ? new Date(newHerd.dateIn) : undefined,
        });
        filterHerdMarkers(herds[index]);

        return [...herds];
      });
    });
  }

  function saveHerds(): Promise<any> {
    const bodySave = herds
      .filter((h) => typeof h.id === 'number')
      .map((herd) => {
        herd.marker.remove();
        return {
          ...herd,
          growerId: journey?.leadId ?? journey?.opportunityId,
          journeyId: journey?.id,
          marker: undefined,
          invalid: undefined,
          id: undefined,
        };
      });
    const bodyMoveArray = herds
      .filter((h) => typeof h.id !== 'number' && !!h.unsavedMoveEvent)
      .map((h) => {
        return h.unsavedMoveEvent;
      });

    const promises = [
      API.post(store, '/field-service/herds', bodySave),
      ...bodyMoveArray.map((bodyMove) => API.post(store, '/field-service/herds/move', bodyMove)),
    ];

    return Promise.all(promises).then((results) => {
      const oldHerds = herds.filter((h) => typeof h.id !== 'number');
      const treatedNewHerds = (results.shift() ?? { data: [] }).data.map((herd: Herd) => {
        return handleHerdMarkers({
          ...herd,
          startDate: herd.startDate ? new Date(herd.startDate) : undefined,
          endDate: herd.endDate ? new Date(herd.endDate) : undefined,
          dateIn: herd.dateIn ? new Date(herd.dateIn) : undefined,
        });
      });
      const newHerds = [...oldHerds, ...treatedNewHerds];

      results.forEach((result) => {
        const rotationData = result.data;
        const index = newHerds.findIndex((herd) => herd.id === rotationData[0].data.herdId);
        newHerds[index].rotation = [...rotationData, ...newHerds[index].rotation];
        newHerds[index] = handleHerdMarkers(newHerds[index]);
      });
      setHerds(newHerds);
      filterHerdMarkers(newHerds);
    });
  }

  async function saveMoveEvent(moveEvent: any, herd: any): Promise<any> {
    const newRotation = await API.post(store, '/field-service/herds/move', moveEvent);
    herd.rotation = [...newRotation.data, ...herd.rotation.filter((rotation: { local: boolean | undefined }) => !rotation.local)];
    handleHerdMarkers(herd);
    updateHerd(herd);
    filterHerdMarkers();
  }

  function removeSavedHerd(moveEvent: any): Promise<any> {
    return API.post(store, '/field-service/herds/remove', moveEvent).then(async () => {
      await getHerds();
    });
  }

  function getMoveLocations(excludePaddocks?: string[]) {
    const locations: any[] = excludePaddocks?.includes('OFFSITE')
      ? []
      : [
          {
            locationName: `OFFSITE`,
            fieldId: 'OFFSITE',
            fieldName: 'OFFSITE',
          },
        ];
    Object.keys(paddocks).forEach((fieldId: any) => {
      paddocks[fieldId as keyof typeof paddocks].forEach((paddock) => {
        if (!excludePaddocks || (paddock.id && !excludePaddocks.includes(paddock.id.toString())))
          locations.push({
            locationName: `${paddock.properties?.['fieldName']}${paddocks[fieldId].length > 1 ? ' - ' + paddock.properties?.['name'] : ''}`,
            paddockName: paddock.properties?.['name'],
            fieldName: paddock.properties?.['fieldName'],
            fieldId: fieldId,
            paddockId: paddock.id,
          });
      });
    });
    return locations;
  }

  function herdRemovedThisVersion(herd: any) {
    return new Date(herd.endDate).getTime() < new Date(currentVersion?.endDate ?? new Date()).getTime();
  }

  function getLastMovementDate(herd: any): Promise<Date> {
    return API.get(store, `/field-service/herds/${herd.id}/movements/last`).then((result) => new Date(result.data.timestamp));
  }

  function getHerdHistory(herd: any): Promise<any[]> {
    return API.get(store, `/field-service/herds/${herd.id}/history`).then((res) => res.data);
  }

  async function getLastChangeDate(herd: any): Promise<{ lastDate: Date }> {
    return await API.get(store, `/field-service/herds/${herd.id}/changes/last-date`).then((res) => res.data);
  }

  async function getLastHerdDate(herd: any): Promise<{ lastDate: Date }> {
    return await API.get(store, `/field-service/herds/${herd.id}/last-date`).then((res) => res.data);
  }

  return {
    getHerds,
    clearHerds,

    herds,
    herdsInvalid,
    updateHerdData,
    filterHerdMarkers,
    handleHerdMarkers,
    addHerd,
    duplicateHerd,
    updateHerd,
    removeHerd,
    saveHerd,
    saveHerds,
    saveMoveEvent,
    removeSavedHerd,
    getOffsiteHerds,
    getLastMovementDate,
    getHerdHistory,
    getLastChangeDate,
    getLastHerdDate,
    editHerd,

    getMoveLocations,
    herdRemovedThisVersion,
  };
};
