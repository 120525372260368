import { Box, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { SyncItem } from './SyncItem';
import { useMapContext } from '../../../service/map/MapContext';

interface SyncTableProps {
  type: 'platform' | 'salesforce' | 'currentData';
  practiceData: any[];
  issueCount?: Record<string, number>;
}

export const SyncTable = ({ type, practiceData, issueCount }: SyncTableProps): JSX.Element => {
  const { t } = useTranslation();
  const { country } = useMapContext();

  const headers = [
    t(`fieldFlow.syncErrorModal.headers.Practice`),
    t(`fieldFlow.syncErrorModal.headers.Payment Method`),
    t(`fieldFlow.syncErrorModal.headers.Acreage`),
  ];

  const emptyMessages: Record<typeof type, string> = {
    platform: t(`fieldFlow.syncErrorModal.missingPractices`),
    salesforce: t(`fieldFlow.syncErrorModal.salesforceIsCorrect`),
    currentData: t(`fieldFlow.syncErrorModal.noPracticeOnFields`),
  };
  const tableContent = type === 'salesforce' ? practiceData.filter((error) => error.sf > 0) : practiceData.filter((error) => error.platform > 0);

  function isPaymentError(practice: string) {
    if (!issueCount) return false;
    return issueCount?.[practice] > 1;
  }

  return (
    <Box height={'fit-content'} key={type} width={'100%'} direction='column' style={{ alignItems: 'center' }} margin={'0.5rem 0 1rem 0'}>
      {type !== 'currentData' ? (
        <Text weight={700} size={'1.25rem'} margin={{ bottom: '16px' }}>
          {t(`fieldFlow.syncErrorModal.headers.${type}`)}
        </Text>
      ) : (
        <></>
      )}
      <Box
        justify={'between'}
        border={{ color: '#282828', size: '1px' }}
        style={{ borderRadius: '10px' }}
        width={'100%'}
        margin={'0rem 0rem 0.25rem 0rem'}
      >
        <Box direction={'row'} gap={'8px'} style={{ borderBottom: '1px solid #282828' }} justify={'between'} pad={'1rem 0'}>
          {headers.map((header) => (
            <Box key={header + type} width={country === 'br' ? '50%' : '33.33%'}>
              <Text weight='bold' size='14px' alignSelf={'center'} color='#282828'>
                {header}
              </Text>
            </Box>
          ))}
        </Box>
        <Box data-cy={'sync-error-table'} pad={'0.5rem 0'}>
          {tableContent.length ? (
            <Box data-cy={'sync-error-line'}>{tableContent?.map((error: any) => SyncItem(error, isPaymentError(error.name), type))}</Box>
          ) : (
            <Text data-cy={`empty-${type}-errors`} textAlign={'center'} margin={'8px'}>
              {emptyMessages[type]}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SyncTable;
