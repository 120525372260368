import { Box, Text } from 'grommet';
import { useMapContext } from '../../service/map/MapContext';
import { HerdItem } from './HerdItem';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const HerdStep = () => {
  const { t } = useTranslation();
  const { herds, addHerd, updateHerdData, updateHerd, removeHerd, getLastChangeDate, fields, paddocks, currentVersion, duplicateHerd, state } =
    useMapContext();

  //Reset position of herds inside modified paddocks
  useEffect(() => {
    const herdsWithoutPaddock = herds.map((herd) => {
      if (herd.fieldId === 'OFFSITE') {
        herd.fieldName = 'OFFSITE';
        herd.paddockId = 'OFFSITE';
        herd.paddockName = 'OFFSITE';
        return herd;
      }
      if (herd.fieldId && paddocks[herd.fieldId].length === 1) {
        herd.fieldName = fields.find((field) => field.id === herd.fieldId)?.properties?.name;
        herd.paddockId = paddocks[herd.fieldId][0].id?.toString() ?? undefined;
        herd.paddockName = paddocks[herd.fieldId][0].properties?.name?.toString() ?? undefined;
        return herd;
      }
      if (herd.fieldId && !paddocks[herd.fieldId].some((element) => element.id === herd.paddockId)) {
        herd.fieldName = fields.find((field) => field.id === herd.fieldId)?.properties?.name;
        herd.paddockId = undefined;
        herd.paddockName = undefined;
        return herd;
      }
      return herd;
    });
    updateHerdData([...herdsWithoutPaddock]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box margin={'24px'} gap={'16px'} overflow={'auto'}>
      {herds.map((herd, index) => (
        <HerdItem
          key={index}
          state={state}
          herd={herd}
          duplicateHerd={duplicateHerd}
          updateHerd={updateHerd}
          removeHerd={removeHerd}
          fields={fields}
          paddocks={paddocks}
          currentVersion={currentVersion}
          getLastChangeDate={getLastChangeDate}
        />
      ))}
      <Box
        onClick={() => addHerd()}
        height={{ min: '84px' }}
        align={'center'}
        justify={'center'}
        border={{ size: '1px', color: '#CCCCCC' }}
        style={{
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        <Text size={'16px'} style={{ lineHeight: '20px' }} color={'#282828'} weight={400}>
          {t('fieldFlow.mainWizard.Herds.create')}
        </Text>
      </Box>
    </Box>
  );
};
