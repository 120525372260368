import store from '../../redux';
import { Practice } from '../estimate/interfaces';

const isFieldGrazingOnly = (field) => {
  const practices = field.practices;
  if (practices.length !== 1) {
    return false;
  }
  return practices[0].readableCode === 'Improved grazing';
};

const isOptionB = (field) => {
  return field.details.paymentOption === 'OPTION_B';
};

const getTotalFieldsCarbon = (fields) => {
  return fields.reduce((totalCarbon, field) => {
    const fieldCarbon = field.practices.reduce((totalFieldCarbon, practice) => {
      if (practice.readableCode === 'Nitrogen reduction') {
        return totalFieldCarbon;
      }
      return totalFieldCarbon + practice.defaultMultiplier * field.area;
    }, 0);
    return totalCarbon + fieldCarbon;
  }, 0);
};

const getContractAverageCarbonPerAcres = () => {
  const state = store.getState();
  const totalCarbon = getTotalFieldsCarbon(state.estimate.fields);
  const totalContractArea = state.estimate.fields.reduce((totalArea, field) => {
    return field.area + totalArea;
  }, 0);
  return totalCarbon / totalContractArea;
};

const shouldWarnGrazingCarbon = (field) => {
  if (isFieldGrazingOnly(field) && isOptionB(field)) {
    return getContractAverageCarbonPerAcres() < 0.3;
  }
  return false;
};

const getFieldCarbonAverage = (field) => {
  const state = store.getState();
  const scenarioResult = state.estimate.paymentInfo?.scenarioResults.find((scenarioResult) => scenarioResult.scenarioId === field.id);
  const fieldArea = field.area;
  const practiceResult = scenarioResult?.practiceResult;
  if (!scenarioResult || field.practices.length !== practiceResult.length) {
    return -1;
  }
  return scenarioResult.carbon / fieldArea;
};

const isImprovedGrazingAndAnotherPracticeLessThan0_3 = (field) => {
  const fieldCarbonAverage = getFieldCarbonAverage(field);
  const isImprovedGrazingAndAnotherPractice =
    field.practices.length > 1 && !!field.practices.find((practice: Practice) => practice.readableCode === 'Improved grazing');
  if (fieldCarbonAverage === -1) {
    return false;
  }
  return field.details.paymentOption === 'OPTION_B' && isImprovedGrazingAndAnotherPractice && fieldCarbonAverage <= 0.3;
};
export { shouldWarnGrazingCarbon, isImprovedGrazingAndAnotherPracticeLessThan0_3 };
