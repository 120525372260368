import { useTranslation } from 'react-i18next';
import { Box, Text } from 'grommet';
import ConfirmationModal from './ConfirmationModal';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface SaveAndLeaveModalProps {
  setShowSaveAndLeaveModal: (value: ((prevState: boolean) => boolean) | boolean) => void;
  saveAndLeave: () => void;
}

const SaveAndLeaveModal = ({ setShowSaveAndLeaveModal, saveAndLeave }: SaveAndLeaveModalProps) => {
  const { t } = useTranslation();
  const { brBoundaryApproval } = useFlags();

  return (
    <ConfirmationModal
      dataCy={'save-and-leave-modal'}
      confirm={async () => {
        setShowSaveAndLeaveModal(false);
        saveAndLeave();
      }}
      cancel={() => setShowSaveAndLeaveModal(false)}
      title={t('fieldFlow.saveAndLeaveModal.attention')}
      width={'496px'}
      message={
        <Box>
          <Text weight={700} textAlign={'center'}>
            {t(`fieldFlow.saveAndLeaveModal.notificationWarning${brBoundaryApproval ? 'Neli' : ''}`)}
          </Text>
          <Text weight={700} textAlign={'center'}>
            {t('fieldFlow.saveAndLeaveModal.continue')}
          </Text>
        </Box>
      }
      confirmLabel={t('fieldFlow.saveAndLeaveModal.confirm')}
      cancelLabel={t('fieldFlow.saveAndLeaveModal.cancel')}
    />
  );
};

export default SaveAndLeaveModal;
