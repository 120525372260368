import { Box, Button, Image, Text } from 'grommet';
import herdIcon from '../../../../../../assets/BoundaryTool/infoIcons/herd.svg';
import herdIconRemoved from '../../../../../../assets/BoundaryTool/infoIcons/herd-removed.svg';
import React, { useEffect, useRef, useState } from 'react';
import { FormDown, FormUp, MoreVertical } from 'grommet-icons';
import LevelTag from '../../../Wizard/components/LevelTag';
import HerdOptions from '../HerdOptions';
import MoveEventModal from '../../../Modals/MoveEventModal';
import RemoveHerdModal from '../../../Modals/RemoveHerdModal';
import { formatDate } from '../../../../service/common/utils';
import { HerdItem } from '../../../Herd/HerdItem';
import { useSearchParams } from 'react-router-dom';
import { useMapContext } from '../../../../service/map/MapContext';
import { HerdDetails } from '../../../Herd/HerdDetails';
import Field from '../../../../types/Field';
import HerdHistoryModal from '../../../Modals/HerdHistoryModal/HerdHistoryModal';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface HerdDropdownProps {
  herds: any[];
  disableCreation?: boolean;
}

export const HerdDropdown = ({ herds, disableCreation }: HerdDropdownProps): JSX.Element => {
  const { hideStructuresAndEvents } = useFlags();
  const { t } = useTranslation();
  const {
    paddocks,
    state,
    fields,
    addHerd,
    removeHerd,
    updateHerd,
    saveHerd,
    duplicateHerd,
    getLastChangeDate,
    herdRemovedThisVersion,
    currentVersion,
    editHerd,
  } = useMapContext();
  const [searchParams] = useSearchParams();
  const fieldId = searchParams.get('fieldId');
  const field = fieldId === 'OFFSITE' ? ({ id: 'OFFSITE' } as Field) : fields.find((field) => field.id === fieldId);
  const [expanded, setExpanded] = useState<boolean>(hideStructuresAndEvents);
  const [openOptions, setOpenOptions] = useState<Record<string, boolean>>({});
  const [editingHerds, setEditingHerds] = useState<Record<string, boolean>>({});
  const [showMoveModal, setShowMoveModal] = useState<boolean>(false);
  const [removeHerdModal, setRemoveHerdModal] = useState<boolean>(false);
  const [herdHistoryModal, setHerdHistoryModal] = useState<boolean>(false);
  const [selectedHerd, setSelectedHerd] = useState<any | undefined>();
  const [highlight, setHighlight] = useState<string | undefined>();
  const [savingHerd, setSavingHerd] = useState<boolean>(false);
  const elementsRef = useRef<any>({});

  function getPreviousLocationName(rotations: any) {
    if (!rotations[1]) return '--';
    if (!rotations[1].data.paddockName || rotations[1].data.fieldName === rotations[1].data.paddockName) {
      return rotations[1].data.fieldName;
    }
    return `${rotations[1].data.fieldName} - ${rotations[1].data.paddockName}`;
  }

  useEffect(() => {
    const type = searchParams.get('type');
    const elementId = searchParams.get('elementId');

    if (type && type === 'HERD' && elementId) {
      setExpanded(true);
      setHighlight(elementId);
    } else {
      setHighlight(undefined);
    }
  }, [searchParams]);

  useEffect(() => {
    if (highlight && elementsRef.current[highlight]) {
      elementsRef.current[highlight].scrollIntoView({ behavior: 'smooth' });
    }
  }, [highlight, elementsRef]);

  return (
    <>
      {showMoveModal && (
        <MoveEventModal
          herd={selectedHerd}
          onCancel={() => {
            setShowMoveModal(false);
            setSelectedHerd(undefined);
          }}
        />
      )}
      {removeHerdModal && (
        <RemoveHerdModal
          herd={selectedHerd}
          onCancel={() => {
            setRemoveHerdModal(false);
            setSelectedHerd(undefined);
          }}
        />
      )}
      {herdHistoryModal && (
        <HerdHistoryModal
          herd={selectedHerd}
          onCancel={() => {
            setHerdHistoryModal(false);
            setSelectedHerd(undefined);
          }}
        />
      )}
      <Box style={{ minHeight: 'unset' }}>
        <Box
          onClick={() => {
            if (!hideStructuresAndEvents) setExpanded(!expanded);
          }}
          style={{
            borderRadius: expanded ? '5px 5px 0 0' : '5px',
            border: '1px solid #808080',
            padding: '0.5rem 0.75rem',
            cursor: hideStructuresAndEvents ? 'default' : 'pointer',
          }}
        >
          <Box direction={'row'} justify={'between'}>
            <Box direction={'row'} gap={'0.75rem'} align={'center'}>
              <Image height={'36px'} width={'36px'} src={herdIcon} />
              <Text weight={900} margin={'-0.25rem 0 0 0'} size={'1.25rem'}>
                Herds ({herds.length})
              </Text>
              <LevelTag level={'Paddock'} />
            </Box>
            {!hideStructuresAndEvents ? (
              expanded ? (
                <Box justify={'center'}>
                  <FormUp />
                </Box>
              ) : (
                <Box justify={'center'}>
                  <FormDown />
                </Box>
              )
            ) : null}
          </Box>
        </Box>
        {expanded && (
          <Box
            overflow={'hidden'}
            pad={'1rem'}
            gap={'1rem'}
            style={{ borderTop: 0 }}
            round={'0 0 5px 5px'}
            border={{ color: '#808080', size: '1px', style: 'solid' }}
          >
            {herds.map((herd) => {
              if (typeof herd.id === 'number' || editingHerds[herd.id]) {
                return (
                  <Box key={herd.id}>
                    <HerdItem
                      state={state}
                      duplicateHerd={duplicateHerd}
                      simple={true}
                      herd={herd}
                      editing={editingHerds[herd.id]}
                      fields={[field!]}
                      paddocks={{ [field!.id!]: paddocks[field!.id!] }}
                      currentVersion={currentVersion}
                      updateHerd={updateHerd}
                      removeHerd={() => removeHerd(herd)}
                      getLastChangeDate={getLastChangeDate}
                    />

                    <Box margin={{ top: '19px' }} alignSelf={'end'} align={'end'} direction={'row'} gap={'16px'}>
                      <Button
                        focusIndicator={false}
                        hoverIndicator={false}
                        style={{
                          width: '80px',
                          minHeight: '28px',
                          borderRadius: '0px',
                          border: '1px solid #282828',
                          fontWeight: '800',
                          fontSize: '10px',
                          lineHeight: '12px',
                          textAlign: 'center',
                          padding: '6px 0',
                          backgroundColor: 'white',
                          justifyContent: 'center',
                        }}
                        onClick={() => {
                          if (!editingHerds[herd.id]) {
                            removeHerd(herd);
                          } else {
                            setEditingHerds({ ...editingHerds, [herd.id]: false });
                            herd.changes = undefined;
                            updateHerd(herd);
                          }
                        }}
                        label={t('fieldFlow.cancel')}
                        plain
                      />

                      <Button
                        focusIndicator={false}
                        hoverIndicator={false}
                        disabled={
                          editingHerds[herd.id]
                            ? !herd.changes?.reason || !herd.changes?.newData || (herd.changes?.reason === 'CHANGES' && !herd.changes?.date)
                            : herd.invalid
                        }
                        style={{
                          width: '90px',
                          minHeight: '28px',
                          borderRadius: '0px',
                          fontWeight: '800',
                          fontSize: '10px',
                          lineHeight: '12px',
                          textAlign: 'center',
                          padding: '8px 0',
                          backgroundColor: '#1B7C3D',
                          color: 'white',
                          justifyContent: 'center',
                        }}
                        onClick={async () => {
                          if (savingHerd) return;

                          setSavingHerd(true);
                          if (!editingHerds[herd.id]) {
                            await saveHerd(herd);
                          } else {
                            await editHerd(herd.id, herd.changes);
                            setEditingHerds({ ...editingHerds, [herd.id]: false });
                          }
                          setSavingHerd(false);
                        }}
                        label={savingHerd ? t('fieldFlow.saving') : t('fieldFlow.saveChanges')}
                        plain
                      />
                    </Box>
                  </Box>
                );
              }
              return (
                <Box key={herd.id} round={'5px'} border={{ color: highlight === herd.id ? '#1457A8' : '#CCC', size: '1px', style: 'solid' }}>
                  <Box
                    direction={'row'}
                    justify={'between'}
                    style={{ position: 'relative' }}
                    align={'center'}
                    pad={'0.25rem 0.75rem'}
                    border={{
                      color: highlight === herd.id ? '#1457A8' : '#CCC',
                      size: '1px',
                      style: 'solid',
                      side: 'bottom',
                    }}
                  >
                    <Box direction={'row'} gap={'1rem'} align={'center'}>
                      <Image
                        margin={{ vertical: '0.125rem' }}
                        width={'32px'}
                        height={'32px'}
                        src={herdRemovedThisVersion(herd) ? herdIconRemoved : herdIcon}
                      />
                      <Text size={'1.25rem'} weight={900}>
                        {herd.name}
                      </Text>
                    </Box>
                    <Box direction={'row'} align={'center'} height={'100%'}>
                      {herd.rotation && herd.rotation[0] && (
                        <Text textAlign={'end'} margin={'0 12px 0 0'}>
                          {`${t('fieldFlow.ranchOverview.fieldDetails.herd.movedIn')} ${getPreviousLocationName(herd.rotation)}
                        ${t('fieldFlow.on')} ${formatDate(herd.rotation[0].timestamp).toString()}`}
                        </Text>
                      )}
                      <Box style={{ position: 'relative', overflow: 'visible' }} align={'end'} height={'100%'} justify={'center'}>
                        <MoreVertical
                          height={'24px'}
                          style={{ cursor: 'pointer', top: '8px' }}
                          onClick={() => setOpenOptions({ ...openOptions, [herd.id]: true })}
                        />
                        {openOptions[herd.id] && (
                          <HerdOptions
                            herd={herd}
                            onEditHerd={() => {
                              setEditingHerds({ ...editingHerds, [herd.id]: true });
                            }}
                            onMoveHerd={() => {
                              setSelectedHerd(herd);
                              setShowMoveModal(true);
                            }}
                            onHerdHistory={() => {
                              setSelectedHerd(herd);
                              setHerdHistoryModal(true);
                            }}
                            onDuplicateHerd={() => {
                              duplicateHerd(herd);
                            }}
                            onRemoveHerd={() => {
                              setSelectedHerd(herd);
                              setRemoveHerdModal(true);
                            }}
                            onClose={() => {
                              setOpenOptions({ ...openOptions, [herd.id]: false });
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <HerdDetails herd={herd} />
                </Box>
              );
            })}
            {!disableCreation && (
              <Box
                as={'button'}
                align={'center'}
                onClick={() => addHerd(field?.id?.toString(), field?.properties?.name?.toString())}
                style={{
                  cursor: 'pointer',
                  display: typeof herds[herds.length - 1]?.id === 'number' ? 'none' : 'flex',
                }}
                justify={'center'}
                height={'84px'}
                background={'#FFF'}
                round={'5px'}
                border={{ color: '#CCC', size: '1px', style: 'solid' }}
              >
                <Text size={'1rem'} weight={400}>
                  {t('fieldFlow.mainWizard.Herds.create')}
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default HerdDropdown;
