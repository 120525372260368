import { API } from '../common/api';
import { FIELD_URL } from '../common/env';

const getFields = async (journeyId: string) => {
  const limit = 70;

  let finalFields = [];
  let initialLength = 0,
    finalLength = limit;

  while (finalLength - initialLength === limit) {
    initialLength = finalFields.length;

    const result = await API.get(`${FIELD_URL}/field-service/fields`, {
      journeyId,
      limit,
      offset: finalFields.length,
    });

    finalFields = finalFields.concat(result.data);
    finalLength = finalFields.length;
  }

  return finalFields;
};

export const FieldService = {
  getFields,
};
