import { Box, Button, Heading, Layer, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { useMapContext } from '../../service/map/MapContext';

interface EstimateErrorModalProps {
  setShowEstimatesModal: (value: ((prevState: boolean) => boolean) | boolean) => void;
}

const EstimateErrorModal = ({ setShowEstimatesModal }: EstimateErrorModalProps) => {
  const { t } = useTranslation();
  const { estimate, journey, getEstimate } = useMapContext();

  function cancel() {
    getEstimate();
    setShowEstimatesModal(false);
  }

  return (
    <Layer background={{ opacity: 0.7 }} onEsc={() => cancel()} onClickOutside={() => cancel()}>
      <Box
        data-cy={'estimate-error-modal'}
        background={'white'}
        direction={'column'}
        alignSelf={'center'}
        pad={'24px'}
        className={'hideScrollBox'}
        overflow={'auto'}
        style={{ borderRadius: '1rem', display: 'unset', width: '500px', maxHeight: '95vh' }}
        align={'center'}
        justify={'center'}
      >
        <Heading fill margin={'0'} style={{ textAlign: 'center', fontWeight: 700 }}>
          {t('fieldFlow.estimateErrorModal.title')}
        </Heading>
        <Box margin='1.5rem 1.5rem 1.5rem 1.5rem' gap={'0.5rem'}>
          <Text size='16px' alignSelf='center' color='#282828' textAlign={'center'} weight={400}>
            {t('fieldFlow.estimateErrorModal.message.first')}
          </Text>
          <Text size='16px' alignSelf='center' color='#282828' textAlign={'center'} weight={700}>
            {t('fieldFlow.estimateErrorModal.message.second')}
          </Text>
        </Box>
        <Box direction={'row'} gap={'8px'} justify={'between'} style={{ justifyContent: 'end', marginTop: '1.5rem' }}>
          <Button
            data-cy={'update-estimate-button'}
            onClick={() => {
              window.open(`${process.env['REACT_APP_PLATFORM_BASE_URL']}/estimator/${journey?.id}?estimateId=${estimate.id}`);
            }}
            style={{ width: '100%', backgroundColor: '#FFE137', padding: '10px', textAlign: 'center' }}
          >
            <Text weight={600} size={'1rem'} style={{ lineHeight: 'unset' }}>
              {t('fieldFlow.estimateErrorModal.button')}
            </Text>
          </Button>
        </Box>
      </Box>
    </Layer>
  );
};

export default EstimateErrorModal;
