import { Box, Button, Layer, Text } from 'grommet';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const DeleteField = ({ deleteAllFields, onCancel, onDelete }: { deleteAllFields: boolean; onCancel: () => void; onDelete: () => void }) => {
  const { t } = useTranslation();

  return (
    <Layer onClickOutside={onCancel} onEsc={onCancel} position='center' style={{ borderRadius: '12px' }}>
      <Box pad='24px' width='400px' align={'center'}>
        <Text size='18px' color='#282828' weight={800} style={{ lineHeight: '28px' }}>
          {t('fieldFlow.deleteFieldModal.title.' + (deleteAllFields ? 'all' : 'single'))}
        </Text>
        <Text
          margin={{ top: '8px' }}
          textAlign={'center'}
          size='14px'
          color='#494949'
          weight={400}
          style={{ lineHeight: '21px', whiteSpace: 'pre-line' }}
        >
          {t('fieldFlow.deleteFieldModal.message.' + (deleteAllFields ? 'all' : 'single'))}
        </Text>

        <Box margin={{ top: '24px' }} gap='8px' direction='row-responsive'>
          <Button
            data-cy={'cancel-delete-field-button'}
            plain
            focusIndicator={false}
            label={t('fieldFlow.deleteFieldModal.cancel')}
            onClick={onCancel}
            style={{
              borderRadius: '0px',
              border: '1px solid #282828',
              fontWeight: '800',
              fontSize: '16px',
              padding: '8px 0',
              width: '172px',
              textAlign: 'center',
            }}
          />
          <Button
            data-cy={'confirm-delete-field-button'}
            focusIndicator={false}
            hoverIndicator={false}
            style={{
              borderRadius: '0px',
              fontWeight: '800',
              fontSize: '16px',
              padding: '8px 0',
              width: '172px',
              textAlign: 'center',
              backgroundColor: '#D03450',
            }}
            label={t('fieldFlow.deleteFieldModal.delete')}
            onClick={onDelete}
            plain
            color={'#FFFFFF'}
          />
        </Box>
      </Box>
    </Layer>
  );
};
