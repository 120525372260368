import React, { useState } from 'react';
import { SearchInputWithOptions } from './SearchInputWithOptions';
import { AddressSearchService, MapSearchResultFeature, MapSearchResult } from '../../service/AddressSearchService';
import { useTranslation } from 'react-i18next';

export type HeaderSearchType = {
  initialValue?: string;
  hint?: string;
  searchDataTypes?: Array<string>;
  setSearchInput?: (value: ((prevState: string) => string) | string) => void;
  addressSelectedNameCallback?: (value: ((prevState: string) => string) | string, stateShortCode: string) => void;
  addressSelectedBBoxCallback?: (position: number[]) => void;
  country: string;
};

export const AddressSearchComponent = ({
  initialValue,
  hint,
  searchDataTypes,
  setSearchInput,
  addressSelectedNameCallback,
  addressSelectedBBoxCallback,
  country,
}: HeaderSearchType): JSX.Element => {
  const { t } = useTranslation();
  const searchAddress = async (params: string): Promise<MapSearchResult | undefined> => {
    return AddressSearchService.searchAddress(params, searchDataTypes, true, `&country=${country}`);
  };
  const [args, setArgs] = useState<{
    options: MapSearchResultFeature[] | undefined;
    hint: string;
  }>({
    options: [],
    hint: hint ?? t('fieldFlow.addressSearch'),
  });

  const inputCallback = async (value: string) => {
    const result = await searchAddress(value);
    setArgs((state) => {
      const tempAddressResult = { ...state };
      tempAddressResult.options = result?.features;
      return tempAddressResult;
    });
  };
  const onSelectOptionCallback = (bbox: number[], placeName: string, context: any[]) => {
    if (addressSelectedNameCallback) {
      const stateShortCode =
        context
          .find((item) => item.id.indexOf('region') !== -1)
          ?.short_code.split('-')
          .pop() ?? '';
      addressSelectedNameCallback(placeName, stateShortCode);
    }
    if (addressSelectedBBoxCallback) addressSelectedBBoxCallback(bbox);
  };
  return (
    <SearchInputWithOptions
      {...args}
      initialValue={initialValue}
      setSearchInput={setSearchInput}
      inputCallback={inputCallback}
      onSelectOptionCallback={onSelectOptionCallback}
    />
  );
};
