import { LeadService } from '../../service/leads/lead.service';

export const getActiveCampaigns: any = () => (dispatch: any) => {
  dispatch({
    type: 'GET_ACTIVE_CAMPAIGNS_REQUESTED',
  });
  return LeadService.getActiveCampaigns()
    .then((res: any) => {
      dispatch({
        type: 'GET_ACTIVE_CAMPAIGNS_SUCCEEDED',
        payload: res.data,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_ACTIVE_CAMPAIGNS_ERROR',
        payload: err,
      });
    });
};

export const getHowDidYouHearOptions: any = () => (dispatch: any) => {
  dispatch({
    type: 'GET_HOW_DID_YOU_HEAR_OPTIONS_REQUESTED',
  });
  return LeadService.getHowDidYouHearOptions()
    .then((res: any) => {
      dispatch({
        type: 'GET_HOW_DID_YOU_HEAR_OPTIONS_SUCCEEDED',
        payload: res,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_HOW_DID_YOU_HEAR_OPTIONS_ERROR',
        payload: err,
      });
    });
};

export const getRepresentativeOptions: any = () => (dispatch: any) => {
  dispatch({
    type: 'GET_REPRESENTATIVE_OPTIONS_REQUESTED',
  });
  return LeadService.getRepresentativeOptions()
    .then((res: any) => {
      dispatch({
        type: 'GET_REPRESENTATIVE_OPTIONS_SUCCEEDED',
        payload: res,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_REPRESENTATIVE_OPTIONS_ERROR',
        payload: err,
      });
    });
};
