import { cloneDeep } from 'lodash';
import { AnyAction } from 'redux';

const initialState: {
  fields: any[];
  estimateFromDb: any;
  calculateState: any;
  paymentInfo: any;
  optionAPaymentInfo: any;
  optionBPaymentInfo: any;
  duplicateFieldInfo: any;
  availablePractices: any[];
  availablePracticesCountry: any;
  estimateState: any;
  updateEstimateState: any;
  currentRequestId;
  brazilSpecies: any[];
  iclFactor: { [key: string]: number };
} = {
  availablePractices: [],
  availablePracticesCountry: null,
  fields: [],
  duplicateFieldInfo: null,
  estimateFromDb: null,
  calculateState: 'NONE',
  paymentInfo: null,
  optionAPaymentInfo: null,
  optionBPaymentInfo: null,
  estimateState: { lifeCycle: 'NONE' },
  updateEstimateState: { lifeCycle: 'NONE' },
  currentRequestId: null,
  brazilSpecies: [],
  iclFactor: {},
};

const getUpdatedPractices = (allPractices: any[], practiceId: string, updatedPractice: any) => {
  //Remove practice
  if (updatedPractice === null) {
    const filteredPractices = allPractices.filter((practice: any) => practice.id !== practiceId);
    if (filteredPractices.length === 1 && filteredPractices[0].readableCode === 'Inoculant') {
      return [];
    }
    return filteredPractices;
  }
  if (practiceId === null) {
    if (updatedPractice.readableCode === 'ICL') {
      const inoculant = allPractices.find((p) => p.readableCode === 'Inoculant');
      return inoculant ? [inoculant, updatedPractice] : [updatedPractice];
    }
    return allPractices.concat(updatedPractice);
  }
  return allPractices.map((practice) => {
    if (practice.id === updatedPractice.id) {
      return updatedPractice;
    }
    return practice;
  });
};

const sortFieldsComparator = (field1: any, field2: any) => {
  return field1.name.localeCompare(field2.name);
};

const mergePractices = (newFields: any[], existingField: any) => {
  const fieldToMerge = newFields.find((newField: any) => newField.id === existingField.id);
  if (!fieldToMerge) {
    return existingField;
  }
  existingField.practices = existingField.practices.map((existingPractice: any) => {
    const practiceToMerge = fieldToMerge.practices.find((practice: any) => practice.id === existingPractice.id);
    if (!practiceToMerge) {
      return existingPractice;
    }
    if (practiceToMerge.type === 'PASTURELAND') {
      existingPractice.details = practiceToMerge.details;
      existingPractice.defaultMultiplier = practiceToMerge.defaultMultiplier;
      return existingPractice;
    }
    if (practiceToMerge.type === 'ROW_CROP') {
      existingPractice.defaultMultiplier = practiceToMerge.defaultMultiplier;
      return existingPractice;
    }
    return existingPractice;
  });
  return existingField;
};

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'GET_ESTIMATE_REQUESTED': {
      return {
        ...state,
        estimateState: { lifeCycle: 'REQUESTED' },
      };
    }
    case 'GET_ESTIMATE_SUCCEEDED': {
      if (!action.payload) {
        return {
          ...state,
          estimateState: { lifeCycle: 'SUCCEEDED' },
        };
      }
      return {
        ...state,
        estimateState: { lifeCycle: 'SUCCEEDED' },
        paymentInfo: action.payload?.paymentInfo || null,
        estimateFromDb: action.payload.currentEstimate,
        fields: action.payload.currentEstimate?.data.sort(sortFieldsComparator) || [],
        optionAPaymentInfo: action.payload.optionAPaymentInfo,
        optionBPaymentInfo: action.payload.optionBPaymentInfo,
      };
    }
    case 'GET_ESTIMATE_ERROR': {
      return {
        ...state,
        estimateState: { lifeCycleStage: 'ERROR' },
      };
    }
    case 'ESTIMATE_PAGE_DISMOUNTED': {
      return {
        ...state,
        paymentInfo: null,
        estimateFromDb: null,
        fields: [],
        optionAPaymentInfo: null,
        optionBPaymentInfo: null,
        estimateState: { lifeCycle: 'NONE' },
        duplicateFieldInfo: null,
      };
    }
    case 'CALCULATE_SUCCEEDED': {
      return {
        ...state,
        calculateState: 'SUCCEEDED',
        paymentInfo: action.payload.calculatorResultCombined,
        optionAPaymentInfo: action.payload.calculatorResultA,
        optionBPaymentInfo: action.payload.calculatorResultB,
      };
    }
    case 'CALCULATE_FAILED': {
      return {
        ...state,
        calculateState: 'FAILED',
        updateEstimateState: { lifeCycle: 'FAILED' },
        paymentInfo: action.payload?.calculatorResultCombined,
        optionAPaymentInfo: action.payload?.calculatorResultA,
        optionBPaymentInfo: action.payload?.calculatorResultB,
      };
    }
    case 'SAVE_SCENARIOS_REQUESTED': {
      return {
        ...state,
        currentRequestId: action.payload.currentRequestId,
      };
    }
    case 'SAVE_SCENARIOS_SUCCEEDED': {
      if (state.currentRequestId !== action.payload.currentRequestId) {
        return { ...state }; // ignore changes if another request is on the way
      }
      return {
        ...state,
        fields: state.fields.map((existingField: any) => mergePractices(action.payload.data, existingField)),
        updateEstimateState: { lifeCycle: 'SUCCEEDED' },
        estimateFromDb: {
          ...state.estimateFromDb,
          ...action.payload,
          notes: state.estimateFromDb?.notes || action.payload.notes,
        },
      };
    }
    case 'SAVE_ESTIMATE_SUCCEEDED': {
      return {
        ...state,
        fields: state.fields.map((existingField: any) => mergePractices(action.payload.data, existingField)),
        estimateFromDb: action.payload,
      };
    }
    case 'UPDATE_ESTIMATE_NAME_REQUESTED': {
      return {
        ...state,
        estimateFromDb: {
          ...state.estimateFromDb,
          name: action.payload,
        },
      };
    }
    case 'UPDATE_ESTIMATE_NAME_SUCCEEDED': {
      return {
        ...state,
        estimateFromDb: action.payload,
      };
    }
    case 'UPDATE_ESTIMATE_NOTES_REQUESTED': {
      return {
        ...state,
        estimateFromDb: {
          ...state.estimateFromDb,
          notes: action.payload,
        },
      };
    }
    case 'UPDATE_PRACTICE_REQUESTED': {
      const scenarioToUpdate = state.fields.find((field) => field.id === action.payload.fieldId);
      scenarioToUpdate.practices = getUpdatedPractices(scenarioToUpdate.practices, action.payload.practiceId, cloneDeep(action.payload.practice));
      return {
        ...state,
        updateEstimateState: { lifeCycle: 'REQUESTED' },
        fields: state.fields.map((field) => {
          if (field.id === scenarioToUpdate.id) {
            if (field.practiceType === 'PASTURELAND' && field.version === 'V2') {
              const specie =
                field.practices.find((practice) => practice.readableCode === 'Biodiversity/Seeding')?.details.species ?? field.baseline.species;
              const fertilizer =
                field.practices.find((practice) => practice.readableCode === 'Fertilization')?.details.fertilizer ?? field.baseline.fertilizer;
              const dryMatterFound = state.brazilSpecies.find((dryMatter) => dryMatter.species === specie && dryMatter.input === fertilizer);
              if (dryMatterFound) {
                scenarioToUpdate.details.dryMatter = dryMatterFound.dryMatter;
              }
            }
            return scenarioToUpdate;
          }
          return field;
        }),
      };
    }
    case 'SAVE_FIELD_REQUESTED': {
      const newScenarios = state.fields.filter((field) => field.id !== action.payload.field.id).concat(action.payload.field);
      return {
        ...state,
        updateEstimateState: { lifeCycle: 'REQUESTED' },
        fields: newScenarios.sort(sortFieldsComparator),
      };
    }
    case 'DELETE_FIELD_REQUESTED': {
      const newScenarios = state.fields.filter((field) => field.id !== action.payload.field.id);
      return {
        ...state,
        updateEstimateState: { lifeCycle: 'REQUESTED' },
        fields: newScenarios.sort(sortFieldsComparator),
      };
    }
    case 'CLEAR_FIELD_REQUESTED': {
      return {
        ...state,
        updateEstimateState: { lifeCycle: 'REQUESTED' },
        fields: [],
      };
    }
    case 'AVAILABLE_PRACTICES_SUCCEEDED': {
      return {
        ...state,
        availablePractices: action.payload.practices,
        availablePracticesCountry: action.payload.country,
      };
    }
    case 'APPLY_PRACTICES_TO_ALL_SCENARIOS_REQUESTED': {
      return {
        ...state,
        fields: action.payload,
      };
    }
    case 'APPLY_CREDIT_ESTIMATE_TO_ALL_SCENARIOS_REQUESTED': {
      return {
        ...state,
        fields: action.payload,
      };
    }
    case 'COPY_FIELDS_FROM_BOUNDARY_REQUESTED': {
      return {
        ...state,
        fields: action.payload,
      };
    }
    case 'DUPLICATE_FIELD_REQUESTED': {
      return {
        ...state,
        duplicateFieldInfo: {
          ...action.payload.field,
          id: null,
          name: null,
          practices: [],
        },
      };
    }

    case 'DUPLICATE_FIELD_PROCESSED': {
      return {
        ...state,
        duplicateFieldInfo: null,
      };
    }

    case 'GET_BRAZIL_SPECIES_REQUESTED': {
      return {
        ...state,
        brazilSpeciesLifecycle: 'PENDING',
      };
    }

    case 'GET_BRAZIL_SPECIES_SUCCEEDED': {
      return {
        ...state,
        brazilSpeciesLifecycle: 'SUCCEEDED',
        brazilSpecies: action.payload,
      };
    }

    case 'GET_ICL_FACTOR_REQUESTED': {
      return {
        ...state,
        iclFactorLifecycle: 'PENDING',
      };
    }

    case 'GET_ICL_FACTOR_SUCCEEDED': {
      return {
        ...state,
        iclFactorLifecycle: 'SUCCEEDED',
        iclFactor: action.payload,
      };
    }
  }
  return state;
};

export default reducer;
