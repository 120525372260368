import AgoToast from '../../components/Toast/AgoToast';
import { ProducerService } from '../../service/producers/producer.service';

export const getProducerListAction: any = () => (dispatch: any) => {
  dispatch({
    type: 'GET_PRODUCER_LIST_REQUESTED',
  });
  ProducerService.getCycleStatuses()
    .then((res: any) => {
      dispatch({
        type: 'GET_CYCLE_STATUSES_SUCCEEDED',
        payload: res,
      });
    })
    .catch((e) => {
      dispatch({
        type: 'GET_CYCLE_STATUSES_ERRORED',
      });
    });
  return ProducerService.getProducerList()
    .then((res: any) => {
      dispatch({
        type: 'GET_PRODUCER_LIST_SUCCEEDED',
        payload: res,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_PRODUCER_LIST_ERROR',
        payload: err,
      });
      AgoToast.showToast({
        title: 'Error loading Producers',
        message: err.message,
        type: 'error',
        toastId: 'lead-request',
      });
    });
};

export const getProducerByIdAction: any = (growerId: string) => (dispatch: any) => {
  dispatch({
    type: 'GET_PRODUCER_BY_ID_REQUESTED',
  });
  return ProducerService.getProducerById(growerId)
    .then((res: any) => {
      dispatch({
        type: 'GET_PRODUCER_BY_ID_SUCCEEDED',
        payload: res,
      });
      return res;
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_PRODUCER_BY_ID_ERROR',
        payload: err,
      });
      AgoToast.showToast({
        title: 'Error getting producer information',
        message: err.message,
        type: 'error',
        toastId: 'lead-request',
      });
    });
};

export const getJourneyByIdAction: any = (journeyId: string) => (dispatch: any) => {
  dispatch({
    type: 'GET_JOURNEY_BY_ID_REQUESTED',
  });
  return ProducerService.getJourneyById(journeyId)
    .then((res: any) => {
      dispatch({
        type: 'GET_JOURNEY_BY_ID_SUCCEEDED',
        payload: res,
      });
      return res;
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_JOURNEY_BY_ID_ERROR',
        payload: err,
      });
      AgoToast.showToast({
        title: 'Error getting journey information',
        message: err.message,
        type: 'error',
        toastId: 'journey-request',
      });
    });
};

export const clearProducerStore = () => (dispatch: any) => {
  dispatch({
    type: 'CLEAR_PRODUCER_STORE',
  });
};
