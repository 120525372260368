import styled from 'styled-components';
import { Box } from 'grommet';

export const HeaderBox = styled(Box)`
  min-height: unset;
  width: 100%;
  height: 56px;
  left: 0px;
  top: 1px;
  box-shadow: 0px 1px 0px #e9e9eb;
  background: ${(props) => props.theme.global.colors.light};
`;
