import { Box, Button, Layer, Text, RadioButtonGroup, CheckBox } from 'grommet';
import { CSSProperties, useEffect, useState } from 'react';
import { useMapContext } from '../../../service/map/MapContext';
import { DateInput } from '../../Input/DateInput';
import { TextInput } from '../../Input/TextInput';
import { addDays, formatDate } from '../../../service/common/utils';
import DateRangeList from './components/DateRangeList';
import DateRangeExample from './components/DateRangeExample';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Step } from '../../../types/Step';

interface DateRangeModalProps {
  steps: Step[];
  stepIndex: number;
  setStepIndex: (value: ((prevState: number) => number) | number) => void;
  setShowMeHowModal: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setDateRangeModal: (value: ((prevState: boolean) => boolean) | boolean) => void;
}

export const DateRangeModal = ({ steps, stepIndex, setStepIndex, setDateRangeModal, setShowMeHowModal }: DateRangeModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { layouts, setCurrentVersion, setWizardStep, setState, invalidSteps } = useMapContext();
  const [tempStartDate, setTempStartDate] = useState<Date | undefined>();
  const [tempEndDate, setTempEndDate] = useState<Date | undefined>();
  const [newLayoutType, setLayoutType] = useState('before');
  const [hasCurrent, setHasCurrent] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [versionName, setVersionName] = useState<string>('');
  const [dateError, setDateError] = useState(false);
  const [checked, setChecked] = useState(true);
  const disabledAfter = newLayoutType === 'before' || checked;
  const disabledInput: CSSProperties = { opacity: 0.5, pointerEvents: 'none' };
  const isSecondSetup = layouts && layouts.length > 0;

  useEffect(() => {
    if (layouts) {
      setNameError(layouts.some((item) => item.name === versionName));
    }
  }, [versionName, layouts]);

  useEffect(() => {
    if (!layouts || layouts.length === 0) {
      setLayoutType('first');
      return;
    }
    const lastIndex = layouts.length - 1;
    const hasCurrent = !!layouts[0].endDate;
    setHasCurrent(hasCurrent);
    if (newLayoutType === 'before') {
      setTempStartDate(undefined);
      setTempEndDate(new Date(addDays(layouts[lastIndex].startDate, -1)));
    } else {
      setTempStartDate(hasCurrent ? new Date(addDays(layouts[0].endDate, 1)) : undefined);
      setTempEndDate(undefined);
    }
  }, [newLayoutType, layouts]);

  useEffect(() => {
    if (tempStartDate) setDateError(!!tempEndDate && tempEndDate < tempStartDate);
  }, [tempStartDate, tempEndDate]);

  function stepBack() {
    if (stepIndex === steps.findIndex((step) => step.name === 'Paddocks') && invalidSteps(stepIndex)) {
      return;
    }

    if (stepIndex === 0) return navigate(-1);

    const ignorePrevious = steps[stepIndex - 1].ignoreStep ? 1 : 0;
    if (ignorePrevious && stepIndex === 1) {
      setWizardStep(undefined);
      setCurrentVersion(layouts?.length ? layouts[0] : undefined);
      setState('OVERVIEW');
      return;
    }

    setStepIndex((stepIndex) => stepIndex - 1 - ignorePrevious);
    if (stepIndex - 1 === 1) {
      setWizardStep('PADDOCK');
    } else if (stepIndex - 1 === 0) {
      setWizardStep('FIELD');
    } else {
      setWizardStep(undefined);
    }
  }

  return (
    <Layer position='center' style={{ borderRadius: '12px' }}>
      <Box pad='24px' width='500px' style={{ maxHeight: '700px' }}>
        {isSecondSetup ? <DateRangeList dateRanges={layouts!} /> : <DateRangeExample />}
        <Box margin={{ bottom: '0.75rem' }}>
          <Text textAlign={'justify'} size={'1rem'}>
            <Trans i18nKey={'fieldFlow.dateRangeModal.message'} />
          </Text>
          {isSecondSetup && (
            <Text textAlign={'justify'} size={'1rem'}>
              {t('fieldFlow.dateRangeModal.secondSetup.message')}
            </Text>
          )}
        </Box>
        <TextInput label={t('fieldFlow.dateRangeModal.name')} width={'100%'} value={versionName} onChange={(newName) => setVersionName(newName)} />
        {nameError && (
          <Text textAlign={'justify'} size={'0.75rem'} color='red' margin={{ top: '.25rem' }}>
            {t('fieldFlow.dateRangeModal.errors.sameName')}
          </Text>
        )}
        {isSecondSetup && (
          <>
            <Text size={'16px'} style={{ whiteSpace: 'nowrap' }} color={'#282828'} weight={700} margin={{ top: '1rem' }}>
              {t('fieldFlow.dateRangeModal.layoutTimeframe')}
            </Text>
            <RadioButtonGroup
              disabled={!hasCurrent}
              value={newLayoutType}
              onChange={(event) => {
                setLayoutType(event.target.value);
              }}
              margin={{ top: '0.5rem' }}
              direction='row'
              name={'hg'}
              options={[
                {
                  label: (
                    <Text margin={{ right: '5rem' }}>
                      {t('fieldFlow.dateRangeModal.before')}
                      <strong>{formatDate(layouts[layouts?.length - 1].startDate)}</strong>
                    </Text>
                  ),
                  value: 'before',
                },
                {
                  label: (
                    <Text>
                      {t('fieldFlow.dateRangeModal.after')}
                      <strong>{layouts[0]?.endDate ? formatDate(layouts[0].endDate) : formatDate(new Date())}</strong>
                    </Text>
                  ),
                  value: 'after',
                },
              ]}
            />
          </>
        )}
        <Box width={'100%'} direction={'row'} margin={'1.25rem 0 0 0'}>
          <Box margin={{ right: '0.75rem' }} width={'100%'} style={newLayoutType === 'after' && hasCurrent ? disabledInput : undefined}>
            <DateInput
              max={tempEndDate ? addDays(tempEndDate, -1) : new Date()}
              label={t('fieldFlow.dateRangeModal.startDate')}
              width={'100%'}
              value={tempStartDate}
              error={dateError}
              onChange={(value) => setTempStartDate(value as Date)}
            />
          </Box>
          <Box width={'100%'}>
            <Box justify={'between'} direction={'row'} margin={{ bottom: '0.25rem' }}>
              <Text
                size={'16px'}
                style={{ lineHeight: '20px', whiteSpace: 'nowrap', opacity: disabledAfter ? 0.5 : 1 }}
                color={'#282828'}
                weight={700}
              >
                {t('fieldFlow.dateRangeModal.endDate')}
              </Text>
              <Box style={newLayoutType === 'before' ? { display: 'none' } : { alignSelf: 'end' }}>
                <CheckBox
                  toggle
                  checked={checked}
                  label={t('fieldFlow.dateRangeModal.current')}
                  onChange={(event) => {
                    setTempEndDate(undefined);
                    setChecked(event.target.checked);
                  }}
                />
              </Box>
            </Box>
            <Box style={disabledAfter ? disabledInput : undefined}>
              <DateInput
                min={tempStartDate ? addDays(tempStartDate, 1) : undefined}
                max={new Date()}
                width={'100%'}
                value={tempEndDate}
                error={dateError}
                onChange={(value) => setTempEndDate(value as Date)}
              />
            </Box>
          </Box>
        </Box>
        {dateError && (
          <Text textAlign={'justify'} size={'0.75rem'} color='red' margin={{ top: '.25rem' }}>
            {t('fieldFlow.dateRangeModal.errors.startBeforeEnd')}
          </Text>
        )}
        <Box direction={'row'} justify={'between'} margin={'1rem 0 0 0'} gap={'0.75rem'}>
          <Button
            plain
            focusIndicator={false}
            label={t('fieldFlow.dateRangeModal.back')}
            style={{
              borderRadius: '0px',
              border: '1px solid #282828',
              textAlign: 'center',
              width: '100%',
              fontWeight: '800',
              fontSize: '16px',
              padding: '8px 0',
              justifyContent: 'center',
            }}
            onClick={() => {
              if (stepBack) stepBack();
              setDateRangeModal(false);
            }}
          />
          <Button
            focusIndicator={false}
            hoverIndicator={false}
            disabled={!versionName || !tempStartDate || nameError || dateError}
            style={{
              borderRadius: '0px',
              fontWeight: '800',
              fontSize: '16px',
              textAlign: 'center',
              width: '100%',
              padding: '8px 0',
              backgroundColor: '#FFE137',
              justifyContent: 'center',
            }}
            onClick={() => {
              if (tempStartDate && (tempEndDate || checked)) {
                const startDate = new Date(tempStartDate);
                const endDate = tempEndDate && new Date(tempEndDate);
                setCurrentVersion({ name: versionName, startDate, endDate });
                setDateRangeModal(false);
                setShowMeHowModal(!isSecondSetup);
              }
            }}
            label={t('fieldFlow.dateRangeModal.proceed')}
            plain
          />
        </Box>
      </Box>
    </Layer>
  );
};

export default DateRangeModal;
