import { Box } from 'grommet';
import { DetailsInfo } from '../../../DetailsInfo';
import { formatDate } from '../../../../../../service/common/utils';
import { getEventTypeDictionary } from '../../../../../Events/EventItem';
import { useTranslation } from 'react-i18next';
const EventInfo = ({ id, event }: { id: string; event: any }): JSX.Element => {
  const { t } = useTranslation();
  const shownData = selectShownData(event);
  const keyList = Object.keys(shownData);

  function selectShownData(event: any) {
    const EventTypeDictionary = getEventTypeDictionary(t);
    event.data = event.data || {};
    const eventDisplays = {
      FEEDING: {
        'Event Type': EventTypeDictionary[event.type as keyof typeof EventTypeDictionary],
        'Sub Type': event.data?.subType,
        'Total Amount': `${event.data?.amount} lbs`,
        'Start Date': formatDate(event.data?.startDate),
        'End Date': formatDate(event.data?.endDate),
      },
      SUPPLEMENTING: {
        'Event Type': EventTypeDictionary[event.type as keyof typeof EventTypeDictionary],
        'Sub Type': event.data?.subType,
        'Total Amount': `${event.data?.amount} lbs`,
        'Start Date': formatDate(event.data?.startDate),
        'End Date': formatDate(event.data?.endDate),
      },
      HAYING: {
        'Event Type': EventTypeDictionary[event.type as keyof typeof EventTypeDictionary],
        'Total Amount': `${event.data?.amount} lbs`,
        'Start Date': formatDate(event.data?.startDate),
        'End Date': formatDate(event.data?.endDate),
      },
      FIRE: {
        'Event Type': EventTypeDictionary[event.type as keyof typeof EventTypeDictionary],
        'Sub Type': event.data?.subType,
        'Acres burned': event.data?.acresBurned,
        'Month Burn Occurred': event.data?.monthBurnOccurred,
        'Year Burn Occurred': event.data?.yearBurnOccurred,
      },
    };
    return eventDisplays[event.type as keyof typeof eventDisplays];
  }

  return (
    <Box direction={'row'} align={'center'} gap={'1.125rem'} pad={{ horizontal: '0.75rem', bottom: '0.75rem' }} wrap={true}>
      {keyList.map((key) => {
        return <DetailsInfo key={id + key} label={t(`fieldFlow.eventItem.eventInfo.${key}`)} value={shownData[key as keyof typeof shownData]} />;
      })}
    </Box>
  );
};

export default EventInfo;
