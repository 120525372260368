import { Box, Text, Select as SelectGrommet } from 'grommet';

interface SelectProps {
  label: string;
  value: string;
  width?: string;
  titleWeight?: number;
  options: string[] | object[];
  labelKey?: string | ((...args: any[]) => string);
  handleEvent?: boolean;
  onChange: (value: any) => void;
  disabled?: boolean;
}

export const Select = ({ label, titleWeight, width, value, options, labelKey, handleEvent, onChange, disabled }: SelectProps) => {
  return (
    <Box width={width ?? '24.2%'} height={'44px'} direction={'column'}>
      <Text size={'16px'} style={{ lineHeight: '20px' }} color={'#282828'} weight={titleWeight ?? 700}>
        {label}
      </Text>
      {disabled ? (
        <Text size={'16px'} style={{ lineHeight: '16px', whiteSpace: 'nowrap' }} color={'#282828'} weight={400}>
          {value && value.length ? value : '--'}
        </Text>
      ) : (
        <SelectGrommet
          plain
          focusIndicator={false}
          size={'16px'}
          height={'24px'}
          labelKey={labelKey}
          style={{ lineHeight: '16px', padding: '4px', borderRadius: '0', fontWeight: '400' }}
          value={value}
          options={options}
          onChange={(event) => {
            handleEvent ? onChange(event) : onChange(event.target.value);
          }}
        />
      )}
    </Box>
  );
};
