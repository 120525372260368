import { Box, Text, Tip } from 'grommet';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

interface TooltipProps {
  label: string | JSX.Element;
  children: React.ReactNode;
  showToolTip?: boolean;
  dropProps?: any;
}

export const StyledTooltip = ({ label, children, showToolTip, dropProps = { align: { top: 'bottom' } } }: TooltipProps): JSX.Element => {
  if (!showToolTip) return <>{children}</>;
  return (
    <Tip
      plain
      key={uuidv4()}
      dropProps={dropProps}
      content={
        <Box direction={'column'} justify={'center'} margin={'0 auto'}>
          <Box round={'0.25rem'} pad={'0.25rem 0.5rem'} height={'fit-content'} justify={'center'} background={{ color: '#282828' }}>
            <Text color={'#FFFFFF'} size={'0.75rem'} textAlign={'center'}>
              {label}
            </Text>
          </Box>
          <Box
            margin={'0 auto'}
            style={{
              width: 0,
              height: 0,
              borderLeft: '7.5px solid transparent',
              borderRight: '7.5px solid transparent',
              borderBottom: dropProps?.align?.top === 'bottom' ? '10px solid #282828' : undefined,
              borderTop: dropProps?.align?.bottom === 'top' ? '10px solid #282828' : undefined,
            }}
          />
        </Box>
      }
    >
      {children}
    </Tip>
  );
};
