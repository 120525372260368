import { createContext } from 'react';
import { IAuthState, initialAuthState } from './CognitoReducer';

export interface ICognitoContext extends IAuthState {
  login: () => void;
  logout: () => void;
  getTokensOrLogin: () => void;
  getLoggedUserFromCognito: () => void;
  refreshSessionSilently: () => void;
}

const notProvided = (): never => {
  throw new Error('Function not provided on context wrapper');
};

const initialContext: ICognitoContext = {
  ...initialAuthState,
  login: notProvided,
  logout: notProvided,
  getTokensOrLogin: notProvided,
  getLoggedUserFromCognito: notProvided,
  refreshSessionSilently: notProvided,
};

const CognitoContext = createContext<ICognitoContext>(initialContext);

export default CognitoContext;
