import { Box, Select, Text } from 'grommet';
import cardCheckbox from '../../../../../assets/cardCheckbox.svg';
import { useResponsiveWrapper } from '../ResponsiveContextWrapper';
import { Scenario } from '../../../../../service/estimate/interfaces';

export interface BrazilCoverAnnualFormProps {
  showTitle?: boolean;
  field?: Scenario | null;
  onCheckboxClick?: () => void;
  practice: any;
  onTimesAppliedSelect: (option) => void;
  labelMargin?: string;
}

const timesAppliedOptions = Array.from(Array(10), (e, i) => ({
  value: i + 1,
  label: `${i + 1} vez${i === 0 ? '' : 'es'}`,
}));

export const coverAnnualDefaultValue = { value: 10, label: '10 vezes' };

const BrazilCoverAnnualForm = ({
  showTitle = true,
  field,
  onCheckboxClick,
  practice,
  onTimesAppliedSelect,
  labelMargin = '1rem 0 0 0',
}: BrazilCoverAnnualFormProps): JSX.Element => {
  const { fontSize } = useResponsiveWrapper();

  return (
    <Box width={'100%'}>
      {showTitle ? (
        <Box direction={'row'} align={'center'} alignSelf={'center'} justify={'between'} width={'100%'}>
          <Text weight={800} size={fontSize.medium} textAlign={'center'} alignSelf={'center'}>
            Cobertura - Anuais
          </Text>
          <img
            src={cardCheckbox}
            alt={'checkbox'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (onCheckboxClick) onCheckboxClick();
            }}
          />
        </Box>
      ) : (
        <></>
      )}
      <Box style={{ alignItems: 'start', width: '100%' }} margin={labelMargin}>
        <Text weight={400} size={fontSize.medium} textAlign={'start'}>
          Quantas vezes o produtor utilizará plantas de cobertura nos próximos 10 anos?
        </Text>
        <Box width={'100%'}>
          <Select
            style={{ height: '32px' }}
            options={timesAppliedOptions}
            valueKey={'value'}
            labelKey={'label'}
            defaultValue={coverAnnualDefaultValue}
            value={practice.details?.timesApplied}
            data-cy={`cover-crop-annual-times-applied-${field?.name}`}
            onChange={({ option }) => {
              onTimesAppliedSelect(option);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BrazilCoverAnnualForm;
