import { Box } from 'grommet';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../service/common/env';
import { API } from '../../service/common/api';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MapContextProvider } from './service/map/MapContext';
// @ts-ignore
import { AgoroMap } from './components/Map/AgoroMap';
import './Map.style.css';

// eslint-disable-next-line import/no-anonymous-default-export
export const MapV2Page = () => {
  const navigate = useNavigate();
  const { upsellingBoundaryTool, cpBoundaryTool } = useFlags();
  const [lead, setLead] = useState<any>();
  const [showUpsellingBanner, setShowUpsellingBanner] = useState<boolean>(false);
  const params = useParams();
  const user = useSelector((state: any) => {
    return state.user.userData;
  });

  useEffect(() => {
    if (params.journeyId) {
      API.get(`${BASE_URL}/base/growers/leads/${params.journeyId}`)
        .then((result) => {
          setLead(result.data);
        })
        .catch(() => {
          API.get(`${BASE_URL}/base/producer/journey/${params.journeyId}`).then((res) => {
            API.get(`${BASE_URL}/base/growers/leads/${res.data.opportunityId || res.data.leadId}`).then((sfResult) => {
              setLead(sfResult.data);
            });
          });
        });
    }
  }, [params.journeyId]);

  useEffect(() => {
    setShowUpsellingBanner(
      upsellingBoundaryTool &&
        lead?.caseStatus === 'Document & Data Collection' &&
        !!lead?.caseStatusHistory?.find((i) => i.status === 'In Support') &&
        user?.country === 'US',
    );
  }, [upsellingBoundaryTool, lead]);

  const isChannelPartner = user?.channelPartnerId;
  if (isChannelPartner && !cpBoundaryTool) {
    navigate(-1);
  }

  return (
    <Box className={'agoro-map-wrapper'} style={{ height: '100%' }}>
      {showUpsellingBanner && (
        <Box
          style={{
            width: '100%',
            height: '68px',
            backgroundColor: '#D03450',
            color: '#fff',
            fontFamily: 'Avenir',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: 900,
            lineHeight: '20px',
            letterSpacing: '1.12px',
            justifyContent: 'center',
            // position: 'absolute'
          }}
        >
          You are on the Upselling flow. Every change made will be considered an addition to all previously inputted information. To edit past data
          collection inputs, please get in touch with the Product Team.
        </Box>
      )}
      <Box
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
        }}
      >
        <MapContextProvider>
          <AgoroMap />
        </MapContextProvider>
      </Box>
    </Box>
  );
};
