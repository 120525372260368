import { AnyAction } from '@reduxjs/toolkit';

const initState: {
  fields: any[];
} = {
  fields: [],
};

const reducer = (state = initState, action: AnyAction) => {
  switch (action.type) {
    case 'CLEAR_SF_FIELD_REQUESTED': {
      return {
        ...state,
        fields: [],
      };
    }
    case 'GET_FIELDS_REQUESTED': {
      return {
        ...state,
        fieldListState: { lifeCycle: 'PENDING' },
        fieldListParams: action.params,
      };
    }
    case 'GET_FIELDS_SUCCEEDED': {
      return {
        ...state,
        fieldListState: { lifeCycle: 'SUCCESS' },
        fields: action.payload,
      };
    }
    case 'GET_FIELDS_ERROR': {
      return {
        ...state,
        fieldListState: { lifeCycle: 'ERROR' },
        fields: [],
      };
    }
  }
  return state;
};

export default reducer;
