import { Box, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { useMapContext } from '../../service/map/MapContext';
import SyncTable from './SyncErrorModal/SyncTable';
import ConfirmationModal from './ConfirmationModal';
// @ts-ignore
import { showToast } from '../../service/common/utils';

interface SyncFieldsModalProps {
  setShowFinalizeModal: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setShowLoading: (value: ((prevState: boolean) => boolean) | boolean) => void;
}

const SyncFieldsModal = ({ setShowFinalizeModal, setShowLoading }: SyncFieldsModalProps) => {
  const { t } = useTranslation();
  const { saveFields, country, calculateCarbonEstimate, upsertShapefile, totalFieldsAcreage, getPracticeData, fields } = useMapContext();

  async function handleSyncFields() {
    setShowFinalizeModal(false);
    // stepForward();
    await saveFields();
    setShowLoading(true);
    try {
      let totalCarbon;
      if (country === 'br') {
        const calculatedResult = await calculateCarbonEstimate();
        // Dividing by 3.67 to convert from CO2 to C
        totalCarbon = calculatedResult.calculatorResultCombined.carbon.totalCarbon / 3.67;
      }
      await upsertShapefile('APPROVED', totalCarbon);
      showToast(t('fieldFlow.success.success'), t('fieldFlow.success.sync'), 'success', 'sync-success');
    } catch (e) {
      showToast(t('fieldFlow.errors.error'), t('fieldFlow.errors.sync'), 'error', 'sync-error');
    } finally {
      setShowLoading(false);
    }
  }

  return (
    <ConfirmationModal
      dataCy={'sync-fields-modal'}
      confirm={async () => await handleSyncFields()}
      cancel={() => setShowFinalizeModal(false)}
      title={t('fieldFlow.syncModal.title')}
      width={'708px'}
      message={
        <Box>
          <Text weight={700} textAlign={'center'} color={'#D03450'}>
            {t('fieldFlow.syncModal.afterSync')}
          </Text>
          <Text weight={700} textAlign={'center'} color={'#D03450'}>
            {t('fieldFlow.syncModal.areYouSure')}
          </Text>
          <Text weight={700} margin={{ top: '16px', bottom: '8px' }} textAlign={'center'}>
            {t('fieldFlow.syncModal.currentData')} ({t('fieldFlow.totalAcreage')}: {totalFieldsAcreage} {t('fieldFlow.acreUnit')})
          </Text>
          <SyncTable practiceData={getPracticeData(fields.filter((i) => !i?.properties?.unenrolledAt))} type={'currentData'} />
        </Box>
      }
      confirmLabel={t('fieldFlow.syncModal.confirm')}
      cancelLabel={t('fieldFlow.syncModal.cancel')}
    />
  );
};

export default SyncFieldsModal;
