import React, { useCallback, useState } from 'react';
import { Box, Text, MaskedInput } from 'grommet';
import { LngLat } from 'mapbox-gl';
import { debounce } from 'lodash';

export type CoordinateSearchComponentProps = {
  map: any;
};

export const CoordinateSearchComponent = ({ map }: CoordinateSearchComponentProps): JSX.Element => {
  const [value, setValue] = useState<string>(stringifyLatLng(map.getCenter()) ?? '');
  const [validCoordinate, setValidCoordinate] = useState<boolean>(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mapMoveHandler = useCallback(
    debounce((event: any) => {
      setValidCoordinate(true);
      setValue(stringifyLatLng(event.target.getCenter()));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 100) as (event: any) => void,
    [],
  );
  map.on('move', mapMoveHandler);

  function stringifyLatLng(lngLat: LngLat) {
    return `${lngLat.lat.toPrecision(10)}, ${lngLat.lng.toPrecision(10)}`;
  }

  function stringToLngLat(coordinateString: string) {
    const coordinates = coordinateString.split(',');
    return new LngLat(parseFloat(coordinates[1]), parseFloat(coordinates[0]));
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCoordinate = useCallback(
    debounce((value) => {
      //Tests to see if it's a valid coordinate string
      const isValidCoordinate = /^[-+]?([0-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(value);
      setValidCoordinate(isValidCoordinate);
      if (isValidCoordinate) {
        const lngLat = stringToLngLat(value);
        map.flyTo({ center: lngLat });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 1000) as (value: string) => void,
    [],
  );

  return (
    <React.Fragment>
      <Box
        round={'5px'}
        style={{
          background: '#ffffff',
        }}
      >
        <Box
          width={'180px'}
          height={'48px'}
          round={'5px'}
          border={{ color: '#282828', size: '1px' }}
          style={{
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 11,
            background: '#ffffff',
          }}
        >
          <MaskedInput
            mask={[{ regexp: /^([-+]?\d{0,3}(.\d{0,10})?\s?),?(\s?[-+]?\d{0,3}(.\d{0,10})?\s?)$/ }]}
            data-cy='input-searchbar'
            style={{ fontWeight: 700, padding: 0, color: validCoordinate ? '#282828' : '#D03450' }}
            plain={true}
            textAlign={'center'}
            focusIndicator={false}
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
              debounceCoordinate(event.target.value);
            }}
          />
        </Box>
        {!validCoordinate ? (
          <Box
            background={'#D03450'}
            pad={'2px'}
            margin={{ top: '-3px' }}
            round={'0 0 5px 5px'}
            style={{ borderWidth: '0px 1px 1px 1px', borderColor: '#D03450', borderStyle: 'solid' }}
          >
            <Text textAlign={'center'} weight={700} size={'10px'} style={{ color: '#FFF' }}>
              Invalid Coordinates
            </Text>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </React.Fragment>
  );
};
