import { Geodesic } from 'geographiclib-geodesic';

export function polygonArea(geomCoords: number[][][]) {
  let total = 0;
  const geod = Geodesic.WGS84;

  if (geomCoords && geomCoords.length > 0) {
    geomCoords.forEach((borderCoordinates, i) => {
      let poly = geod.Polygon(false);
      for (let j = 0; j < borderCoordinates.length; ++j) {
        poly.AddPoint(borderCoordinates[j][1], borderCoordinates[j][0]);
      }
      if (i === 0) {
        //Outer Border
        poly = poly.Compute(false, true);
        total = Math.abs(poly.area);
      } else {
        //Inner Border
        poly = poly.Compute(false, true);
        total -= Math.abs(poly.area);
      }
    });
  }
  return total;
}
