import { Box, Button, Layer, Text, Image } from 'grommet';
import { useEffect, useState } from 'react';
import { useMapContext } from '../../service/map/MapContext';
import { DateInput } from '../Input/DateInput';
import { addDays, formatDate } from '../../service/common/utils';
import arrowLong from '../../../../assets/BoundaryTool/arrow-long.svg';
import { useTranslation } from 'react-i18next';

interface EndCurrentVersionModalProps {
  closeDateRangeModal: () => void;
}

export const EndCurrentVersionModal = ({ closeDateRangeModal }: EndCurrentVersionModalProps) => {
  const { t } = useTranslation();

  const { currentVersion, getLastPossibleVersionEndDate, endCurrentVersion } = useMapContext();
  const [tempEndDate, setTempEndDate] = useState<Date | undefined>();
  const [dateError, setDateError] = useState(false);
  const [minEndDate, setMinEndDate] = useState(addDays(currentVersion.startDate, 1));

  useEffect(() => {
    getLastPossibleVersionEndDate()
      .then((lastDate: any) => {
        setMinEndDate(() => {
          if (!!lastDate) return new Date(lastDate);
          return addDays(currentVersion.startDate, 1);
        });
        setTempEndDate(undefined);
      })
      .catch((err) => {
        setMinEndDate(addDays(currentVersion.startDate, 1));
        setTempEndDate(undefined);
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVersion]);

  useEffect(() => {
    setDateError(!tempEndDate || tempEndDate > new Date() || tempEndDate < minEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempEndDate]);

  return (
    <Layer position='center' style={{ borderRadius: '12px' }}>
      <Box pad='24px' width='500px' style={{ maxHeight: '700px' }}>
        <Text size='26px' color='#282828' weight={800} style={{ lineHeight: '1.7rem' }}>
          {t('fieldFlow.dateRangeList.endVersionModal.title')}
        </Text>
        <Text margin={'0.5rem 0'} style={{ lineHeight: '20px', fontSize: '16px', fontWeight: '400' }}>
          {t('fieldFlow.dateRangeList.endVersionModal.message')}
        </Text>
        <Box
          margin={'0'}
          round={'5px'}
          style={{ maxHeight: '28vh', minHeight: 'unset' }}
          overflow={'auto'}
          border={{ color: '#282828', size: '1px' }}
          align={'center'}
          pad={'1rem 2.25rem'}
          gap={'1rem'}
        >
          <Box style={{ minHeight: 'unset' }} width={'100%'} direction={'row'} align={'between'} justify={'between'}>
            <Text style={{ width: '30%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} size={'1rem'} weight={700}>
              {currentVersion.name}
            </Text>
            <Text style={{ width: '25%' }} size={'1rem'} weight={700}>
              {formatDate(currentVersion.startDate)}
            </Text>
            <Image src={arrowLong} width={'20px'} />
            <Text style={{ width: '30%', textAlign: 'center' }} size={'1rem'} weight={700}>
              {currentVersion.endDate ? formatDate(currentVersion.endDate) : t('fieldFlow.dateRangeModal.current')}
            </Text>
          </Box>
        </Box>
        <Box width={'100%'} direction={'row'} margin={'1.25rem 0 0 0'}>
          <Box>
            <DateInput
              label={t('fieldFlow.dateRangeModal.endDate')}
              min={minEndDate}
              max={new Date()}
              width={'100%'}
              value={tempEndDate}
              error={dateError}
              onChange={(value) => setTempEndDate(value as Date)}
            />
          </Box>
        </Box>
        <Box direction={'row'} justify={'between'} margin={'1.5rem 0 0 0'} gap={'0.75rem'}>
          <Button
            plain
            focusIndicator={false}
            label={t('fieldFlow.dateRangeList.endVersionModal.backToMap')}
            style={{
              borderRadius: '0px',
              border: '1px solid #282828',
              textAlign: 'center',
              width: '100%',
              fontWeight: '800',
              fontSize: '16px',
              padding: '8px 0',
              justifyContent: 'center',
            }}
            onClick={() => {
              closeDateRangeModal();
            }}
          />
          <Button
            focusIndicator={false}
            hoverIndicator={false}
            disabled={dateError}
            style={{
              borderRadius: '0px',
              fontWeight: '800',
              fontSize: '16px',
              textAlign: 'center',
              width: '100%',
              padding: '8px 0',
              backgroundColor: '#FFE137',
              justifyContent: 'center',
            }}
            onClick={() => {
              endCurrentVersion(tempEndDate!);
              closeDateRangeModal();
            }}
            label={t('fieldFlow.dateRangeList.endVersion')}
            plain
          />
        </Box>
      </Box>
    </Layer>
  );
};

export default EndCurrentVersionModal;
