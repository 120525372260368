import draw_line_string from './drawLineString';
//@ts-ignore
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
//@ts-ignore
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';

const { onSetup: originOnSetup, onMouseMove: originOnMouseMove, clickAnywhere: originClickAnywhere, ...restOriginMethods } = draw_line_string;

const PassingDrawLineString = {
  originOnSetup,
  originOnMouseMove,
  originClickAnywhere,
  ...restOriginMethods,
};

PassingDrawLineString.onSetup = function (opt: any) {
  const state = this.originOnSetup();
  const { onDraw, onCancel } = opt;
  state.onDraw = onDraw;
  state.onCancel = onCancel;
  return state;
};

PassingDrawLineString.onMouseMove = function (state: any, e: any) {
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this.originOnMouseMove(state, e);
};

PassingDrawLineString.clickOnVertex = function (state: any) {
  //Avoids selecting line that can't be created (less than two vertices)
  if (state.currentVertexPosition > 1) {
    return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.line.id] });
  }
};

PassingDrawLineString.clickAnywhere = function (state: any, e: any) {
  this.originClickAnywhere(state, e);
  this.map.fire('draw.update', {
    action: 'CreatePaddockVertex',
  });
};

PassingDrawLineString.onStop = function (state: any) {
  const f = state.line;

  this.updateUIClasses({ mouse: Constants.cursors.NONE });
  doubleClickZoom.enable(this);
  this.activateUIButton();

  /// check to see if we've deleted this feature
  const drawnFeature = this.getFeature(f.id);
  if (drawnFeature === undefined) {
    /// Call `onCancel` if exists.
    if (typeof state.onCancel === 'function') state.onCancel();
    return;
  }
  /// remove last added coordinate
  else f.removeCoordinate(`${state.currentVertexPosition}`);

  if (f.isValid()) {
    if (typeof state.onDraw === 'function') state.onDraw(f.toGeoJSON());
    else
      this.map.fire('draw.passing-create', {
        features: [f.toGeoJSON()],
      });
  }
  this.deleteFeature([f.id], { silent: true });
  this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
};

export default PassingDrawLineString;
