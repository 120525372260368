import { Box, Text } from 'grommet';
import { formatDate } from '../../service/common/utils';

interface DateInputProps {
  label?: string;
  width?: string;
  value?: Date | undefined;
  titleWeight?: number;
  onChange: (value?: Date) => void;
  onBlur?: (value?: Date) => void;
  min?: Date;
  max?: Date;
  disabled?: boolean;
  disabledInput?: boolean;
  error?: boolean;
  noDateLabel?: string;
}

export const DateInput = ({
  label,
  width,
  value,
  titleWeight,
  onChange,
  onBlur,
  min,
  max,
  disabled,
  disabledInput,
  error,
  noDateLabel,
}: DateInputProps) => {
  return (
    <Box width={width ?? '24.2%'} height={'44px'} direction={'column'}>
      {label && (
        <Text
          size={'16px'}
          style={{ lineHeight: '20px', whiteSpace: 'nowrap' }}
          color={'#282828'}
          weight={titleWeight ?? 700}
          margin={{ bottom: '0.25rem' }}
        >
          {label}
        </Text>
      )}
      {disabled ? (
        <Text size={'16px'} style={{ lineHeight: '16px', whiteSpace: 'nowrap' }} color={'#282828'} weight={400}>
          {value ? formatDate(value) : noDateLabel ?? '--'}
        </Text>
      ) : (
        <Box
          height={{ min: '29px' }}
          style={{
            lineHeight: '16px',
            borderRadius: '0',
            opacity: disabledInput ? 0.5 : undefined,
            pointerEvents: disabledInput ? 'none' : undefined,
          }}
          border={error ? { size: '1px', style: 'solid', color: 'red' } : { size: '1px', style: 'solid', color: '#808080' }}
        >
          <input
            type={'date'}
            min={min && new Date(min).toISOString().split('T')[0]}
            max={max && new Date(max).toISOString().split('T')[0]}
            style={{ lineHeight: '16px', padding: '4px', border: '0px', fontSize: '16px', fontWeight: '400' }}
            value={value ? new Date(value).toISOString().split('T')[0] : ''}
            onChange={(event) => {
              if (!event.target.value || event.target.value.length === 0) {
                onChange(undefined);
              } else {
                onChange(new Date(event.target.value));
              }
            }}
            onBlur={(event) => {
              if (!!onBlur) {
                if (!event.target.value || event.target.value.length === 0) {
                  onBlur(undefined);
                } else {
                  onBlur(new Date(event.target.value));
                }
              }
            }}
          />
        </Box>
      )}
    </Box>
  );
};
