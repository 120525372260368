import { MAP_MODES } from './modes';
// @ts-ignore
import DrawPolygon from '@mapbox/mapbox-gl-draw/src/modes/draw_polygon';

export const DrawField = { ...DrawPolygon };

DrawField.clickOnVertex = function (state: any) {
  if (state.polygon.coordinates[0].length < 4) {
    return;
  }

  return this.changeMode(MAP_MODES.DIRECT_SELECT, {
    featureId: state.polygon.id,
  });
};

DrawField.oldOnClick = DrawField.onClick;
DrawField.onTap = DrawField.onClick = function (state: any, event: any) {
  if (event.originalEvent.button === 2) {
    return this.onRightCLick(state);
  }
  return this.oldOnClick(state, event);
};

DrawField.onRightCLick = function (state: any) {
  if (state.currentVertexPosition) {
    const ring = state.polygon.coordinates[0];
    if (ring) {
      state.polygon.changed();
      state.currentVertexPosition -= 1;
      ring.splice(state.currentVertexPosition, 1);
    }
  }
};

export default DrawField;
