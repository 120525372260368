import { API } from '../common/api';
import { BASE_URL, CONTRACT_URL, ESTIMATE_URL } from '../common/env';
import AgoToast from '../../components/Toast/AgoToast';
import { User } from '../../redux/reducers/user-reducer';
import i18n from '../../i18n/i18n';
import {
  BrazilEnrollmentInfo,
  BrazilGuardrailInfo,
  BrazilPersonalInfo,
  Contract,
  ContractInfo,
  ContractPracticeList,
  EnrollmentInfo,
  GuardrailInfo,
  IContractVersion,
  IVersionRules,
  PaymentTypes,
  PersonalInfo,
  WitnessInfo,
} from './interfaces';
import { CalculatorResult, Practice, Scenario, UsaDetails } from '../estimate/interfaces';
import { cloneDeep } from 'lodash';
import { isImprovedGrazingAndAnotherPracticeLessThan0_3, shouldWarnGrazingCarbon } from '../common/estimate-validation-helper';
import { removeTrailingZeros } from '../../pages/contract-creation/utils/utils';
import { Lead } from '../../pages/customer-profile/types/Lead';
import { Journey } from '../../pages/customer-profile/types/GrowerService';
import { ChannelPartner } from '../common/interfaces';
const t = i18n.t;

const getMappedStatus = (status: string) => {
  switch (status) {
    case 'Warm':
    case 'Qualification call scheduled':
      return 'Contacted';

    case 'OA & Pitch Meeting':
    case 'Follow Up & Close':
    case 'Document verification':
      return 'Estimation Stage';

    case 'Closed Won':
      return 'Contract Complete';

    default:
      return status;
  }
};

const formatPhone = (phone: string, region?: string) => {
  if (!phone) return '-';
  if (region === 'US') {
    if (phone?.length === 10) return phone.substr(0, 3) + '-' + phone.substr(3, 3) + '-' + phone.substr(6, 4);
    return phone;
  }
  const x = phone.replace(/\D/g, '').match(region === 'BR' ? /(\d{2})(\d{5})(\d{4})/ : /(\d{3})(\d{3})(\d{4})/);
  if (x) {
    return '(' + x[1] + ') ' + x[2] + '-' + x[3];
  }
  return region === 'BR' ? phone : '-';
};
const makeInitials = (name: string): string => {
  if (!name) {
    return '-';
  }
  const res = name.split(' ');
  const res1: string = res[0];
  const res2: string = res[1];

  const firstInitials = res1?.split('#').map(function (str) {
    return str ? str[0].toUpperCase() : '';
  });
  const lastInitials = res2?.split('#').map(function (str) {
    return str ? str[0].toUpperCase() : '';
  });
  return firstInitials.concat(lastInitials).join('');
};
const formatCreatedAt = (date: Date, translation: string) => {
  const diff = Number(new Date()) - date.getTime();
  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = day * 365;
  switch (true) {
    case diff < minute:
      const seconds = Math.round(diff / 1000);
      return t(`common.time.${translation}`, { val: -seconds, range: 'second' });
    case diff < hour:
      const min = Math.round(diff / minute);
      return t(`common.time.${translation}`, { val: -min, range: 'minute' });
    case diff < day:
      const d = Math.round(diff / hour);
      return t(`common.time.${translation}`, { val: -d, range: 'hour' });
    case diff < month:
      const m = Math.round(diff / day);
      return t(`common.time.${translation}`, { val: -m, range: 'day' });
    case diff < year:
      const y = Math.round(diff / month);
      return t(`common.time.${translation}`, { val: -y, range: 'month' });
    case diff > year:
      const n = Math.round(diff / year);
      return t(`common.time.${translation}`, { val: -n, range: 'year' });
    default:
      return '-';
  }
};

const formatLocation = (city: string, state: string) => {
  const data: string[] = [];
  if (city) data.push(city);
  if (state) data.push(state);
  if (!data.length) return '-';
  return data.join(', ');
};

const getGrowerList = async () => {
  return await API.get(`${BASE_URL}/base/growers`).then((res) => {
    return {
      data: res.data.map((i: any) => {
        return {
          id: i.Grower_Id,
          name: i.Grower_Name,
          email: i.Email,
          status: i.Opportunity_Stage,
          location: formatLocation(i.City, i.State),
          phone: formatPhone(i.Phone),
          createdAt: 'Today',
        };
      }),
    };
  });
};

const getChannelPartner = (leadChannelPartner: any, loggedInChannelPartner: any) => {
  return leadChannelPartner || loggedInChannelPartner;
};

const getPartnerData = (loggedInChannelPartner: ChannelPartner) => {
  return {
    label: `${loggedInChannelPartner.name} - ${loggedInChannelPartner.contractType}`,
    value: {
      ...loggedInChannelPartner,
    },
  };
};

const getLeadList = async (user: User | null, filter: string, showCP: boolean, leadsList?: string[]) => {
  if (!user) throw Error('No user found');

  let channelPartnerId = '';
  if (user.channelPartnerId) {
    channelPartnerId = user.channelPartnerId;
  }
  return await API.get(`${BASE_URL}/base/growers/leads`, {
    channelPartnerId: channelPartnerId,
    filter: String(filter || '').replace(/'/g, "\\'"),
    showChannelPartner: showCP,
    leadsList: leadsList,
  })
    .then((res) => {
      const leads = res.data.sort((a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1));
      return leads.map((i: any) => {
        i.createdAt = new Date(i.createdAt);
        i.location = formatLocation(i.address?.city, i.address?.state);
        i.displayStatus =
          !!channelPartnerId && (i.address.countryCode === 'US' || i.address.country === 'United States') ? getMappedStatus(i.status) : i.status;
        return i;
      });
    })
    .catch((err) => {
      if (err.response?.status === 500) {
        AgoToast.showToast({
          title: 'Malformed Request',
          message: 'Please try again or contact support.',
          type: 'error',
          toastId: 'malformed-request',
        });
      } else {
        AgoToast.showToast({
          title: `Error ${err.response?.status}`,
          message: 'An error occurred.',
          type: 'error',
          toastId: 'lead-error',
        });
      }
      throw Error('Error occurred in lead request.');
    });
};

const getPersonalInfoSection = async (
  growerInfo: PersonalInfo & { country: string; neighborhood: string },
  loggedInChannelPartner: ChannelPartner,
  savedPersonalInfo?: PersonalInfo | BrazilPersonalInfo,
) => {
  let brazilPersonalInfo;
  if (growerInfo?.country === 'BR') {
    brazilPersonalInfo = {
      legalEntityName: (savedPersonalInfo as BrazilPersonalInfo)?.legalEntityName || '',
      cpf: (savedPersonalInfo as BrazilPersonalInfo)?.cpf || '',
      cnpj: (savedPersonalInfo as BrazilPersonalInfo)?.cnpj || '',
      neighborhood: (savedPersonalInfo as BrazilPersonalInfo)?.neighborhood || growerInfo.neighborhood || '',
      properties: (savedPersonalInfo as BrazilPersonalInfo)?.properties || '',
      propertiesRegisterOffice: (savedPersonalInfo as BrazilPersonalInfo)?.propertiesRegisterOffice || '',
    };
  }
  //TODO: Fix lead CP name is in channelPartner instead of channelPartnerName
  return {
    growerName: savedPersonalInfo?.growerName || growerInfo?.growerName || '',
    streetAddress: savedPersonalInfo?.streetAddress || growerInfo?.streetAddress || '',
    city: savedPersonalInfo?.city || growerInfo?.city || '',
    state: savedPersonalInfo?.state || growerInfo?.state || '',
    zipCode: savedPersonalInfo?.zipCode || growerInfo?.zipCode || '',
    company: savedPersonalInfo?.company || growerInfo?.company || '',
    email: savedPersonalInfo?.email || growerInfo?.email || '',
    phone: savedPersonalInfo?.phone && savedPersonalInfo?.phone !== '-' ? savedPersonalInfo?.phone : growerInfo?.phone || '',
    isLeadFromCP: !!growerInfo?.channelPartner,
    channelPartner:
      savedPersonalInfo?.channelPartner ||
      (!!getChannelPartner(growerInfo?.channelPartner, loggedInChannelPartner?.name) ? 'Yes' : 'N/A Direct sale'),
    channelPartnerName:
      savedPersonalInfo?.channelPartnerName ||
      growerInfo?.channelPartnerName ||
      growerInfo?.channelPartner ||
      `${loggedInChannelPartner?.name} - ${loggedInChannelPartner?.contractType}` ||
      'N/A Direct Sale',
    channelPartnerData: getPartnerData(loggedInChannelPartner),
    ...brazilPersonalInfo,
  };
};

const getWitnessSection = (savedWitnessInfo?: WitnessInfo) => {
  return {
    witnessName: savedWitnessInfo?.witnessName || '',
    witnessCpf: savedWitnessInfo?.witnessCpf || '',
    witnessEmail: savedWitnessInfo?.witnessEmail || '',
    witnessRelationshipWithProducer: savedWitnessInfo?.witnessRelationshipWithProducer || '',
  };
};

const getConvertedPracticeInformation = (scenarios: Scenario[], defaultPractices: ContractPracticeList, region: string): ContractPracticeList => {
  for (const scenario of scenarios) {
    // Brazil is always Preço fixo. USA is optionA unless optionB is selected, even for anual practices
    let paymentOption: PaymentTypes = region === 'BR' ? 'Preço fixo' : (scenario.details as UsaDetails).paymentOption;
    for (const practice of scenario.practices) {
      const defaultPractice = defaultPractices[practice.shortCode];
      if (practice.availablePaymentOptions.includes('ANNUAL')) {
        paymentOption = 'ANNUAL';
      }
      if (paymentOption === 'OPTION_A' || paymentOption === 'Preço fixo' || paymentOption === 'ANNUAL') {
        defaultPractice.optionA.area += scenario.area;
      } else {
        defaultPractice.optionB.area += scenario.area;
      }
      defaultPractice.totalArea += scenario.area;
      defaultPractice.checked = true;
      defaultPractice.paymentOption = paymentOption;
      defaultPractice.type = scenario.practiceType;
    }
  }
  Object.keys(defaultPractices).forEach((key) => {
    defaultPractices[key].optionA.area = Number(removeTrailingZeros(defaultPractices[key].optionA.area));
    defaultPractices[key].optionB.area = Number(removeTrailingZeros(defaultPractices[key].optionB.area));
    defaultPractices[key].totalArea = Number(removeTrailingZeros(defaultPractices[key].totalArea));
  });
  return defaultPractices;
};

const getDefaultPractices = (availablePractices: Practice[], contractPractices: ContractPracticeList): ContractPracticeList => {
  const updatedContractPractices = cloneDeep(contractPractices);
  for (const practice of availablePractices) {
    const updatedPractice = {
      id: practice.id,
      name: practice.name,
      optionA: {
        area: 0,
      },
      optionB: {
        area: 0,
      },
      totalArea: 0,
      startDate: '',
      quarter: '',
      type: practice.type,
      checked: false,
      externalId: practice.externalId,
      shortCode: practice.shortCode,
      availablePaymentOptions: practice.availablePaymentOptions,
    };
    if (!updatedContractPractices[practice.shortCode]) {
      updatedContractPractices[practice.shortCode] = {
        ...updatedPractice,
      };
    } else {
      updatedContractPractices[practice.shortCode] = {
        ...updatedPractice,
        startDate: updatedContractPractices[practice.shortCode].startDate,
        quarter: updatedContractPractices[practice.shortCode].quarter,
      };
    }
  }
  return updatedContractPractices;
};

const getEnrollmentInfoSection = (
  growerInfo: PersonalInfo & { country: string; neighborhood: string },
  estimateResults: CalculatorResult,
  savedEnrollmentInfo?: EnrollmentInfo | BrazilEnrollmentInfo,
  isContractSigned?: boolean,
): EnrollmentInfo => {
  let brazilEnrollmentInfo;
  if (growerInfo?.country === 'BR') {
    brazilEnrollmentInfo = {
      containsIndigenousLand: (savedEnrollmentInfo as BrazilEnrollmentInfo)?.containsIndigenousLand,
      authorizedToSign: (savedEnrollmentInfo as BrazilEnrollmentInfo)?.authorizedToSign,
      authorityDescription: (savedEnrollmentInfo as BrazilEnrollmentInfo)?.authorityDescription,
    };
  }

  return {
    totalAreaEnrolled: isContractSigned
      ? removeTrailingZeros(savedEnrollmentInfo!.totalAreaEnrolled)
      : removeTrailingZeros(
          estimateResults.updatedScenarios.reduce((acc, scenario) => {
            return acc + scenario.area;
          }, 0),
        ),
    areaOwned: savedEnrollmentInfo?.areaOwned ? removeTrailingZeros(savedEnrollmentInfo.areaOwned) : '',
    areaLeased: savedEnrollmentInfo?.areaLeased ? removeTrailingZeros(savedEnrollmentInfo.areaLeased) : '',
    areaOther: savedEnrollmentInfo?.areaOther ? removeTrailingZeros(savedEnrollmentInfo.areaOther) : '',
    totalCarbon: (estimateResults.carbon.totalCarbon || 0).toFixed(2).toString(),
    leaseStartDate: savedEnrollmentInfo?.leaseStartDate || '',
    leaseEndDate: savedEnrollmentInfo?.leaseEndDate || '',
    ...brazilEnrollmentInfo,
  };
};

const shouldWarnAnyFieldImprovedGrazing = (fields) => {
  const fieldToWarn = fields.find((field) => shouldWarnGrazingCarbon(field));
  return !!fieldToWarn;
};

const hasAnyFieldImprovedGrazingAndAnotherPracticeLessThan0_3 = (fields) => {
  const fieldToWarn = fields.find((field) => isImprovedGrazingAndAnotherPracticeLessThan0_3(field));
  return !!fieldToWarn;
};

const getGuardrailSection = (
  growerInfo: PersonalInfo & { country: string; neighborhood: string },
  fields: any,
  savedGuardrailInfo?: GuardrailInfo | BrazilGuardrailInfo,
): GuardrailInfo | BrazilGuardrailInfo => {
  const guardrails = {
    salesAcknowledgement: savedGuardrailInfo?.salesAcknowledgement || false,
    otherAssumptions: savedGuardrailInfo?.otherAssumptions || '',
    shouldWarnGrazingCarbon: shouldWarnAnyFieldImprovedGrazing(fields),
    isImprovedGrazingAndAnotherPracticeLessThan0_3: hasAnyFieldImprovedGrazingAndAnotherPracticeLessThan0_3(fields),
  };
  if (growerInfo.country === 'BR') {
    guardrails['sentToOneDrive'] = (savedGuardrailInfo as BrazilGuardrailInfo)?.sentToOneDrive ?? false;
  }
  return guardrails;
};

const getFormattedContractPractices = (
  availablePractices: Practice[],
  isContractSigned: boolean = false,
  estimateScenarios: Scenario[],
  region: string,
  savedContractPractices?: ContractPracticeList,
): ContractPracticeList => {
  const defaultPractices = getDefaultPractices(availablePractices, savedContractPractices || {});
  const updatedContractPractices = isContractSigned
    ? savedContractPractices
    : getConvertedPracticeInformation(estimateScenarios, defaultPractices, region);
  return updatedContractPractices!;
};

const getContractInfo = async (
  journeyId: string,
  estimate: any,
  estimateResults: CalculatorResult,
  availablePractices: Practice[],
  loggedInChannelPartner: ChannelPartner,
  producerInformation: any,
) => {
  const savedContract: Contract = await getContract(journeyId, estimate.id);
  const growerInfo = await getGrowerInformation(producerInformation);
  const isContractSigned = savedContract?.docusignStatus === 'DOCUSIGN_SIGNED' || savedContract?.docusignStatus === 'DOCUSIGN_COMPLETED';
  const practices = getFormattedContractPractices(
    availablePractices,
    isContractSigned,
    estimateResults.updatedScenarios,
    growerInfo.country,
    savedContract?.data?.practices,
  );
  const contractInfo: Partial<ContractInfo> = {
    country: growerInfo.country,
    personalInfoSection: await getPersonalInfoSection(growerInfo, loggedInChannelPartner, savedContract?.data?.personalInfoSection),
    notesSection: savedContract?.data?.notesSection || estimate?.notes || '',
    practices: practices,
    enrollmentInfoSection: getEnrollmentInfoSection(growerInfo, estimateResults, savedContract?.data?.enrollmentInfoSection, isContractSigned),
    guardrailSection: getGuardrailSection(growerInfo, estimate.data, savedContract?.data?.guardrailSection),
    conflictOfInterestSection: {
      conflictOfInterestCheck: savedContract?.data?.conflictOfInterestSection?.conflictOfInterestCheck || false,
      conflictOfInterest: savedContract?.data?.conflictOfInterestSection?.conflictOfInterest || null,
    },
    history: savedContract?.data?.history,
  };
  if (contractInfo.country === 'BR') {
    contractInfo.witnessSection = getWitnessSection(savedContract?.data?.witnessSection);
    contractInfo['paymentOption'] = savedContract?.data?.paymentOption ?? 'Preço fixo';
  }
  return {
    docusignStatus: savedContract?.docusignStatus || 'UNINITIALIZED',
    contractId: savedContract?.id || undefined,
    approvedBy: savedContract?.approvedBy || '',
    docusignUpdate: savedContract?.docusignUpdate || false,
    estimateId: savedContract?.estimateId || estimate.id,
    contractType: savedContract?.contractType,
    contractRegion: savedContract?.contractRegion || contractInfo.country,
    data: contractInfo,
    createdAt: savedContract?.createdAt || '',
    updatedAt: savedContract?.updatedAt || '',
    createdBy: savedContract?.createdBy || '',
    deletedAt: savedContract?.deletedAt || '',
    envelopeId: savedContract?.envelopeId,
    growerId: savedContract?.growerId || '',
    lastEditedBy: savedContract?.lastEditedBy || '',
    templateId: savedContract?.templateId,
  };
};

const getDefaultAvailablePractices = async (contractVersionId) => {
  return Promise.all([
    API.get(`${ESTIMATE_URL}/estimate-service/practices`).then((res) => {
      return res.data;
    }),
    ContractService.getContractVersionById(contractVersionId),
  ]).then((data: any) => {
    const availablePractices = data[0];
    const contractVersion = data[1];
    const contractPractices = contractVersion.availablePractices.split(',');
    const practices = availablePractices
      .filter((i) => contractPractices.indexOf(i.shortCode) >= 0)
      .map((res) => {
        if (res.name === 'Nitrogen reduction') {
          return {
            ...res,
            legacy: 'BOTH',
          };
        }
        return res;
      });
    return {
      practices,
      activeContractVersion: contractVersion.active,
    };
  });
};

const createDocusignContract = async (data: any, userInfo: any) => {
  const payload = {
    ...data,
    salespersonEmail: userInfo.userData.email,
    salespersonName: userInfo.userData.fullName,
  };
  return API.post(`${CONTRACT_URL}/contract-service/contract/envelope`, payload).then((res) => {
    return {
      data: res.data,
    };
  });
};

const getEstimatesByGrower = async (growerId: string) => {
  return API.get(`${ESTIMATE_URL}/estimate-service/estimates?growerId=${growerId}`).then((res) => {
    return res.data;
  });
};

const transformNotes = (notes: string) => {
  if (typeof notes !== 'string') {
    return notes;
  }
  return {
    ops: [{ insert: notes }],
  };
};

const getEstimateById = async (estimateId: string) => {
  return API.get(`${ESTIMATE_URL}/estimate-service/estimates/${estimateId}`)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      data.notes = transformNotes(data.notes);
      return data;
    });
};

const getCountryCodeFromCountry = (country: string) => {
  if (country === 'United States') {
    return 'US';
  }
  if (country === 'Brazil') {
    return 'BR';
  }
  return 'US';
};

const getGrowerInformation = async (producerInformation: any): Promise<PersonalInfo & { country: 'BR' | 'US'; neighborhood: string }> => {
  let postalCode = '';
  let street = '';
  let city = '';
  let state = '';
  const country = getCountryCodeFromCountry(producerInformation.country);
  if (country === 'US') {
    postalCode = producerInformation.accountAddress?.billingAddress?.postalCode || '';
    street = producerInformation.accountAddress?.billingAddress?.street || '';
    city = producerInformation.accountAddress?.billingAddress?.city || '';
    state = producerInformation.accountAddress?.billingAddress?.state || '';
  } else {
    postalCode = producerInformation.Address?.postalCode || '';
    street = producerInformation.Address?.street || '';
    city = producerInformation.Address?.city || '';
    state = producerInformation.Address?.state || '';
  }

  return {
    channelPartner: producerInformation.ChannelPartner__c || producerInformation.ReferralPartner__c,
    growerName: producerInformation.growerName,
    streetAddress: street,
    city: city,
    state: state,
    zipCode: postalCode,
    country: country,
    company: country === 'BR' ? producerInformation.FarmName ?? '' : '', // https://agoro.atlassian.net/browse/AP-5413 - company should be empty by default
    neighborhood: country === 'BR' ? producerInformation.Neighborhood__c ?? '' : '',
    email: producerInformation.Email || '',
    phone: producerInformation.Phone ? formatPhone(producerInformation.Phone, country) : '',
  };
};

const saveContractInfo = async (contractInfo: any, metaData: any) => {
  if (metaData.contractVersionId) {
    const activeContractVersion: IContractVersion = await getContractVersionById(metaData.contractVersionId);
    metaData.templateId = activeContractVersion.docusignId;
  }
  const payload = {
    ...metaData,
    data: contractInfo,
  };
  //Treat CPF and CNPJ to maintain format consistency
  if (contractInfo.country === 'BR') {
    payload.data.personalInfoSection = {
      ...payload.data.personalInfoSection,
      cpf: contractInfo.personalInfoSection.cpf?.replace(/\D/g, ''),
      cnpj: contractInfo.personalInfoSection.cnpj?.replace(/\D/g, ''),
    };
  }
  return await API.post(`${CONTRACT_URL}/contract-service/contract`, payload);
};

const getActiveContractVersion = async (country: string, versionRules: IVersionRules): Promise<IContractVersion> => {
  const res = await API.post(`${CONTRACT_URL}/contract-service/contract/contract-versions/active-version?country=${country}`, versionRules);
  return res.data;
};

const getContractVersionById = async (id: string): Promise<IContractVersion> => {
  const res = await API.get(`${CONTRACT_URL}/contract-service/contract/contract-versions/${id}`);
  return res.data;
};

const sendToDocuSign = (growerId: any) => {
  return API.patch(`${CONTRACT_URL}/contract-service/contract/update-status/${growerId}`, { status: true });
};

const cachedContracts = {};

const getContract = (journeyId: string, estimateId: string) => {
  if (cachedContracts[journeyId + estimateId]) {
    return cachedContracts[journeyId + estimateId];
  }
  const promise = API.get(`${CONTRACT_URL}/contract-service/contract/${journeyId}/${estimateId}`).then((res) => {
    cachedContracts[journeyId + estimateId] = null;
    return res.data;
  });
  cachedContracts[journeyId + estimateId] = promise;
  return promise;
};

const getMultipleContractsByJourneyId = (journeyId: string, estimateIds: string[]) => {
  return API.get(`${CONTRACT_URL}/contract-service/contract/multiple/${journeyId}?estimateIds=${estimateIds}`).then((res) => {
    return res.data;
  });
};

const getLeadsByContractStatus = (status: string) => {
  return API.get(`${CONTRACT_URL}/contract-service/contract/lead/${status}`);
};

const getSequestrationApprovalReasons = async () => {
  return API.get(`${CONTRACT_URL}/contract-service/contract/sequestration-approval-reasons`).then((res) => {
    return res.data;
  });
};

const sendToRiskAssessment = async (contractId: string) => {
  return API.post(`${CONTRACT_URL}/contract-service/contract/risk-assessment/${contractId}`, {}).then((res) => {
    return res.data;
  });
};

const getValidChannelPartners = async () => {
  return API.get(`${CONTRACT_URL}/contract-service/contract/channel-partners`).then((res) => {
    return res.data;
  });
};

const getRiskAssessment = async (contractId: string) => {
  return API.get(`${CONTRACT_URL}/contract-service/contract/risk-assessment/${contractId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return null;
    });
};

export const getContractType = (userInfo: Record<string, any>) => {
  if (userInfo.userData.channelPartnerId) return 'CHANNEL_PARTNER';
  return 'INTERNAL';
};

export const shouldDisableForm = (userInfo, contractFromStore) => {
  if (userInfo.userData.channelPartnerId) {
    return (
      contractFromStore.contractInfoState.docusignStatus !== 'UNINITIALIZED' &&
      contractFromStore.contractInfoState.docusignStatus !== 'AGORO_SAVED' &&
      contractFromStore.contractInfoState.docusignStatus !== 'SALES_APPROVAL_REJECTED'
    );
  }
  return (
    contractFromStore.contractInfoState.docusignStatus === 'DOCUSIGN_SIGNED' ||
    contractFromStore.contractInfoState.docusignStatus === 'DOCUSIGN_COMPLETED' ||
    contractFromStore.contractInfoState.docusignStatus === 'DOCUSIGN_VOIDED' ||
    contractFromStore.contractInfoState.docusignStatus === 'RA_REVIEW_FAILED' ||
    contractFromStore.contractInfoState.lifeCycle === 'SUBMIT_PENDING'
  );
};

export const buildMetaData = (
  contractFromStore: any,
  estimateId: string | null,
  userInfo: Record<string, any>,
  lead: Lead | undefined,
  journey: Journey | undefined,
  status?: string,
  isEdited?: boolean,
) => {
  if (!status && isEdited === undefined) {
    const currentStatus = contractFromStore.contractInfoState.docusignStatus;
    status = currentStatus === 'UNINITIALIZED' ? 'AGORO_SAVED' : currentStatus;
    isEdited = currentStatus !== 'UNINITIALIZED';
  }
  return {
    id: contractFromStore?.contractId ?? undefined,
    estimateId: estimateId ?? null,
    isEdited: isEdited,
    contractRegion: contractFromStore?.contractRegion,
    envelopeId: contractFromStore?.envelopeId,
    docusignStatus: status,
    approvedBy: status === 'DOCUSIGN_APPROVAL' ? userInfo.userData.id : null,
    growerId: journey?.leadId ?? journey?.opportunityId ?? lead?.leadId ?? lead?.opportunityId,
    journeyId: journey?.id,
    contractType: contractFromStore.contractType ?? getContractType(userInfo),
    createdBy: contractFromStore.createdBy ?? userInfo.userData.id,
  };
};

export const ContractService = {
  getRiskAssessment,
  getGrowerList,
  getLeadList,
  getDefaultPractices,
  makeInitials,
  getContractInfo,
  getActiveContractVersion,
  createDocusignContract,
  getAutoSavedContractInfo: getContract,
  getEstimatesByGrower,
  saveContractInfo,
  sendToDocuSign,
  getEstimateById,
  formatCreatedAt,
  getLeadsByContractStatus,
  getSequestrationApprovalReasons,
  sendToRiskAssessment,
  getValidChannelPartners,
  getDefaultAvailablePractices,
  formatPhone,
  getMultipleContractsByJourneyId,
  getContractVersionById,
  getContractType,
  shouldDisableForm,
  buildMetaData,
};
