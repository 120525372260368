import { Box, Text } from 'grommet';
import { useMapContext } from '../../../service/map/MapContext';
import { useTranslation } from 'react-i18next';
import React from 'react';
import FieldDropdownV2 from './Dropdowns/FieldDropdownV2';
import PanelHeader from '../../common/PanelHeader';

const RanchOverviewV2 = () => {
  const { fields } = useMapContext();
  const { t } = useTranslation();

  return (
    <Box height={'100%'}>
      <Box direction={'row'} margin={'0 0 0 0'} justify={'between'} align={'center'} style={{ minHeight: '56px', minWidth: 'unset' }}>
        <Text weight={700} size={'1.5rem'} style={{ whiteSpace: 'nowrap' }}>
          {t('fieldFlow.mainWizard.Fields.title')}
        </Text>
        <PanelHeader />
      </Box>
      <Box height={'1px'} width={'100%'} background={'#808080'} margin={{ vertical: '16px' }} />
      <Box gap={'0.5rem'} overflow={'auto'}>
        {fields.map((field: any) => {
          return <FieldDropdownV2 key={field.id} field={field} />;
        })}
      </Box>
    </Box>
  );
};

export default RanchOverviewV2;
