import { Box, Button, Layer, Text } from 'grommet';
import { Feature } from '@turf/helpers';
// @ts-ignore
import enrollIcon from '../../../../assets/BoundaryTool/enroll-icon.svg';
import { FieldThumbnail } from './FieldThumbnail';
import i18next, { TFunction } from 'i18next';
import { StyledTooltip } from '../common/StyledTooltip';
import React, { useState } from 'react';
import { PracticesIconsComponent } from './PracticesIconsComponent';

export const UnenrolledFieldItem = ({
  t,
  field,
  focusField,
  changeFieldEnrollmentStatus,
  country,
  hidden = false,
}: {
  t: TFunction;
  field: Feature;
  focusField: (field: Feature) => void;
  changeFieldEnrollmentStatus: (field: Feature, status: boolean, hidden?: boolean) => void;
  country: string;
  hidden?: boolean;
}) => {
  const [showEnrollModal, setShowEnrollModal] = useState<boolean>(false);

  return (
    <Box
      key={`field-${field.id}`}
      border={{ side: 'top', color: '#ccc', size: '1px' }}
      margin={'16px 0px'}
      pad={{ top: '16px' }}
      height={{ min: 'auto' }}
      gap={'16px'}
      focusIndicator={false}
      onClick={() => focusField(field)}
    >
      {showEnrollModal && (
        <Layer
          style={{ border: '1px solid #CCCCCC', borderRadius: '12px' }}
          onEsc={() => setShowEnrollModal(false)}
          onClickOutside={() => setShowEnrollModal(false)}
        >
          <Box alignSelf={'center'} align={'center'} pad={'24px'} gap={'24px'} width={'400px'} data-cy={'enroll-modal'}>
            <Box alignSelf={'center'} align={'center'} gap={'8px'}>
              <Text style={{ fontSize: '16px', fontWeight: '900' }} alignSelf='center'>
                {t('fieldFlow.mainWizard.Fields.enrollModal.title')}
              </Text>
              <Text style={{ fontSize: '12px', fontWeight: '400', textAlign: 'center' }}>{t('fieldFlow.mainWizard.Fields.enrollModal.content')}</Text>
            </Box>
            <Box width={'100%'} direction={'row'} gap={'12px'}>
              <Button
                plain
                data-cy={'enroll-modal-cancel'}
                label={t('fieldFlow.mainWizard.Fields.enrollModal.cancelButton')}
                onClick={() => {
                  setShowEnrollModal(false);
                }}
                className={'secondary-button'}
                style={{
                  padding: '8px 18px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid #282828',
                  fontWeight: '900',
                  fontSize: '16px',
                  flexGrow: 1,
                  textAlign: 'center',
                }}
              />
              <Button
                plain
                data-cy={'enroll-modal-confirm'}
                id={'resubmit-contract'}
                type='submit'
                label={t('fieldFlow.mainWizard.Fields.enrollModal.confirmButton')}
                onClick={($event) => {
                  $event.stopPropagation();
                  changeFieldEnrollmentStatus(field, !field.properties?.unenrolledAt, false);
                  setShowEnrollModal(false);
                }}
                className={'primary-button'}
                style={{
                  padding: '8px 18px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#FFE137',
                  fontWeight: '900',
                  fontSize: '16px',
                  color: '#282828',
                  flexGrow: 1,
                  textAlign: 'center',
                }}
              />
            </Box>
          </Box>
        </Layer>
      )}

      <Box direction={'row'} justify={'between'}>
        <Box direction={'row'} align={'center'} gap={'8px'}>
          <FieldThumbnail feature={field} height={36} width={36} unenrolled={true} />
          <Text
            style={{
              fontWeight: 900,
              fontSize: '20px',
              color: '#282828',
            }}
          >
            {field?.properties?.['name'] ?? t('fieldFlow.loadingField')}
          </Text>
          <Text
            style={{
              fontSize: '16px',
              fontWeight: 900,
              color: '#494949',
              marginLeft: '8px',
            }}
          >
            {field.properties?.[i18next.language === 'pt-BR' ? 'areaHectare' : 'area'].toFixed(2)}
          </Text>
        </Box>

        <StyledTooltip label={t(`fieldFlow.mainWizard.Fields.enrollTooltip`)} showToolTip={true} dropProps={{ align: { bottom: 'top' } }}>
          <img
            data-cy={`enrollment-field-button-${field?.properties?.name}`}
            src={enrollIcon}
            height={24}
            width={24}
            alt='Enrollment Icon'
            style={{
              opacity: field.properties?.editing ? '0.5' : '1',
              marginLeft: '16px',
              cursor: field.properties?.editing ? 'default' : 'pointer',
            }}
            onClick={(event) => {
              if (field.properties?.editing) return;
              event.stopPropagation();
              setShowEnrollModal(true);
            }}
          />
        </StyledTooltip>
      </Box>

      <Box direction={'row'} wrap={true} gap={'16px'}>
        <Box width={'45%'}>
          <Text weight={400} size={'16px'}>
            {t('fieldFlow.fieldItem.fieldDetails.landOwner')}
          </Text>
          <Text weight={900} size={'16px'} color={'#282828'}>
            {field.properties?.['situation'] === 'OWNED' ? t('fieldFlow.yes') : t('fieldFlow.syncModal.cancel')}
          </Text>
        </Box>
        <Box width={'45%'}>
          <Text weight={400} size={'16px'}>
            {t('fieldFlow.fieldItem.irrigation.title')}
          </Text>
          <Text weight={900} size={'16px'} color={'#282828'}>
            {field.properties?.['irrigated']
              ? t('fieldFlow.fieldItem.irrigation.options.irrigated')
              : t('fieldFlow.fieldItem.irrigation.options.nonIrrigated')}
          </Text>
        </Box>
        <Box width={'45%'}>
          <Text weight={400} size={'16px'}>
            {t('fieldFlow.fieldItem.practiceType.title')}
          </Text>
          <Text weight={900} size={'16px'} color={'#282828'}>
            {field.properties?.['practiceType'] === 'ROW_CROP'
              ? t('fieldFlow.fieldItem.practiceType.options.rowCrop')
              : t('fieldFlow.fieldItem.practiceType.options.pasture')}
          </Text>
        </Box>
        <Box width={'45%'}>
          <Text weight={400} size={'16px'}>
            {t('fieldFlow.ranchOverview.practices')}
          </Text>
          <PracticesIconsComponent practices={field.properties?.practices} />
        </Box>
      </Box>
    </Box>
  );
};
