import { Box, Text } from 'grommet';
import LevelTag from './LevelTag';
import PanelHeader from '../../common/PanelHeader';
import { useMapContext } from '../../../service/map/MapContext';
import { Step } from '../../../types/Step';

interface WizardHeaderProps {
  currentStep: Step;
}

const WizardHeader = ({ currentStep }: WizardHeaderProps) => {
  const { currentVersion, fields } = useMapContext();

  const getEmailFromUpdatedStamp = (stamp: string) => {
    if (!stamp) {
      return 'Unknown';
    }
    const stampSegments = stamp.split('|');
    return stampSegments[stampSegments.length - 1] || '';
  };

  const latestUpdatedInfo =
    fields.length > 0
      ? fields.reduce(
          (currentLatestUpdatedInfo: any, field) => {
            const properties = field.properties;
            const fieldUpdatedAt = properties?.updatedAt ? new Date(properties?.updatedAt) : null;
            const currentUpdatedAt = currentLatestUpdatedInfo?.updatedAt || null;
            if (fieldUpdatedAt === null) {
              return currentLatestUpdatedInfo;
            }
            if (currentUpdatedAt === null || fieldUpdatedAt < currentUpdatedAt) {
              return {
                updatedBy: getEmailFromUpdatedStamp(field.properties?.updatedBy),
                updatedAt: fieldUpdatedAt,
              };
            }
            return currentLatestUpdatedInfo;
          },
          { updatedBy: getEmailFromUpdatedStamp(fields[0].properties?.updatedBy), updatedAt: null },
        )
      : null;

  return (
    <>
      <Box direction={'row'} margin={'0.5rem 0 0 0'} justify={'between'} style={{ minHeight: '40px', minWidth: 'unset' }}>
        <Box direction={'row'} gap={'8px'} align={'center'} margin={{ right: '12px' }} style={{ minWidth: 'unset' }}>
          {/*Deactivating FormPrevious, current flow doesn't need it*/}
          {/*{stepIndex <= 2 &&*/}
          {/*  <FormPrevious*/}
          {/*    onClick={() => stepBack()}*/}
          {/*    style={{ cursor: 'pointer', marginTop: '2px' }}*/}
          {/*    width='9px'*/}
          {/*    height='9px'*/}
          {/*  />*/}
          {/*}*/}
          <Text weight={700} size={'1.5rem'} style={{ whiteSpace: 'nowrap' }}>
            {currentStep.title}
          </Text>
          {currentVersion && (
            <Text style={{ whiteSpace: 'nowrap' }} margin={'8px 0 0 0'}>
              {currentVersion.name}
            </Text>
          )}
          {currentStep.levels.map((level) => {
            return <LevelTag margin={'8px 0 0 0'} key={level} level={level} />;
          })}
        </Box>
        <PanelHeader />
      </Box>
      {currentStep.description && <Text margin={{ top: '1rem' }}>{currentStep.description}</Text>}
      {latestUpdatedInfo?.updatedAt ? (
        <Box margin={'10px 10px 1rem 10px'}>
          <Text textAlign={'start'}>
            Updated by {latestUpdatedInfo?.updatedBy} at {latestUpdatedInfo?.updatedAt?.toLocaleDateString()}{' '}
            {latestUpdatedInfo?.updatedAt?.toLocaleTimeString()}
          </Text>
        </Box>
      ) : null}
    </>
  );
};

export default WizardHeader;
