import axios, { ResponseType } from 'axios';
import { Store } from 'redux';

const BASE_URL = process.env['REACT_APP_GATEWAY_API_ENDPOINT'];

export const getAccessToken = (store: Store) => {
  // token gets token from father app
  const state = store.getState() as { context: { accessToken: null | string } };
  return state.context.accessToken;
};

const get = async (store: Store, url: string, params?: any) => {
  return axios.get(`${BASE_URL}${url}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken(store)}`,
    },
    params,
  });
};
const get2 = async (store: Store, url: string, params?: any) => {
  return axios.get(`${url}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken(store)}`,
    },
    params,
  });
};

const patch = async (store: Store, url: string, bodyData: any) => {
  return axios.patch(`${BASE_URL}${url}`, bodyData, {
    headers: {
      Authorization: `Bearer ${getAccessToken(store)}`,
    },
  });
};

const put = async (store: Store, url: string, bodyData: any) => {
  return axios.put(`${BASE_URL}${url}`, bodyData, {
    headers: {
      Authorization: `Bearer ${getAccessToken(store)}`,
    },
  });
};

const post = async (store: Store, url: string, bodyData: any, config?: { responseType?: ResponseType; headers: any }) => {
  return axios.post(`${BASE_URL}${url}`, bodyData, {
    responseType: config?.responseType,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${getAccessToken(store)}`,
    },
  });
};

const post2 = async (store: Store, url: string, bodyData: any, config?: { responseType?: ResponseType; headers: any }) => {
  return axios.post(`${url}`, bodyData, {
    responseType: config?.responseType,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${getAccessToken(store)}`,
    },
  });
};

const deleteMethod = async (store: Store, url: string) => {
  return axios.delete(`${BASE_URL}${url}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken(store)}`,
    },
  });
};

export const API = {
  post,
  post2,
  get,
  get2,
  patch,
  put,
  delete: deleteMethod,
};
