import { Box, Image, Text } from 'grommet';
import moveIcon from '../../../../../assets/BoundaryTool/move.svg';
import copyIcon from '../../../../../assets/BoundaryTool/copy-icon.svg';
import deleteIcon from '../../../../../assets/BoundaryTool/deleteIcon.svg';
import editIcon from '../../../../../assets/BoundaryTool/edit.svg';
import { useEffect, useRef } from 'react';
import { FormNext } from 'grommet-icons';
import { useTranslation } from 'react-i18next';

interface HerdOptionsProps {
  herd: any;
  onHerdHistory: () => void;
  onEditHerd: () => void;
  onMoveHerd: () => void;
  onRemoveHerd: () => void;
  onDuplicateHerd?: () => void;
  onClose: () => void;
  top?: string;
  right?: string;
  width?: string;
}

export const HerdOptions = ({
  herd,
  onEditHerd,
  onDuplicateHerd,
  onMoveHerd,
  onRemoveHerd,
  onHerdHistory,
  onClose,
  top,
  right,
  width,
}: HerdOptionsProps): JSX.Element => {
  const { t } = useTranslation();

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  //Listen for click outside of options box
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClose();
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      //Remove listener on component unmount
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <>
      <Box
        ref={wrapperRef}
        height={herd.endDate ? '80px' : '160px'}
        round={'5px'}
        pad={'9px'}
        gap={'10px'}
        justify={'between'}
        background={'#FFFFFF'}
        style={{
          border: '1px solid #CCCCCC',
          zIndex: 99,
          position: 'absolute',
          top: top ?? '-5px',
          right: right ?? '-13px',
          minWidth: width ?? '152px',
        }}
      >
        <Box
          direction={'row'}
          justify={'between'}
          align={'center'}
          onClick={() => {
            onHerdHistory();
            onClose();
          }}
        >
          <Text>{t('fieldFlow.herdItem.herdInfo.options.seeHistory')}</Text>
          <FormNext size={'20px'} />
        </Box>
        <Box
          direction={'row'}
          justify={'between'}
          align={'center'}
          onClick={() => {
            onEditHerd();
            onClose();
          }}
        >
          <Text>{t('fieldFlow.herdItem.herdInfo.options.edit')}</Text>
          <Image src={editIcon} height={20} width={20} />
        </Box>
        <Box
          direction={'row'}
          justify={'between'}
          align={'center'}
          onClick={() => {
            onMoveHerd();
            onClose();
          }}
        >
          <Text>{t('fieldFlow.herdItem.herdInfo.options.move')}</Text>
          <Image src={moveIcon} height={20} width={20} />
        </Box>
        {onDuplicateHerd && (
          <Box
            direction={'row'}
            justify={'between'}
            align={'center'}
            onClick={() => {
              onDuplicateHerd();
              onClose();
            }}
          >
            <Text>{t('fieldFlow.herdItem.herdInfo.options.duplicate')}</Text>
            <Image src={copyIcon} height={20} width={20} />
          </Box>
        )}
        {!herd.endDate && (
          <Box
            direction={'row'}
            justify={'between'}
            align={'center'}
            onClick={() => {
              onRemoveHerd();
              onClose();
            }}
          >
            <Text color={'#D03450'}>{t('fieldFlow.herdItem.herdInfo.options.remove')}</Text>
            <Image src={deleteIcon} height={24} width={24} />
          </Box>
        )}
      </Box>
    </>
  );
};

export default HerdOptions;
