import { Box, Text } from 'grommet';
import React from 'react';

export type MessageProps = {
  title?: string;
  message: string;
};
export const ToastBody = (props: MessageProps): JSX.Element => {
  return (
    <Box direction='column'>
      <Text size='medium' color='#191408'>
        {props.title}
      </Text>
      <Text size='medium' weight={800} color='#515564'>
        {props.message}
      </Text>
    </Box>
  );
};
