import lineDistance from '@turf/line-distance';
// @ts-ignore
import DrawLineString from '@mapbox/mapbox-gl-draw/src/modes/draw_line_string';
import { Feature } from '@turf/helpers';
import { MAP_MODES } from './modes';

const RadiusMode = { ...DrawLineString };

function createVertex(parentId: string, coordinates: [number, number], path: any, selected: boolean) {
  return {
    type: 'Feature',
    properties: {
      meta: 'vertex',
      parent: parentId,
      coord_path: path,
      active: selected ? 'true' : 'false',
    },
    geometry: {
      type: 'Point',
      coordinates,
    },
  };
}

function createGeoJSONCircle(center: [number, number], radiusInKm: number, parentId: string, points = 64) {
  const coords = {
    latitude: center[1],
    longitude: center[0],
  };

  const km = radiusInKm;

  const ret: any[] = [];
  const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  const distanceY = km / 110.574;

  let theta;
  let x;
  let y;
  for (let i = 0; i < points; i += 1) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [ret],
    },
    properties: {
      parent: parentId,
      active: 'true',
      meta: '',
    },
  };
}

function getDisplayMeasurements(feature: Feature) {
  // should log both metric and standard display strings for the current drawn feature

  // metric calculation
  const drawnLength = lineDistance(feature) * 1000; // meters

  let metricUnits = 'm';
  let metricMeasurement;

  let standardUnits = 'feet';
  let standardMeasurement;

  metricMeasurement = drawnLength;
  if (drawnLength >= 1000) {
    // if over 1000 meters, upgrade metric
    metricMeasurement = drawnLength / 1000;
    metricUnits = 'km';
  }

  standardMeasurement = drawnLength * 3.28084;
  if (standardMeasurement >= 5280) {
    // if over 5280 feet, upgrade standard
    standardMeasurement /= 5280;
    standardUnits = 'mi';
  }

  return {
    metric: `${metricMeasurement.toFixed(2)} ${metricUnits}`,
    standard: `${standardMeasurement.toFixed(2)} ${standardUnits}`,
  };
}

RadiusMode.clickAnywhere = function (state: any, e: any) {
  // this ends the drawing after the user creates a second point, triggering this.onStop
  if (state.currentVertexPosition === 1) {
    state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
    return this.changeMode(MAP_MODES.AUTO_DIRECT_SELECT, { featureIds: [state.line.id] });
  }
  this.updateUIClasses({ mouse: 'add' });
  state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
  if (state.direction === 'forward') {
    state.currentVertexPosition += 1; // eslint-disable-line
    state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat);
  } else {
    state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
  }

  return null;
};

// creates the final geojson point feature with a radius property
// triggers draw.create
RadiusMode.onStop = function (state: any) {
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.line.id) === undefined) return;

  // remove last added coordinate
  state.line.removeCoordinate('0');
  if (state.line.isValid()) {
    const lineGeoJson = state.line.toGeoJSON();
    const center = lineGeoJson.geometry.coordinates[0];
    const radiusInKm = lineDistance(lineGeoJson, 'kilometers');
    const circleFeature = createGeoJSONCircle(center, radiusInKm, state.line.id);
    circleFeature.properties!['meta'] = 'radius';

    this.deleteFeature([state.line.id], { silent: true });
    this.addFeature(this.newFeature({ ...circleFeature, id: state.line.id }));
    this.map.fire('draw.create', {
      features: [circleFeature],
    });
  } else {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(MAP_MODES.AUTO_DIRECT_SELECT, {}, { silent: true });
  }
};

RadiusMode.toDisplayFeatures = function (state: any, geojson: any, display: any) {
  const isActiveLine = geojson.properties.id === state.line.id;
  geojson.properties.active = isActiveLine ? 'true' : 'false';
  if (!isActiveLine) return display(geojson);

  // Only render the line if it has at least one real coordinate
  if (geojson.geometry.coordinates.length < 2) return null;
  geojson.properties.meta = 'feature';

  // displays center vertex as a point feature
  display(
    createVertex(
      state.line.id,
      geojson.geometry.coordinates[state.direction === 'forward' ? geojson.geometry.coordinates.length - 2 : 1],
      `${state.direction === 'forward' ? geojson.geometry.coordinates.length - 2 : 1}`,
      false,
    ),
  );

  // displays the line as it is drawn
  display(geojson);

  const displayMeasurements = getDisplayMeasurements(geojson);

  // create custom feature for the current pointer position
  const currentVertex = {
    active: 'true',
    type: 'Feature',
    properties: {
      meta: 'currentPosition',
      radiusMetric: displayMeasurements.metric,
      radiusStandard: displayMeasurements.standard,
      parent: state.line.id,
    },
    geometry: {
      type: 'Point',
      coordinates: geojson.geometry.coordinates[1],
    },
  };
  display(currentVertex);

  // create custom feature for radius circlemarker
  const center = geojson.geometry.coordinates[0];
  const radiusInKm = lineDistance(geojson, 'kilometers');
  const circleFeature = createGeoJSONCircle(center, radiusInKm, state.line.id);
  circleFeature.properties!['meta'] = 'radius';

  return display(circleFeature);
};

export default RadiusMode;
