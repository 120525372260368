import AgoToast from '../../components/Toast/AgoToast';
import { ContractService } from '../../service/contract/contract.service';
import { getStates } from '../../service/common/constants';
import { getActiveCampaigns, getHowDidYouHearOptions, getRepresentativeOptions } from './lead-action';
import { User } from '@auth0/auth0-react';
import { getUser, getUserPartnerContracts } from './user-action';

export const getAccessTokenAction: any = (getAccessTokenFn: () => Promise<string>, refreshSessionFn: () => Promise<string>) => (dispatch: any) => {
  return getAccessTokenFn()
    .then((result: any) => {
      dispatch({
        type: 'ACCESS_TOKEN_LOADED',
        payload: { result, getAccessTokenFn, refreshSessionFn },
      });
    })
    .catch((err: any) => {
      AgoToast.showToast({
        title: 'Error',
        message: 'Unable to get access token, please try again',
        type: 'error',
        toastId: 'access-token-error',
      });
    });
};

export const refreshSessionAction: any = (refreshSessionFn: () => Promise<string>) => (dispatch: any) => {
  return refreshSessionFn()
    .then((result: any) => {
      dispatch({
        type: 'ACCESS_TOKEN_LOADED',
        payload: { result, refreshSessionFn },
      });
    })
    .catch((err: any) => {
      AgoToast.showToast({
        title: 'Error',
        message: 'Unable to get refresh session, please try again',
        type: 'error',
        toastId: 'refresh-session-error',
      });
    });
};

export const getAppInitializedAction: any =
  (getAccessTokenFn: () => Promise<string>, refreshSessionFn: () => Promise<string>, user: User) => (dispatch: any) => {
    dispatch(getAccessTokenAction(getAccessTokenFn, refreshSessionFn))
      .then((_: any) => {
        return dispatch(getUser(user));
      })
      .then(() => {
        return dispatch({
          type: 'APP_INITIALIZED',
        });
      })
      .then((_: any) => {
        return ContractService.getSequestrationApprovalReasons();
      })
      .then((result: any) => {
        dispatch({
          type: 'CARBON_SEQUESTRATION_APPROVAL_REASONS_SUCCESSFUL',
          payload: result,
        });
      })
      .then(() => {
        dispatch(getActiveCampaigns());
        dispatch(getHowDidYouHearOptions());
        dispatch(getRepresentativeOptions());
        if (user['https://api.pa.agoro.comapp_metadata'].channelPartnerId) {
          dispatch(getUserPartnerContracts());
        }
      })
      .then(() => {
        return getStates(user.country).then((result) => {
          dispatch({
            type: 'AVAILABLE_STATES_SUCCESSFUL',
            payload: { list: result, country: user.country },
          });
        });
      })
      .catch((err: any) => {
        AgoToast.showToast({
          title: 'Initialization failed, API error',
          message: 'Unable to get initialization data',
          type: 'error',
          toastId: 'unable-to-get-initialization-data-error',
        });
      });
  };
