import { ContractService } from '../contract/contract.service';

const compareDates = (field1: any, field2: any, direction: string) => {
  const compareResult = field1 - field2;
  return direction === 'ascending' ? compareResult : compareResult * -1;
};

const compareStrings = (field1: string, field2: string, direction: string) => {
  const compareResult = field1.localeCompare(field2);
  return direction === 'ascending' ? compareResult : compareResult * -1;
};
const compareGstAssigned = (field1: string, field2: string, direction: string) => {
  const compareResult = ContractService.makeInitials(field1).localeCompare(ContractService.makeInitials(field2));
  return direction === 'ascending' ? compareResult : compareResult * -1;
};

const decodeStatus = (filterInfo: any) => {
  const copy = { ...filterInfo };

  switch (filterInfo.status) {
    case '':
      copy.status = [];
      break;

    case 'Contacted':
      copy.status = ['Warm', 'Qualification call scheduled'];
      break;

    case 'Estimation Stage':
      copy.status = ['OA & Pitch Meeting', 'Follow Up & Close', 'Document verification'];
      break;

    case 'Contract Complete':
      copy.status = ['Closed Won'];
      break;

    default:
      copy.status = [filterInfo.status];
  }

  return copy;
};

export const getFilteredLeads = (filterInfo: any, sortBy: any, fullLeadsList: any = []) => {
  // "decode" CP status
  const filterInfoDecoded = decodeStatus(filterInfo);

  return fullLeadsList
    .filter((lead: any) => {
      if (filterInfoDecoded.status.length && !filterInfoDecoded.status.includes(lead.status)) {
        return false;
      }
      return true;
    })
    .sort((lead1: any, lead2: any) => {
      if (sortBy.field === 'createdAt') {
        return compareDates(lead1['createdAt'], lead2['createdAt'], sortBy.direction);
      }
      if (sortBy.field === 'gstAssigned') {
        return compareGstAssigned(lead1['gstAssigned'], lead2['gstAssigned'], sortBy.direction);
      }
      return compareStrings(lead1[sortBy.field] || '', lead2[sortBy.field] || '', sortBy.direction);
    });
};
