import { Box, Spinner, Text } from 'grommet';
import FieldDropdown from './Dropdowns/FieldDropdown';
import { useMapContext } from '../../../service/map/MapContext';
import React from 'react';
import { PracticesIconsComponent } from '../../Field/PracticesIconsComponent';
import OffsiteDropdown from '../../Offsite/OffsiteDropdown';
import { useTranslation } from 'react-i18next';

export const RanchOverview = (): JSX.Element => {
  const { t } = useTranslation();
  const { fields, getFieldsLocations, totalFieldsAcreage, getFieldsPractices, companyName, getOffsiteHerds, loading } = useMapContext();
  return loading ? (
    <Box height={'100%'} align={'center'} justify={'center'}>
      <Spinner color={'#FFE137'} size={'medium'} />
    </Box>
  ) : (
    <Box pad={'24px'} height={'100%'}>
      <Box direction={'row'} wrap={true} margin={'0 0 0.5rem 0'} width={'100%'} justify={'between'} align={'baseline'}>
        <Box direction={'row'} gap={'1.25rem'} align={'baseline'}>
          <Text data-cy={'ranch-overview-title'} weight={900} size={'2rem'} style={{ whiteSpace: 'nowrap' }}>
            {t('fieldFlow.ranchOverview.title')}
          </Text>
          <Text weight={400} size={'1rem'}>
            {getFieldsLocations()[0]}
          </Text>
        </Box>
        {/* todo: plan this <Text size={'0.75rem'} weight={400}>Last updated by John Doe 7 hours ago</Text>*/}
      </Box>
      {/*Ranch information on hold for now*/}
      <Box margin={'0 0 0.5rem 0'} direction={'row'} gap={'2rem'}>
        <Box width={'50%'} style={{ maxWidth: 'fit-content' }} height={'40px'}>
          <Text weight={700} textAlign={'start'}>
            {t('fieldFlow.farmName')}
          </Text>
          <Box
            width={'100%'}
            style={{
              padding: '0',
              width: 'fit-content',
              overflow: 'hidden',
              position: 'relative',
              display: 'inline-block',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <Text textAlign={'start'}>{companyName}</Text>
          </Box>
        </Box>
        <Box width={'50%'} style={{ maxWidth: 'fit-content' }}>
          <Text data-cy={'total-acreage'} weight={700} textAlign={'start'}>
            {t('fieldFlow.totalAcreage')}
          </Text>
          <Text textAlign={'start'}>
            {totalFieldsAcreage} {t('fieldFlow.acreUnit')}
          </Text>
        </Box>
        {/*  <Box>*/}
        {/*    <Text weight={700}>Shapefiles Status</Text>*/}
        {/*    <Text>Approved</Text>*/}
        {/*  </Box>*/}
        <Box>
          <Text weight={700} textAlign={'start'}>
            {t('fieldFlow.ranchOverview.practices')}
          </Text>
          <PracticesIconsComponent practices={getFieldsPractices()}></PracticesIconsComponent>
        </Box>
      </Box>
      <Text margin={'0.75rem 0'} weight={900}>
        {t('fieldFlow.ranchOverview.fieldList')}
      </Text>
      <Box
        pad={'0.75rem 0.5rem'}
        height={'100%'}
        overflow={'auto'}
        style={{ borderRadius: '10px' }}
        border={{ color: '#808080', style: 'solid', size: '1px' }}
      >
        <Box gap={'0.75rem'}>
          {getOffsiteHerds().length > 0 && <OffsiteDropdown />}
          {fields.map((field: any) => {
            return <FieldDropdown key={field.id} field={field} />;
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default RanchOverview;
