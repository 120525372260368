import mapboxgl from 'mapbox-gl';

export enum EventTypes {
  FEEDING = 'FEEDING',
  SUPPLEMENTING = 'SUPPLEMENTING',
  HAYING = 'HAYING',
  FIRE = 'FIRE',
}

export enum FeedingSubType {
  HAY = 'HAY',
  SILAGE = 'SILAGE',
  SWEET_MIX = 'SWEET_MIX',
}

export enum SupplementingSubType {
  MINERAL = 'MINERAL',
  PROTEIN_TUBS = 'PROTEIN_TUBS',
  CAKE = 'CAKE',
  OTHER = 'OTHER',
}

export enum FireSubType {
  PRESCRIBED = 'PRESCRIBED',
  WILD = 'WILD',
}

export interface Event {
  id: string;
  growerId: string;
  fieldId?: string;
  paddockIds: string[];
  type: EventTypes;
  data?: {
    name?: string;
    subType?: string;
    startDate?: Date;
    endDate?: Date;
    amount?: number;
    fromField?: string;
    fromPaddock?: string;
    acresBurned?: number;
    monthBurnOccurred?: string;
    yearBurnOccurred?: string;
  };
  marker?: mapboxgl.Marker;
}
