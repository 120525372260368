import { FieldService } from '../../service/field/field.service';

export const clearSFFields: any = () => (dispatch: any) => {
  dispatch({
    type: 'CLEAR_SF_FIELD_REQUESTED',
  });
};

export const getFieldsAction: any = (journeyId: string) => (dispatch: any) => {
  dispatch({
    type: 'GET_FIELDS_REQUESTED',
  });
  return FieldService.getFields(journeyId)
    .then((res: any) => {
      dispatch({
        type: 'GET_FIELDS_SUCCEEDED',
        payload: res,
      });
      return res;
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_FIELDS_ERROR',
        payload: err,
      });
    });
};
