import { Box, ResponsiveContext, Text } from 'grommet';
import { PaddockItem } from './PaddockItem';
import React, { useContext } from 'react';
import { Feature } from '@turf/helpers';
import infoIcon from '../../../../assets/BoundaryTool/info-icon.svg';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useMapContext } from '../../service/map/MapContext';
import Field from '../../types/Field';

interface PaddockStepProps {
  selectedField?: Field;
  setShowMeHow: (state: boolean) => void;
}

export const PaddockStep = ({ selectedField, setShowMeHow }: PaddockStepProps) => {
  const { t } = useTranslation();
  const { paddocks, updatePaddock } = useMapContext();
  const size = useContext(ResponsiveContext);
  const applyToAll = (dominantPlant: string) => {
    if (selectedField) {
      paddocks[selectedField.id!].forEach((paddock: Feature) => {
        paddock.properties!['dominantPlant'] = dominantPlant;
        updatePaddock(paddock);
      });
    }
  };

  return (
    <>
      <Box direction='row' justify={'between'} margin={'0.25rem 0 0.25rem 0'} style={{ minHeight: 'unset' }}>
        <Text size={'20px'} weight={800} style={{ lineHeight: '28px' }}>
          {t('fieldFlow.mainWizard.Paddocks.selectedFields')}
        </Text>
        <Box direction='row' onClick={() => setShowMeHow(true)}>
          <img src={infoIcon} height={22} alt='info' style={{ position: 'relative', marginLeft: '0.2rem' }} />
          <Text size={'15px'} color='#1457A8'>
            {t('fieldFlow.mainWizard.Paddocks.showMeHow')}
          </Text>
        </Box>
      </Box>
      <Box
        border={{ color: selectedField ? '#1B7C3D' : '#CCCCCC', size: '1px' }}
        style={{ borderRadius: '5px' }}
        pad={'16px'}
        direction={'row'}
        height={{ min: '76px' }}
      >
        {selectedField ? (
          <>
            <Box width={'25%'}>
              <Text size={'16px'} weight={800} style={{ lineHeight: '20px' }}>
                {t('fieldFlow.mainWizard.levelTag.Field')}
              </Text>
              <Text size={'16px'} weight={400} margin={{ top: '4px' }} style={{ lineHeight: '20px', overflow: 'hidden' }}>
                {selectedField.properties && selectedField.properties['name']}
              </Text>
            </Box>
            <Box width={'25%'}>
              <Text size={'16px'} weight={800} style={{ lineHeight: '20px' }}>
                {t('fieldFlow.units.area')}
              </Text>
              <Text size={'16px'} weight={400} margin={{ top: '4px' }} style={{ lineHeight: '20px' }}>
                {selectedField.properties && selectedField.properties[i18next.language === 'pt-BR' ? 'areaHectare' : 'area'].toFixed(2)}
              </Text>
            </Box>
            <Box width={'50%'} justify={'center'} align={'end'}>
              <Text size={'16px'} weight={400} style={{ lineHeight: '20px' }} color={'#1B7C3D'}>
                {t('fieldFlow.mainWizard.Paddocks.drawLines')}
              </Text>
            </Box>
          </>
        ) : (
          <Box width={'100%'} height={'100%'} align={'center'} justify={'center'}>
            <Text size={'16px'} textAlign={'center'} weight={400} style={{ lineHeight: '20px' }} color={'#282828'}>
              {t('fieldFlow.mainWizard.Paddocks.selectField')}
            </Text>
          </Box>
        )}
      </Box>

      <Text size={'20px'} weight={800} style={{ lineHeight: '28px' }} margin={{ top: '0.5rem' }}>
        {t('fieldFlow.mainWizard.Paddocks.list')}
      </Text>
      <Box margin={'6px 0 1rem 0'} height={'100%'} style={{ borderRadius: '10px' }} border={{ color: '#808080', style: 'solid', size: '1px' }}>
        <Box
          style={{
            opacity: selectedField && !!paddocks[selectedField.id!] ? 1 : 0.3,
          }}
          margin='16px 24px 0'
          pad='2px 16px'
          direction='row'
          justify={'between'}
          height={{ min: '2rem' }}
          border={{ side: 'bottom', color: '#808080', style: 'solid', size: '1px' }}
        >
          <Box width={'5%'}>
            <Text size={'16px'} weight={400} margin={{ top: '4px' }} style={{ lineHeight: '20px' }}>
              #
            </Text>
          </Box>
          <Box width={'30%'}>
            <Text size={'16px'} weight={400} margin={{ top: '4px' }} style={{ lineHeight: '20px' }}>
              {t('fieldFlow.ranchOverview.fieldDetails.paddock')}
            </Text>
          </Box>
          <Box width={'35%'}>
            <Text size={'16px'} weight={400} margin={{ top: '4px' }} style={{ lineHeight: '20px' }}>
              {t('fieldFlow.mainWizard.Paddocks.dominantPlant')}
            </Text>
          </Box>
          <Box width={'15%'}>
            <Text size={'16px'} weight={400} margin={{ top: '4px' }} style={{ lineHeight: '20px' }}>
              {t('fieldFlow.units.area')}
            </Text>
          </Box>
        </Box>
        <Box pad={'24px'} gap={'10px'} overflow={'auto'} height={'calc(100%-2rem)'}>
          {selectedField &&
            selectedField.id &&
            paddocks[selectedField.id.toString()]?.map((paddock, index) => PaddockItem(paddock, updatePaddock, size, applyToAll, index, t))}
        </Box>
      </Box>
    </>
  );
};
