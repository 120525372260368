import React from 'react';
import { Box, Layer, Video, Text } from 'grommet';
import closeIcon from '../../../../assets/BoundaryTool/close-icon.svg';
import { useTranslation } from 'react-i18next';

interface ShowModeModalProps {
  setShowMeHow: (value: ((prevState: boolean) => boolean) | boolean) => void;
}

export const ShowMeHowModal = ({ setShowMeHow }: ShowModeModalProps): JSX.Element => {
  const { t } = useTranslation();
  const close = () => {
    setShowMeHow(false);
  };
  return (
    <Layer background={{ opacity: 0.7 }} onClickOutside={close}>
      <Box
        background={'white'}
        direction={'column'}
        alignSelf={'center'}
        pad={'1rem'}
        style={{ borderRadius: '1rem', display: 'unset' }}
        align={'center'}
      >
        <Box width='100%' direction='row' justify={'end'} style={{ cursor: 'pointer' }}>
          <img src={closeIcon} height={12} onClick={close} alt='close' />
        </Box>
        <Box pad={'0.5rem 1rem'}>
          <Box>
            <Video
              style={{
                borderColor: '#1457A8',
                borderStyle: 'solid',
                borderRadius: '0.5rem',
              }}
              autoPlay={true}
              loop={true}
              controls={false}
            >
              <source key='video' src={'/videos/how-to-draw-paddocks.mp4'} type='video/mp4' />
            </Video>
          </Box>
        </Box>
        <Box pad={'1.2rem'}>
          <Text size={'1rem'}>{t('fieldFlow.showMeHowModal.part1')}</Text>
          <Text style={{ margin: '0.5rem 0' }} size={'1rem'}>
            {t('fieldFlow.showMeHowModal.part2')}
          </Text>
          <Text size={'1rem'}>{t('fieldFlow.showMeHowModal.part3')}</Text>
        </Box>
      </Box>
    </Layer>
  );
};

export default ShowMeHowModal;
