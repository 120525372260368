import { Box, Text } from 'grommet';
import { addDays } from '../../service/common/utils';
import { DateInput } from './DateInput';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DateRangeProps {
  startLabel?: string;
  endLabel?: string;
  startDate?: Date;
  endDate?: Date;
  minStartDate?: Date;
  maxStartDate?: Date;
  minEndDate?: Date;
  maxEndDate?: Date;
  onChange: (value?: { startDate?: Date; endDate?: Date }) => void;
  disabled?: boolean;
  disabledEndDate?: boolean;
}

export const DateRange = ({
  startLabel,
  endLabel,
  startDate,
  endDate,
  minStartDate,
  maxStartDate,
  minEndDate,
  maxEndDate,
  onChange,
  disabled,
  disabledEndDate,
}: DateRangeProps) => {
  const { t } = useTranslation();
  const [dateError, setDateError] = useState<boolean>(false);
  const [maxStartDateChangeable, setMaxStartDate] = useState<Date | undefined>(maxStartDate);
  const [minEndDateChangeable, setMinEndDate] = useState<Date | undefined>(minEndDate);

  useEffect(() => {
    setMaxStartDate(
      !!maxStartDate
        ? !!endDate
          ? new Date(maxStartDate) > new Date(endDate)
            ? addDays(endDate, -1)
            : maxStartDate
          : maxStartDate
        : !!endDate
        ? addDays(endDate, -1)
        : undefined,
    );
    setMinEndDate(
      !!minEndDate
        ? !!startDate
          ? new Date(minEndDate) < new Date(startDate)
            ? addDays(startDate, 1)
            : minEndDate
          : minEndDate
        : !!startDate
        ? addDays(startDate, 1)
        : undefined,
    );

    setDateError(!!startDate && !!endDate && endDate < startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <Box gap={'1rem'} width={'66%'}>
      <Box direction={'row'} gap={'1rem'}>
        <DateInput
          width={'50%'}
          label={startLabel ?? t('fieldFlow.dateRangeModal.start')}
          value={startDate}
          min={minStartDate}
          max={maxStartDateChangeable}
          disabled={disabled}
          error={dateError}
          onChange={(date) => {
            onChange({ startDate: date });
          }}
          onBlur={(date) => {
            if (!!minStartDate && !!date && date < new Date(minStartDate)) date = new Date(minStartDate);
            if (!!maxStartDate && !!date && date > new Date(maxStartDate)) date = new Date(maxStartDate);
            onChange({ startDate: date });
          }}
        />
        <DateInput
          width={'50%'}
          label={endLabel ?? t('fieldFlow.dateRangeModal.endDate')}
          value={endDate}
          noDateLabel={'CURRENT'}
          min={minEndDateChangeable}
          max={maxEndDate ?? new Date()}
          disabled={disabled}
          disabledInput={disabledEndDate}
          error={dateError}
          onChange={(date) => {
            onChange({ endDate: date });
          }}
          onBlur={(date) => {
            if (!!minEndDate && !!date && date < new Date(minEndDate)) date = new Date(minEndDate);
            if (!!maxEndDate && !!date && date > new Date(maxEndDate)) date = new Date(maxEndDate);
            onChange({ endDate: date });
          }}
        />
      </Box>
      {dateError && (
        <Text textAlign={'justify'} size={'0.75rem'} color='red' margin={{ top: '.25rem' }}>
          {t('fieldFlow.dateRangeModal.errors.startBeforeEnd')}
        </Text>
      )}
    </Box>
  );
};
