import { Box, Spinner, Text } from 'grommet';
import { FormPrevious } from 'grommet-icons';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import HerdDropdown from './Dropdowns/HerdDropdown';
import EventDropdown from './Dropdowns/EventsDropdown/EventsDropdown';
import WaterSourceDropdown from './Dropdowns/WaterSourceDropdown';
import { useMapContext } from '../../../service/map/MapContext';
import React, { useMemo } from 'react';
import { PracticesIconsComponent } from '../../Field/PracticesIconsComponent';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const FieldDetails = (): JSX.Element => {
  const { t } = useTranslation();
  const { hideStructuresAndEvents } = useFlags();
  const { journeyId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fieldId = searchParams.get('fieldId');

  const {
    setState,
    fields,
    paddocks,
    currentVersion,
    herds,
    herdRemovedThisVersion,
    events,
    addEvent,
    removeEvent,
    updateEvent,
    saveEvent,
    structures,
    addStructure,
    removeStructure,
    updateStructure,
    saveStructure,
  } = useMapContext();
  const field = fields.find((field) => field.id === fieldId);

  const totalAnimals = useMemo(() => {
    let totalAnimals = 0;
    herds.forEach((herd) => {
      if (typeof herd.id !== 'number' && field?.id === herd?.rotation?.[0]?.fieldId && !herdRemovedThisVersion(herd)) {
        totalAnimals += herd?.data?.totalSize ?? 0;
      }
    });
    return totalAnimals;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [herds]);

  const filteredHerds = useMemo(
    () =>
      herds.filter((herd) => {
        if (!herd?.rotation || herd?.rotation.length === 0) {
          return herd.fieldId === fieldId;
        }
        return herd?.rotation?.[0]?.fieldId === fieldId;
      }),
    [herds, fieldId],
  );

  const filteredEvents = useMemo(() => events.filter((event) => event.fieldId === fieldId), [events, fieldId]);

  const filteredStructures = useMemo(() => structures.filter((structure) => structure.fieldId === fieldId), [structures, fieldId]);

  return (
    <Box height={'100%'}>
      <Box
        direction={'row'}
        width={'100%'}
        pad={'1rem'}
        justify={'between'}
        align={'center'}
        border={{ color: '#CCC', size: '1px', style: 'solid', side: 'bottom' }}
      >
        <Box direction={'row'} gap={'1.25rem'} align={'center'}>
          <FormPrevious
            onClick={() => {
              setState('OVERVIEW');
              navigate(`/v2/fields/${journeyId}/overview`);
            }}
            style={{ cursor: 'pointer' }}
            width='9px'
            height='9px'
          />
          <Text weight={900} size={'1rem'}>
            {field?.properties?.['name'] ?? t('fieldFlow.loadingField')}
          </Text>
        </Box>
      </Box>
      {field && currentVersion ? (
        <>
          <Box pad={'0 2rem'} margin={'0.5rem 0 1.5rem 0'} direction={'row'} gap={'2rem'}>
            <Box>
              <Text weight={900}>{t('fieldFlow.fieldItem.fieldDetails.landOwner')}</Text>
              <Text>{field.properties?.['situation'] === 'OWNED' ? t('fieldFlow.yes') : t('fieldFlow.syncModal.cancel')}</Text>
            </Box>
            <Box>
              <Text weight={900}>{field.properties?.['area_unit'] ?? t('fieldFlow.units.area')}</Text>
              <Text>{field.properties?.[i18next.language === 'pt-BR' ? 'areaHectare' : 'area'].toFixed(2)}</Text>
            </Box>
            {herds.length > 0 && (
              <Box>
                <Text weight={900}>{t('fieldFlow.animals')}</Text>
                <Text>{totalAnimals}</Text>
              </Box>
            )}
            <Box>
              <Text weight={900}>{t('fieldFlow.ranchOverview.practices')}</Text>
              <PracticesIconsComponent practices={field.properties?.practices} />
            </Box>
          </Box>

          <Box pad={'0 1.25rem 0.75rem 1.25rem'} height={'100%'} style={{ maxHeight: '100%' }} gap={'0.75rem'} overflow={'auto'}>
            {/*Operation has been put on backlog*/}
            {/*<OperationsDropdown/>*/}

            <HerdDropdown herds={filteredHerds} />

            {hideStructuresAndEvents ? null : (
              <Box gap={'0.75rem'} overflow={'auto'}>
                <EventDropdown
                  addEvent={() => addEvent(field?.id?.toString(), (field?.properties ?? {})['name'] ?? '')}
                  updateEvent={updateEvent}
                  saveEvent={saveEvent}
                  removeEvent={removeEvent}
                  field={field}
                  paddocks={paddocks[field.id!]}
                  filteredEvents={filteredEvents}
                />

                <WaterSourceDropdown
                  addNewStructure={() => addStructure(field?.id?.toString())}
                  updateStructure={updateStructure}
                  saveStructure={saveStructure}
                  removeStructure={removeStructure}
                  structures={filteredStructures}
                  field={field}
                  paddocks={paddocks[field.id!]}
                />
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Box height={'100%'} align={'center'} justify={'center'}>
          <Spinner color={'#FFE137'} size={'0.25rem'} />
        </Box>
      )}
    </Box>
  );
};

export default FieldDetails;
