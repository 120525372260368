import { Anchor } from 'grommet';
import React from 'react';
type CloseButtonProps = {
  closeToast: () => void;
};
export const CloseButton = ({ closeToast }: CloseButtonProps): JSX.Element => (
  <Anchor size='small' color='#1370BC' onClick={closeToast}>
    close
  </Anchor>
);
