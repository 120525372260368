import {
  DCEventTypes,
  EventsSuccess,
  IDCCoverCroppingEvent,
  IDCHarvestEvent,
  IDCPlantingEvent,
  IEvents,
} from '../../pages/customer-profile/components/Tabs/DataCollectionTab/interfaces';
import { createReducer } from '@reduxjs/toolkit';
import { eventsModified } from '../../pages/customer-profile/components/Tabs/DataCollectionTab/utils/utils';

const initialState: any = {
  fieldsFilter: [],
  showOnlyErrors: false,
  implementationYear: null,
  implementationYearLoading: false,
  dataCollectionEventsTypes: {},
  dataCollectionEventsTypesFull: {}, //Used for filtering and controlling event versions
  dataCollectionOldFertilizerEvents: {},
  modelInputDefaults: [],
  eventsToDelete: [
    { name: 'Tillage', eventIds: [] },
    { name: 'Planting', eventIds: [] },
    { name: 'Harvest', eventIds: [] },
    { name: 'CoverCrop', eventIds: [] },
    { name: 'Fertilization', eventIds: [] },
    { name: 'Seeding', eventIds: [] },
    { name: 'FieldData', eventIds: [] },
  ],
  dataCollectionEventsTypesState: { lifeCycle: 'NONE' },
  eventSave: { lifeCycle: 'NONE' },
  eventDelete: { lifeCycle: 'NONE', inDeletion: [] },
  eventDuplicate: { lifeCycle: 'NONE', inDuplication: [] },
  containsEvents: false,
  fieldSectionsOpen: {},
  showDeleteModal: false,
  singleEventDelete: {}, //Delete outside of edit
  eventModified: false, //Whether we should show the update modal
};

const reducer = createReducer(initialState, {
  GET_EVENTS_TYPES_REQUESTED(state) {
    state.dataCollectionEventsTypesState.lifeCycle = 'PENDING';
  },
  GET_EVENTS_TYPES_SUCCEEDED(state, action) {
    const { events, containsEvents } = action.payload;
    state.dataCollectionEventsTypesState.lifeCycle = 'SUCCESS';
    state.dataCollectionEventsTypes = events;
    state.dataCollectionEventsTypesFull = events;
    state.containsEvents = containsEvents;

    state.dataCollectionOldFertilizerEvents = Object.values(events.Fertilization.events);
  },
  GET_EVENTS_TYPES_ERROR(state, action) {
    state.dataCollectionEventsTypesState = { lifeCycle: 'ERROR', error: action.payload };
    state.dataCollectionEventsTypes = {};
    state.dataCollectionEventsTypesFull = {};
    state.containsEvents = false;
  },
  GET_IMPLEMENTATION_YEAR_REQUESTED(state) {
    state.implementationYearLoading = true;
  },
  GET_IMPLEMENTATION_YEAR_SUCCEEDED(state, action) {
    const { implementationYear } = action.payload;
    state.implementationYear = implementationYear;
    state.implementationYearLoading = false;
  },
  GET_IMPLEMENTATION_YEAR_ERROR(state) {
    state.implementationYear = null;
    state.implementationYearLoading = false;
  },
  UPSERT_IMPLEMENTATION_YEAR_REQUESTED(state) {
    state.implementationYearLoading = true;
  },
  UPSERT_IMPLEMENTATION_YEAR_SUCCEEDED(state, action) {
    const { implementationYear } = action.payload;
    state.implementationYear = implementationYear;
    state.implementationYearLoading = false;
  },
  UPSERT_IMPLEMENTATION_YEAR_ERROR(state) {
    state.implementationYearLoading = false;
  },
  GET_MODEL_INPUT_DEFAULTS_SUCCEEDED(state, action) {
    const modelInputDefaults = action.payload;
    state.modelInputDefaults = modelInputDefaults;
  },
  CANCEL_EDIT(state) {
    let containsEvents = false;
    Object.values(state.dataCollectionEventsTypes as DCEventTypes).forEach((eventType) => {
      for (const event of Object.values(eventType.events)) {
        if (event.newEvent) {
          delete eventType.events[event.id];
          continue;
        }
        const previousEventState = state.dataCollectionEventsTypesFull[eventType.name].events[event.id];
        eventType.events[event.id] = { ...previousEventState, markedAsDeleted: false, copy: false };
      }
      containsEvents = containsEvents || !!Object.values(eventType.events).length;
    });
    state.eventModified = false;
    state.eventsToDelete = state.eventsToDelete.map((eventType) => {
      return {
        ...eventType,
        eventIds: [],
      };
    });
    state.containsEvents = containsEvents;
  },
  ADD_NEW_EVENT(state, action) {
    const { eventType, eventToAdd } = action.payload;
    state.dataCollectionEventsTypes[eventType].events[eventToAdd.id] = eventToAdd;
    state.dataCollectionEventsTypesFull[eventType].events[eventToAdd.id] = eventToAdd;
    state.containsEvents = true;
    state.eventModified = true;
  },
  UPDATE_EVENT(state, action) {
    const { eventType, event } = action.payload;
    const typeAttributes = state.dataCollectionEventsTypes[eventType].constraints.map((c) => c.attributeName);
    state.eventModified = eventsModified(event, state.dataCollectionEventsTypesFull[eventType].events[event.id], typeAttributes);
    state.dataCollectionEventsTypes[eventType].events[event.id] = event;
    state.fieldSectionsOpen = {
      ...state.fieldSectionsOpen,
      [event.fieldId]: true,
    };
  },
  SAVE_OLD_FERTILIZER_EVENTS(state, action) {
    const { events } = action.payload;
    state.dataCollectionOldFertilizerEvents = events;
  },
  UPDATE_PLANTING_EVENT(state, action) {
    const { event, movedHarvestEvents } = action.payload;
    const typeAttributes = state.dataCollectionEventsTypes['Planting'].constraints.map((c) => c.attributeName);
    state.eventModified = eventsModified(event, state.dataCollectionEventsTypesFull['Planting'].events[event.id], typeAttributes);
    state.dataCollectionEventsTypes['Planting'].events[event.id] = event;
    movedHarvestEvents.forEach((he) => {
      state.dataCollectionEventsTypes['Harvest'].events[he.id] = he;
      state.dataCollectionEventsTypesFull['Harvest'].events[he.id] = he;
    });
    state.fieldSectionsOpen = {
      ...state.fieldSectionsOpen,
      [event.fieldId]: true,
    };
  },
  DELETE_SINGLE_EVENT_REQUESTED(state) {
    state.showDeleteModal = false;
    state.eventDelete = {
      lifeCycle: 'PENDING',
      inDeletion: state.eventDelete.inDeletion.concat(state.singleEventDelete.id),
    };
  },
  DELETE_EVENT_SUCCEEDED(state, action) {
    const eventsDeleted = action.payload;
    state.showDeleteModal = false;
    for (const eventTypeDeletion of eventsDeleted) {
      eventTypeDeletion.eventIds.forEach((id) => {
        delete state.dataCollectionEventsTypes[eventTypeDeletion.name].events[id];
        delete state.dataCollectionEventsTypesFull[eventTypeDeletion.name].events[id];
        if (eventTypeDeletion.name === 'Planting') {
          (Object.values(state.dataCollectionEventsTypes['Harvest'].events) as IDCHarvestEvent[])
            .filter((hv) => hv?.plantingId === id)
            .forEach((hvId) => {
              delete state.dataCollectionEventsTypes['Harvest'].events[hvId.id];
            });
          (Object.values(state.dataCollectionEventsTypesFull['Harvest'].events) as IDCHarvestEvent[])
            .filter((hv) => hv?.plantingId === id)
            .forEach((hvId) => {
              delete state.dataCollectionEventsTypesFull['Harvest'].events[hvId.id];
            });
        }
      });
    }
    //Clear events to delete

    state.eventsToDelete.forEach((eventType) => {
      eventType.eventIds = [];
    });
    let containsEvents = false;
    Object.values(state.dataCollectionEventsTypes as DCEventTypes).forEach((eventType) => {
      containsEvents = containsEvents || !!Object.values(eventType.events).length;
    });
    state.eventDelete = {
      lifeCycle: 'SUCCESS',
      inDeletion: state.eventDelete.inDeletion.filter((e: string) => e === action.payload.eventId),
    };
    state.containsEvents = containsEvents;
  },
  DELETE_EVENT_ERROR(state, action) {
    //Clear events to delete
    state.eventsToDelete.forEach((eventType) => {
      eventType.eventIds = [];
    });
    state.eventDelete = {
      lifeCycle: 'ERROR',
      error: action.payload,
      inDeletion: state.eventDelete.inDeletion.filter((e: string) => e === action.payload.eventId),
    };
  },
  DUPLICATE_EVENT_REQUESTED(state, action) {
    const { eventType, duplicatedEvent, eventId } = action.payload;
    state.dataCollectionEventsTypes[eventType].events[duplicatedEvent.id] = duplicatedEvent;

    state.eventDuplicate = {
      lifeCycle: 'PENDING',
      inDuplication: state.eventDuplicate.inDuplication.concat(eventId),
    };
  },
  DUPLICATE_EVENT_SUCCEEDED(state, action) {
    const { eventType, events, eventId } = action.payload;
    for (const event of Object.values(events[eventType]) as IEvents[]) {
      state.dataCollectionEventsTypesFull[eventType].events[event.id] = event;
      state.dataCollectionEventsTypes[eventType].events[event.id] = event;
    }

    state.eventDuplicate = {
      lifeCycle: 'SUCCESS',
      inDuplication: state.eventDuplicate.inDuplication.filter((e: string) => e !== eventId),
    };
  },
  DUPLICATE_EVENT_ERROR(state, action) {
    const { eventType, duplicatedEvent, eventId } = action.payload;
    delete state.dataCollectionEventsTypes[eventType].events[duplicatedEvent.id];

    state.eventDuplicate = {
      lifeCycle: 'ERROR',
      inDuplication: state.eventDuplicate.inDuplication.filter((e: string) => e !== eventId),
    };
  },
  DUPLICATE_PLANTING_EVENT_REQUESTED(state, action) {
    const { duplicatedHarvestEvents, duplicatedPlantingEvent, eventId } = action.payload;

    state.dataCollectionEventsTypes['Planting'].events[duplicatedPlantingEvent.id] = duplicatedPlantingEvent;
    for (const harvestEvent of duplicatedHarvestEvents) {
      state.dataCollectionEventsTypes['Harvest'].events[harvestEvent.id] = harvestEvent;
    }

    state.eventDuplicate = {
      lifeCycle: 'PENDING',
      inDuplication: state.eventDuplicate.inDuplication.concat(eventId),
    };
  },
  DUPLICATE_PLANTING_EVENT_SUCCEEDED(state, action) {
    const eventTypes: any = action.payload.eventsDuplicated;
    for (const name of Object.keys(eventTypes)) {
      const events = Object.values(eventTypes[name]);
      for (const event of events as IDCPlantingEvent[] | IDCHarvestEvent[] | IDCCoverCroppingEvent[]) {
        state.dataCollectionEventsTypes[name].events[event.id] = event;
        state.dataCollectionEventsTypesFull[name].events[event.id] = event;
      }
    }

    state.eventDuplicate = {
      lifeCycle: 'SUCCESS',
      inDuplication: state.eventDuplicate.inDuplication.filter((e: string) => e !== action.payload.eventId),
    };
  },
  DUPLICATE_PLANTING_EVENT_ERROR(state, action) {
    const { duplicatedHarvestEvents, duplicatedPlantingEvent, eventId } = action.payload;

    delete state.dataCollectionEventsTypes['Planting'].events[duplicatedPlantingEvent.id];
    for (const harvestEvent of duplicatedHarvestEvents) {
      delete state.dataCollectionEventsTypes['Harvest'].events[harvestEvent.id];
    }
    state.eventDuplicate = {
      lifeCycle: 'ERROR',
      inDuplication: state.eventDuplicate.inDuplication.filter((e: string) => e !== eventId),
    };
  },
  MARK_EVENT_AS_DELETED(state, action) {
    const { eventType, eventId } = action.payload;
    state.eventsToDelete.find((eT) => eventType === eT.name).eventIds.push(eventId);
    if (eventType === 'Planting') {
      const relatedHarvest = (Object.values(state.dataCollectionEventsTypes['Harvest'].events) as IDCHarvestEvent[])
        .filter((e) => eventId === e.plantingId)
        .map((e) => e.id);
      state.eventsToDelete.find((eT) => eT.name === 'Harvest').eventIds.push(...relatedHarvest);
    }
  },
  DELETE_MODAL_REQUEST(state, action) {
    state.singleEventDelete = action.payload;
    state.showDeleteModal = true;
  },
  CANCEL_EVENT_DELETE(state) {
    state.singleEventDelete = {};
    state.showDeleteModal = false;
  },
  REMOVE_NEW_EVENT(state, action) {
    const { eventType, eventId } = action.payload;
    delete state.dataCollectionEventsTypes[eventType].events[eventId];
    delete state.dataCollectionEventsTypesFull[eventType].events[eventId];
    let containsEvents = false;
    Object.values(state.dataCollectionEventsTypes as DCEventTypes).forEach((eventType) => {
      containsEvents = containsEvents || !!Object.values(eventType.events).length;
    });
    state.containsEvents = containsEvents;
  },
  SAVE_EVENT_REQUESTED(state) {
    state.eventSave.lifeCycle = 'PENDING';
    state.eventModified = false;
  },
  SAVE_EVENT_SUCCEEDED(state, action) {
    const eventsSaved: EventsSuccess = action.payload.eventsSuccess;
    state.eventSave.lifeCycle = 'SUCCEEDED';
    let containsEvents = false;
    Object.keys(eventsSaved).forEach((eventType) => {
      containsEvents = containsEvents || !!Object.values(eventsSaved[eventType]).length;
      Object.values(eventsSaved[eventType] as Record<string, IEvents>).forEach((event) => {
        state.dataCollectionEventsTypes[eventType].events[event.id] = eventsSaved[eventType][event.id];
        state.dataCollectionEventsTypesFull[eventType].events[event.id] = eventsSaved[eventType][event.id];
      });
    });
    state.containsEvents = containsEvents;
  },
  SAVE_EVENT_ERROR(state) {
    state.eventSave.lifeCycle = 'ERRORED';
  },
  FILTER_EVENTS(state, action) {
    const fieldIds = action.payload.fieldIds;
    const years = action.payload.years;
    const showOnlyErrors = action.payload.showOnlyErrors;
    state.fieldsFilter = fieldIds;
    state.yearsFilter = years;
    const filteredEvents = filterEvents(state.dataCollectionEventsTypesFull, fieldIds, years, showOnlyErrors);
    state.dataCollectionEventsTypes = filteredEvents;
  },
  BULK_COPY_EVENTS(state, action) {
    state.dataCollectionEventsTypes = action.payload.eventsTypes;
    state.dataCollectionEventsTypesFull = action.payload.eventsTypes;
  },
  CLOSE_FIELD_SECTION(state, action) {
    const { fieldId } = action.payload;
    state.fieldSectionsOpen = {
      ...state.fieldSectionsOpen,
      [fieldId]: false,
    };
  },
});

const filterEvents = (eventsTypesFull: DCEventTypes, fieldIds, years, showOnlyErrors: boolean) => {
  const eventCopy = JSON.parse(JSON.stringify(eventsTypesFull)) as DCEventTypes;
  Object.values(eventCopy).forEach((eventType) => {
    Object.values(eventType.events).forEach((event: any) => {
      //todo find a better way to handle the different types
      const date =
        'tillageDate' in event
          ? event.tillageDate
          : 'plantingDate' in event
          ? event.plantingDate
          : 'harvestDate' in event
          ? event.harvestDate
          : 'dateApplied' in event
          ? event.dateApplied
          : 'terminationDate' in event
          ? event.terminationDate
          : 'dateOfApplication' in event
          ? event.dateOfApplication
          : null;
      if (
        (fieldIds.length && fieldIds.indexOf(event.fieldId) === -1) ||
        (years.length && (!date || years.indexOf(new Date(date).getFullYear()) === -1)) ||
        (showOnlyErrors && !event.erroredEvent)
      ) {
        delete eventCopy[eventType.name].events[event.id];
      }
    });
  });
  return eventCopy;
};

export default reducer;
