import { Box, CheckBox, Image, ThemeContext } from 'grommet';
import { StyledTooltip } from '../common/StyledTooltip';
import infoIcon from '../../../../assets/BoundaryTool/info-icon.svg';
import React from 'react';
import { Feature } from '@turf/turf';
import { TFunction } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface PracticeCheckboxProps {
  value: string;
  field: Feature;
  t: TFunction;
  practicesOnContract: any;
  saveField: (field: Feature) => void;
  disabled?: boolean;
  disabledForRegularFlow?: boolean;
  errored?: boolean;
  forceShow?: boolean;
  showTooltip?: boolean;
}

const PracticeCheckbox = ({
  t,
  value,
  practicesOnContract,
  field,
  saveField,
  disabled,
  disabledForRegularFlow,
  errored,
  forceShow,
  showTooltip,
}: PracticeCheckboxProps) => {
  const { usStackTillageWithLegumeAddition } = useFlags();

  function shouldShowCheckbox(checkboxValue: string) {
    const practiceToShortcodeLookup: any = {
      noTillage: 'NT',
      reducedTillage: 'IT',
      nitrogenReduction: 'NRR',
      nitrogenOptimization: 'NO',
      legume: 'LA',
      coverCropping: 'CC',
      fertilization: 'RPF',
      grazing: 'RPIG',
      seeding: 'RPBS',
      ICLP: 'ICL1',
      ICLR: 'ICL2',
      coverPerennial: 'CP',
      coverAnnual: 'CA',
      inoculant: 'IN',
    };
    const shortCode: string =
      practiceToShortcodeLookup[checkboxValue !== 'ICL' ? checkboxValue : checkboxValue + '' + field.properties?.practiceType[0]];
    if (!shortCode) {
      return false;
    }
    return practicesOnContract?.find((i) => i.code === shortCode);
  }

  if (!shouldShowCheckbox(value) && !forceShow) {
    return null;
  }
  field.properties = field.properties || {};
  field.properties.practices = field.properties.practices || [];
  return (
    <Box direction='row' height={'24px'}>
      <ThemeContext.Extend
        value={
          errored
            ? {
                checkBox: {
                  border: {
                    color: 'red',
                  },
                  color: 'red',
                  extend: () => `color: #FF0000;`,
                },
              }
            : {}
        }
      >
        <CheckBox
          data-cy={`${field.properties['name']}-practice-${value}`}
          disabled={disabled}
          style={{ fontSize: '14px', lineHeight: '19.12px' }}
          checked={field.properties.practices.indexOf(value) !== -1}
          onClick={() => {
            field.properties = field.properties || {};
            field.properties.practices = field.properties.practices || [];
            if (field.properties.practices.indexOf(value) !== -1) {
              field.properties.practices = field.properties.practices.filter((practice: string) => practice !== value);

              if (
                field.properties.practices.length === 1 &&
                field.properties.practices.find((p) => ['nitrogenReduction', 'inoculant'].indexOf(p) !== -1)
              ) {
                field.properties.practices = [];
              }
            } else {
              if (field.properties && field.properties.practices) {
                field.properties.practices = value === 'ICL' ? [value] : [...field.properties.practices, value];
              } else {
                field.properties.practices = [value];
              }
            }

            const isLegumeSelected = field?.properties?.practices?.indexOf('legume') !== -1;
            if (isLegumeSelected && field?.properties) {
              field.properties.practices = field.properties.practices.filter((practice: string) => {
                return (
                  practice === 'legume' ||
                  practice === 'nitrogenReduction' ||
                  (practice === 'reducedTillage' && usStackTillageWithLegumeAddition) ||
                  (practice === 'noTillage' && usStackTillageWithLegumeAddition)
                );
              });
              field.properties.paymentOption = 'OPTION_A';
            }
            saveField(field);
          }}
          label={t(`fieldFlow.fieldItem.practices.options.${value}`)}
        />
      </ThemeContext.Extend>

      {(disabledForRegularFlow || showTooltip) && (
        <StyledTooltip
          label={
            showTooltip
              ? t(`fieldFlow.fieldItem.practices.tooltip.${value}`)
              : `${t(`fieldFlow.fieldItem.practices.options.${value}`)} is not present in the contract`
          }
          showToolTip={true}
          dropProps={{ align: { bottom: 'top' } }}
        >
          <Image
            height={24}
            width={24}
            style={{
              marginLeft: '5px',
              filter: 'brightness(0) saturate(100%) invert(15%) sepia(0%) saturate(0%) hue-rotate(179deg) brightness(99%) contrast(98%)',
            }}
            src={infoIcon}
          />
        </StyledTooltip>
      )}
    </Box>
  );
};

export default PracticeCheckbox;
